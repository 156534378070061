import React, { Component } from 'react';
import { Text, Button, Page, Layout, Card, FormLayout, TextField, SettingToggle, TextStyle, Stack, Checkbox, DisplayText, PageActions, Spinner, ResourceList, ResourceItem, Icon, Badge } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { BuyButtonMajor, CartMajor, QuickSaleMajor, ViewMajor, BuyButtonButtonLayoutMajor, SidebarRightMajor } from '@shopify/polaris-icons';
import { fetchApi } from './helpers/fetchApi';
import { configs } from './helpers/appConfig';
export class Dashboard extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.handleEnabledChange = this.handleEnabledChange.bind(this);
        this.handleLiveModeChange = this.handleLiveModeChange.bind(this);
        this.nextOnboardingStep = this.nextOnboardingStep.bind(this);

        this.state = {
            initState: {}, model: {}, loading: true, onboarding: {}
        };
    }

    componentDidMount() {
        this.populateData();
        this.getQuickStats();
        this.props.setSaveChanges(this.saveChanges);
        this.props.setNextOnboardingStep(this.nextOnboardingStep);
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });
    }

    handleOnboardingChange(skip) {

        if (this.state.onboarding.currentStep === 0) {
            this.setState(prevState => {
                let onboarding = Object.assign({}, prevState.onboarding);
                onboarding.visible = false;
                return { onboarding };
            });
            return;
        }

        if (this.state.onboarding.currentStep === 2 && !skip) {
            window.open(this.state.model.demoUrl, '_blank');
            this.props.doOnboardingStep(this.state.onboarding.currentStep);
        }

        if (this.state.onboarding.currentStep === 23) {
            if (skip) {
                this.completeOnboarding();
                this.props.navigate('support');
            }
            else {
                this.props.doOnboardingStep(this.state.onboarding.currentStep);
            }
        }

        if (this.state.onboarding.currentStep === 3) {
            if (skip) {
                this.props.doOnboardingStep(this.state.onboarding.currentStep);
            }
            else {
                this.props.toggleModalReviewActive();
            }
        }
    }

    handleEnabledChange() {
        this.handleChange('enabled', !this.state.model.enabled);
    }

    handleLiveModeChange() {
        this.handleChange('liveMode', !this.state.model.liveMode);
    }

    calcOnboardingProgress() {
        var completedSteps = 1;
        if (this.state.onboarding.step2Done) {
            completedSteps++;
        }
        if (this.state.onboarding.step23Done) {
            completedSteps++;
        }
        if (this.state.onboarding.step3Done) {
            completedSteps++;
        }

        return Math.round(completedSteps / 4 * 100);
    }

    getOnboardingActionText() {
        var txt = 'Close';
        if (this.state.onboarding.currentStep === 2) {
            txt = 'Check it out';
        }
        else if (this.state.onboarding.currentStep === 23) {
            txt = 'All looks good';
        }
        else if (this.state.onboarding.currentStep === 3) {
            txt = 'I\'ll do it!';
        }

        return txt;
    }

    showOnboardingSecondaryAction() {
        return this.state.onboarding.currentStep === 23 || this.state.onboarding.currentStep === 3;
    }

    getOnboardingSecondaryActionText() {
        var txt = '';
        if (this.state.onboarding.currentStep === 23) {
            txt = 'I need help';
        }
        else if (this.state.onboarding.currentStep === 3) {
            txt = 'Skip';
        }

        return txt;
    }

    setOnboardingCurrentStep(step) {
        this.setState(prevState => {
            let onboarding = Object.assign({}, prevState.onboarding);
            onboarding.currentStep = step;
            return { onboarding };
        });
    }

    nextOnboardingStep(prevStep) {
        var nextStep = 0;
        if (prevStep === 2) {
            if (!this.state.onboarding.step23Done) {
                nextStep = 23;
            }
            else if (!this.state.onboarding.step3Done) {
                nextStep = 3;
            }

            this.setState(prevState => {
                let onboarding = Object.assign({}, prevState.onboarding);
                onboarding.step2Done = true;
                onboarding.currentStep = nextStep;
                return { onboarding };
            });
        }
        else if (prevStep === 23) {
            if (!this.state.onboarding.step2Done) {
                nextStep = 2;
            }
            else if (!this.state.onboarding.step3Done) {
                nextStep = 3;
            }

            this.setState(prevState => {
                let onboarding = Object.assign({}, prevState.onboarding);
                onboarding.step23Done = true;
                onboarding.currentStep = nextStep;
                return { onboarding };
            });
        }
        else if (prevStep === 3) {
            if (!this.state.onboarding.step2Done) {
                nextStep = 2;
            }
            else if (!this.state.onboarding.step23Done) {
                nextStep = 23;
            }

            this.setState(prevState => {
                let onboarding = Object.assign({}, prevState.onboarding);
                onboarding.step3Done = true;
                onboarding.currentStep = nextStep;
                return { onboarding };
            });
        }
    }

    showOnboardingStep3() {
        return this.state.onboarding.step2Done && this.state.onboarding.step23Done;
    }

    render() {
        let token = !window.navigate ? `&token=${new URLSearchParams(window.location.search).get("token")}` : "";

        const items = [];

        if(configs.stickyBuyButtonMenuEnabled) {
            items.push(
                {
                    url: 'sticky-buy-button' + this.state.model.shopQueryString + token,
                    name: 'Sticky Buy Button',
                    icon: BuyButtonMajor,
                    widget: 'stickyAddToCartBarEnabled'
                });
        }

        if(configs.quickBuyButtonMenuEnabled) {
            items.push(
            {
                url: 'quick-buy-button' + this.state.model.shopQueryString + token,
                name: 'Quick Buy Button',
                icon: QuickSaleMajor,
                widget: 'quickBuyButtonEnabled'
            });
        }

        if(configs.quickViewMenuEnabled) {
            items.push(
            {
                url: 'quick-view' + this.state.model.shopQueryString + token,
                name: 'Quick View',
                icon: ViewMajor,
                widget: 'quickViewEnabled'
            });
        }

        if(configs.stickyCheckoutBarMenuEnabled) {
            items.push(
            {
                url: 'sticky-checkout-bar' + this.state.model.shopQueryString + token,
                name: 'Sticky Checkout Bar',
                icon: BuyButtonButtonLayoutMajor,
                widget:'stickyCheckoutBarEnabled'
            });
        }

        if(configs.cartSliderMenuEnabled) {
            items.push(
            {
                url: 'cart-slider' + this.state.model.shopQueryString + token,
                name: 'Cart Slider',
                icon: SidebarRightMajor,
                widget: 'cartSliderEnabled'
            });
        }

        if(configs.stickyCartMenuEnabled) {
            items.push(
            {
                url: 'sticky-cart' + this.state.model.shopQueryString + token,
                name: 'Sticky Cart',
                icon: CartMajor,
                enabled: this.state.model.stickyCartEnabled,
                widget: 'stickyCartEnabled'
            });
        }

        const widgets = (
            <ResourceList
                items={items}
                renderItem={(item) => {
                    const { url, name, icon, enabled, widget } = item;

                    return (
                        <ResourceItem
                            url={url}
                            name={name}
                        >
                            <div className="stack-last-right">
                                <Stack>
                                    <Stack.Item pad >
                                        <Icon source={icon} />
                                    </Stack.Item>
                                    <Stack.Item pad>
                                        <Text variation="strong">{name}</Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Badge  status={enabled ? 'success' : 'critical'}>{enabled ? 'Enabled' : 'Disabled'}</Badge>
                                    </Stack.Item>
                                    <Stack.Item fill>
                                        <Button textAlign='center' onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); this.handleChange(widget, !enabled) }}>
                                            { enabled ? "Disable" : "Enable" }
                                        </Button>
                                    </Stack.Item>
                                </Stack>
                            </div>
                        </ResourceItem>
                    );
                }}
            />
        );

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title={'Hi ' + this.state.model.ownerName + ' 👋'}>
                    <Layout>
                        {/*<Layout.Section

                        >
                            <Banner
                                status="success"
                                action={{ content: 'Request Help' }}
                            >
                                <p>If the App is not showing or something not looking quite right just click the link below and we'll make sure everything is perfect!</p>
                            </Banner>
                        </Layout.Section>*/}
                        {
                            !this.state.onboarding.visible ? '' :
                                <Layout.Section>
                                    <Card title={'Let\'s get started (' + this.calcOnboardingProgress() + '%)'} sectioned
                                        primaryFooterAction={{ content: this.getOnboardingActionText(), onAction: () => { this.handleOnboardingChange(false) } }}
                                        secondaryFooterActions={this.showOnboardingSecondaryAction() ? [{ content: this.getOnboardingSecondaryActionText(), onAction: () => { this.handleOnboardingChange(true) } }] : null}>
                                        <div className="onboarding-progressbar">
                                            <div className="completed" style={{ width: this.calcOnboardingProgress() + '%' }}></div>
                                        </div>
                                        <ul className="onboarding-steps">
                                            <li><span>1. </span><span className={'step-done'}>App successfully installed</span></li>
                                            <li><span>2. </span><span onClick={() => this.setOnboardingCurrentStep(2)} className={'step-link ' + (this.state.onboarding.step2Done ? 'step-done' : (this.state.onboarding.currentStep === 2 ? 'step-current' : ''))}>App is showing correctly on the storefront</span></li>
                                            <li><span>3. </span><span onClick={() => this.setOnboardingCurrentStep(23)} className={'step-link ' + (this.state.onboarding.step23Done ? 'step-done' : (this.state.onboarding.currentStep === 23 ? 'step-current' : ''))}>Almost there</span></li>
                                            <li style={{ display: this.showOnboardingStep3() ? 'list-item' : 'none' }}><span>4. </span><span onClick={() => this.setOnboardingCurrentStep(3)} className={'step-link ' + (this.state.onboarding.step3Done ? 'step-done' : (this.state.onboarding.currentStep === 3 ? 'step-current' : ''))}>Let us know what you think (optional but takes less than 30 seconds)</span></li>
                                        </ul>
                                    </Card>
                                </Layout.Section>
                        }
                        { false &&
                        <Layout.AnnotatedSection
                            title="Quick Stats"
                            description="Statistics at a glance."
                        >
                            <Stack distribution="fillEvenly">
                                <Card sectioned title="Total clicks">
                                    {
                                        this.state.quickStats ? <DisplayText size="large">{this.state.quickStats.totalClicks}</DisplayText> :
                                            <div style={{ padding: '0.35rem' }}><Spinner size="small" color="teal" /></div>
                                    }
                                </Card>
                                <Card sectioned title="Total potential revenue">
                                    {
                                        this.state.quickStats ? <DisplayText size="large">{this.state.quickStats.totalRevenue + ' ' + this.state.quickStats.currency}</DisplayText> :
                                            <div style={{ padding: '0.35rem' }}><Spinner size="small" color="teal" /></div>
                                    }
                                </Card>
                            </Stack>
                        </Layout.AnnotatedSection> }
                        { false && <Layout.AnnotatedSection
                            title="App State"
                        //description="You can disable the app to hide it for public and then use the Preview button (step 2.) to see how it would look like if enabled (Don't forget to save the changes first!)"
                        >
                            <div className="setting-toggle-group-card">
                                <Card sectioned>
                                    <SettingToggle action={{
                                        content: this.state.model.enabled ? 'Disable' : 'Enable',
                                        onAction: this.handleEnabledChange
                                    }}
                                        enabled={this.state.model.enabled}
                                    >
                                        <div>The app is currently <TextStyle variation="strong">{this.state.model.enabled ? 'enabled' : 'disabled'}</TextStyle>.</div>
                                        <div className="custom-help-text">Master switch.</div>
                                    </SettingToggle>
                                    <SettingToggle action={{
                                        content: this.state.model.liveMode ? 'Disable' : 'Enable',
                                        onAction: this.handleLiveModeChange
                                    }}
                                        enabled={this.state.model.liveMode}
                                    >
                                        <div>Live mode is <TextStyle variation="strong">{this.state.model.liveMode ? 'enabled' : 'disabled'}</TextStyle>.</div>
                                        <div className="custom-help-text">{this.state.model.liveMode ? 'The app is visible to all visitors. You can switch it to preview mode anytime.' : 'You as the admin of the store are the only one seeing the app on your storefront. Make sure to have Shopify admin bar visible since that is how app knows you are the admin.'}</div>
                                    </SettingToggle>
                                </Card>
                            </div>
                        </Layout.AnnotatedSection>
                        }
                        <Layout.AnnotatedSection
                            title="App Status"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    {widgets}
                                </FormLayout>
                            </Card>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }}  />
                        </Layout.AnnotatedSection>
                        { false && <Layout.AnnotatedSection
                            title="General Settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Skip to Checkout when clicked on main (theme's) Add to Cart button"
                                        checked={this.state.model.skipCart}
                                        onChange={(newValue) => this.handleChange('skipCart', newValue)}
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        }
                        { false && <Layout.AnnotatedSection
                            title="Custom Seasonal Themes"
                            description=""
                        >
                            <Card sectioned>
                                <Card.Section title="Halloween">
                                    <FormLayout>
                                        <Checkbox
                                            label="Show Ghost near Sticky Button"
                                            checked={this.state.model.ghostEnabled}
                                            onChange={(newValue) => this.handleChange('ghostEnabled', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Web and Spider on Sticky Cart"
                                            checked={this.state.model.spiderEnabled}
                                            onChange={(newValue) => this.handleChange('spiderEnabled', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Bats flying around"
                                            checked={this.state.model.batsEnabled}
                                            onChange={(newValue) => this.handleChange('batsEnabled', newValue)}
                                        />
                                        <Stack alignment="center">
                                            <p>Show</p>
                                            <TextField
                                                id="batsXTimes"
                                                type="number"
                                                value={this.state.model.batsXTimes}
                                                onChange={(newValue) => this.handleChange('batsXTimes', newValue)}
                                            />
                                            <p>Bats every</p>
                                            <TextField
                                                id="batsEveryX"
                                                type="number"
                                                value={this.state.model.batsEveryX}
                                                onChange={(newValue) => this.handleChange('batsEveryX', newValue)}
                                            />
                                            <p>seconds</p>
                                        </Stack>
                                    </FormLayout>
                                </Card.Section>

                                <Card.Section title="Winter">
                                    <FormLayout>
                                        <Checkbox
                                            label="Show Santa near Sticky Button"
                                            checked={this.state.model.santaEnabled}
                                            onChange={(newValue) => this.handleChange('santaEnabled', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Santa's hat and Christmas ornament on Sticky Cart"
                                            checked={this.state.model.hatBallEnabled}
                                            onChange={(newValue) => this.handleChange('hatBallEnabled', newValue)}
                                        />
                                        <Checkbox
                                            label="Show falling Snowflakes"
                                            checked={this.state.model.snowEnabled}
                                            onChange={(newValue) => this.handleChange('snowEnabled', newValue)}
                                        />
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Valentine's Day">
                                    <FormLayout>
                                        <Checkbox
                                            label="Show Angel near Sticky Button"
                                            checked={this.state.model.angelEnabled}
                                            onChange={(newValue) => this.handleChange('angelEnabled', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Hearts on Sticky Cart"
                                            checked={this.state.model.heartsEnabled}
                                            onChange={(newValue) => this.handleChange('heartsEnabled', newValue)}
                                        />
                                        <Checkbox
                                            label="Show falling Hearts"
                                            checked={this.state.model.showFallingHearts}
                                            onChange={(newValue) => this.handleChange('showFallingHearts', newValue)}
                                        />
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Layout.AnnotatedSection>
                        }
                    </Layout>
                </Page>
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/dashboard');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);

            var onboarding = {
                visible: false,
                currentStep: 0,
                step2Done: data.onboardingStep2Done,
                step23Done: data.onboardingStep23Done,
                step3Done: data.onboardingStep3Done
            };

            if (!data.onboardingStep2Done) {
                onboarding.visible = true;
                if (onboarding.currentStep === 0) {
                    onboarding.currentStep = 2;
                }
            }

            if (!data.onboardingStep23Done) {
                onboarding.visible = true;
                if (onboarding.currentStep === 0) {
                    onboarding.currentStep = 23;
                }
            }

            if (!data.onboardingStep3Done) {
                onboarding.visible = true;
                if (onboarding.currentStep === 0) {
                    onboarding.currentStep = 3;
                }
            }

            if (data.onboardingStep3Done)
                onboarding.visible = false;

            //Temporary hide Onboading
            onboarding.visible = false;

            this.setState({ initState: data, model: data, loading: false, onboarding: onboarding });
        }
    }

    async saveChanges() {
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveDashboard', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ initState: Object.assign({}, this.state.model) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }

    async completeOnboarding() {
        const response = await fetchApi('api/admin/completeOnboarding', { method: 'post', headers: { 'Content-Type': 'application/json' } });
        const data = await response.json();

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
        }
    }

    async getQuickStats() {
        const response = await fetchApi('api/admin/analytics');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.setState({ quickStats: data });
        }
    }

}