import React, { Component } from 'react';
import { Layout, Card, SkeletonPage, TextContainer, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';

export class SkeletonPage2Custom extends Component {
    render() {
        return (
            <SkeletonPage>
                <Layout>
                    <Layout.Section>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={9} />
                            </TextContainer>
                        </Card>
                    </Layout.Section>
                    <Layout.Section secondary>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={9} />
                            </TextContainer>
                        </Card>
                    </Layout.Section>
                </Layout>
            </SkeletonPage>
        );
    }
}
