import React, { Component } from 'react';
import { Page, Layout, Banner, Card, Stack } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';

export class MoreApps extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);
        this.state = { loading: true };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="More Apps">
                    <Layout>
                        <Layout.Section>
                            <Banner
                                title="Apps recommended just for you"
                                status="info"
                            >
                                <p>More than 80% of Shopify stores that use Quick Buy Button also use one of these apps to grow their business.</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <Card sectioned>
                                <Stack distribution="fillEvenly">
                                    <a href="https://apps.shopify.com/window-shoppers?ref=wizesaas" target="_blank" rel="noreferrer" title="Go to SALES ROCKET | many apps in 1" className="more-apps-link">
                                        <div className="more-apps-container">
                                            <div className="more-apps-icon-container">
                                                <img srcSet="https://cdn.shopify.com/app-store/listing_images/61d4f60c0943274fa4104b9fb8aa42ab/icon/CJfelL30lu8CEAE=.png?height=144&width=144 2x, https://cdn.shopify.com/app-store/listing_images/61d4f60c0943274fa4104b9fb8aa42ab/icon/CJfelL30lu8CEAE=.png?height=72&width=72 1x" height="72" width="72" alt="" src="https://cdn.shopify.com/app-store/listing_images/61d4f60c0943274fa4104b9fb8aa42ab/icon/CJfelL30lu8CEAE=.png?height=72&width=72" />
                                            </div>
                                            <h4 className="more-apps-name">SALES ROCKET | 40+ apps in one app platform</h4>
                                            <div className="more-apps-context">
                                                <div className="more-apps-pricing">7-day free trial</div>
                                            </div>
                                            <p className="more-apps-details">Sales Pop Notification, Conversion & Revenue Boosters, Upsells</p>
                                            <div className="more-apps-review">
                                                <div className="more-apps-rating more-apps-rating-active" data-rating="4">
                                                    <div className="more-apps-rating-icon-set" aria-hidden="true">
                                                        <div className="more-apps-rating-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                                <path d="M13,16c-0.2,0-0.4-0.1-0.6-0.2L8,12.5l-4.4,3.3c-0.4,0.3-0.8,0.3-1.2,0c-0.4-0.3-0.5-0.7-0.3-1.1l1.9-5.2L0.4,6.8 C0.1,6.5-0.1,6.1,0.1,5.7C0.2,5.3,0.6,5,1,5h4.5l1.5-4.3C7.2,0.3,7.6,0,8,0s0.8,0.3,0.9,0.7L10.5,5H15c0.4,0,0.8,0.3,0.9,0.7 c0.1,0.4,0,0.9-0.3,1.1l-3.5,2.6l1.9,5.2c0.1,0.4,0,0.9-0.3,1.1C13.4,15.9,13.2,16,13,16z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>4.9</span>
                                                        <span className="more-apps-review-count-summary">(400+)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="https://apps.shopify.com/preorderly?ref=wizesaas" target="_blank" rel="noreferrer" title="Go to Pre‑order PRO | Pre‑orderly" className="more-apps-link">
                                        <div className="more-apps-container">
                                            <div className="more-apps-icon-container">
                                                <img srcSet="https://cdn.shopify.com/app-store/listing_images/f8c828a11729e6dbdb2c7a69524e6ae4/icon/CI2+6cP0lu8CEAE=.png?height=144&width=144 2x, https://cdn.shopify.com/app-store/listing_images/f8c828a11729e6dbdb2c7a69524e6ae4/icon/CI2+6cP0lu8CEAE=.png?height=72&width=72 1x" height="72" width="72" alt="" src="https://cdn.shopify.com/app-store/listing_images/f8c828a11729e6dbdb2c7a69524e6ae4/icon/CI2+6cP0lu8CEAE=.png?height=72&width=72" />
                                            </div>
                                            <h4 className="more-apps-name">Pre‑order PRO | Pre‑orderly</h4>
                                            <div className="more-apps-context">
                                                <div className="more-apps-pricing">7-day free trial</div>
                                            </div>
                                            <p className="more-apps-details">Pre-orders Made Easy! Manage out of stock &amp; Pre-order products</p>
                                            <div className="more-apps-review">
                                                <div className="more-apps-rating more-apps-rating-active" data-rating="4">
                                                    <div className="more-apps-rating-icon-set" aria-hidden="true">
                                                        <div className="more-apps-rating-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                                <path d="M13,16c-0.2,0-0.4-0.1-0.6-0.2L8,12.5l-4.4,3.3c-0.4,0.3-0.8,0.3-1.2,0c-0.4-0.3-0.5-0.7-0.3-1.1l1.9-5.2L0.4,6.8 C0.1,6.5-0.1,6.1,0.1,5.7C0.2,5.3,0.6,5,1,5h4.5l1.5-4.3C7.2,0.3,7.6,0,8,0s0.8,0.3,0.9,0.7L10.5,5H15c0.4,0,0.8,0.3,0.9,0.7 c0.1,0.4,0,0.9-0.3,1.1l-3.5,2.6l1.9,5.2c0.1,0.4,0,0.9-0.3,1.1C13.4,15.9,13.2,16,13,16z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>4.7</span>
                                                        <span className="more-apps-review-count-summary">(100+)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="https://apps.shopify.com/frequently-bought-together-one-click-upsell-cross-sell?ref=wizesaas" target="_blank" rel="noreferrer" title="Go to Upsell &amp; Cross Sell • Upselly" className="more-apps-link">
                                        <div className="more-apps-container">
                                            <div className="more-apps-icon-container">
                                                <img srcSet="https://cdn.shopify.com/app-store/listing_images/dbe5407b41b71e6887ec84165e65b153/icon/CNDes-bnvvgCEAE=.jpeg?height=144&width=144 2x, https://cdn.shopify.com/app-store/listing_images/dbe5407b41b71e6887ec84165e65b153/icon/CNDes-bnvvgCEAE=.jpeg?height=72&width=72 1x" height="72" width="72" alt="" src="https://cdn.shopify.com/app-store/listing_images/dbe5407b41b71e6887ec84165e65b153/icon/CNDes-bnvvgCEAE=.jpeg?height=72&width=72" />
                                            </div>
                                            <h4 className="more-apps-name">Upsell &amp; Cross Sell • Upselly</h4>
                                            <div className="more-apps-context">
                                                <div className="more-apps-pricing">7-day free trial</div>
                                            </div>
                                            <p className="more-apps-details">Frequently Bought Together 1-Click Upsell &amp; Cross Sell!</p>
                                            <div className="more-apps-review">
                                                <div className="more-apps-rating more-apps-rating-active" data-rating="4">
                                                    <div className="more-apps-rating-icon-set" aria-hidden="true">
                                                        <div className="more-apps-rating-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                                <path d="M13,16c-0.2,0-0.4-0.1-0.6-0.2L8,12.5l-4.4,3.3c-0.4,0.3-0.8,0.3-1.2,0c-0.4-0.3-0.5-0.7-0.3-1.1l1.9-5.2L0.4,6.8 C0.1,6.5-0.1,6.1,0.1,5.7C0.2,5.3,0.6,5,1,5h4.5l1.5-4.3C7.2,0.3,7.6,0,8,0s0.8,0.3,0.9,0.7L10.5,5H15c0.4,0,0.8,0.3,0.9,0.7 c0.1,0.4,0,0.9-0.3,1.1l-3.5,2.6l1.9,5.2c0.1,0.4,0,0.9-0.3,1.1C13.4,15.9,13.2,16,13,16z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>5.0</span>
                                                        <span className="more-apps-review-count-summary">(20+)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="https://apps.shopify.com/affliate-by-secomapp?utm_source=stickyaddtocartboosterpro&utm_medium=banner&ref=wizesaas" target="_blank" rel="noreferrer" title="UpPromote: Affiliate Marketing" className="more-apps-link">
                                        <div className="more-apps-container">
                                            <div className="more-apps-icon-container">
                                                <img height="72" width="72" alt="" src="./images/UpPromote.png" />
                                            </div>
                                            <h4 className="more-apps-name">UpPromote: Affiliate Marketing</h4>
                                            <div className="more-apps-context">
                                                <div className="more-apps-pricing">14-day free trial</div>
                                            </div>
                                            <p className="more-apps-details">Grow With Ambassador, Influencer, referral & Affiliate Program</p>
                                            <div className="more-apps-review">
                                                <div className="more-apps-rating more-apps-rating-active" data-rating="4">
                                                    <div className="more-apps-rating-icon-set" aria-hidden="true">
                                                        <div class="more-apps-rating more-apps-rating-active" data-rating="4"><div class="more-apps-rating-icon-set" aria-hidden="true"><div class="more-apps-rating-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13,16c-0.2,0-0.4-0.1-0.6-0.2L8,12.5l-4.4,3.3c-0.4,0.3-0.8,0.3-1.2,0c-0.4-0.3-0.5-0.7-0.3-1.1l1.9-5.2L0.4,6.8 C0.1,6.5-0.1,6.1,0.1,5.7C0.2,5.3,0.6,5,1,5h4.5l1.5-4.3C7.2,0.3,7.6,0,8,0s0.8,0.3,0.9,0.7L10.5,5H15c0.4,0,0.8,0.3,0.9,0.7 c0.1,0.4,0,0.9-0.3,1.1l-3.5,2.6l1.9,5.2c0.1,0.4,0,0.9-0.3,1.1C13.4,15.9,13.2,16,13,16z"></path>
                                                        </svg>
                                                        </div>
                                                        </div>
                                                            <div>
                                                                <span>5.0</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="https://apps.shopify.com/accessibilityspark?utm_source=stickyaddtocartboosterpro&utm_medium=banner&ref=wizesaas" target="_blank" rel="noreferrer" title="Accessibility Spark Accessibility Spark ‑ ADA & WCAG 2.1" className="more-apps-link">
                                        <div className="more-apps-container">
                                            <div className="more-apps-icon-container">
                                                <img srcSet="https://cdn.shopify.com/app-store/listing_images/13220c451d632c726c5e239ce0e8130e/icon/CKnck4aQrPICEAE=.jpeg?height=120&amp;quality=90&amp;width=120 2x, https://cdn.shopify.com/app-store/listing_images/13220c451d632c726c5e239ce0e8130e/icon/CKnck4aQrPICEAE=.jpeg?height=72&amp;quality=90&amp;width=72" height="72" width="72" alt="" src="./images/Accessibilty-Spark Logo.png" />
                                            </div>
                                            <h4 className="more-apps-name">Accessibility Spark</h4>
                                            <div className="more-apps-context">
                                                <div className="more-apps-pricing">5-day free trial</div>
                                            </div>
                                            <p className="more-apps-details">AI-Powered Web Accessibility for WCAG, EAA, & ADA Compliance</p>
                                            <div className="more-apps-review">
                                                <div className="more-apps-rating more-apps-rating-active" data-rating="4">
                                                    <div className="more-apps-rating-icon-set" aria-hidden="true">
                                                        <div class="more-apps-rating more-apps-rating-active" data-rating="4"><div class="more-apps-rating-icon-set" aria-hidden="true"><div class="more-apps-rating-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13,16c-0.2,0-0.4-0.1-0.6-0.2L8,12.5l-4.4,3.3c-0.4,0.3-0.8,0.3-1.2,0c-0.4-0.3-0.5-0.7-0.3-1.1l1.9-5.2L0.4,6.8 C0.1,6.5-0.1,6.1,0.1,5.7C0.2,5.3,0.6,5,1,5h4.5l1.5-4.3C7.2,0.3,7.6,0,8,0s0.8,0.3,0.9,0.7L10.5,5H15c0.4,0,0.8,0.3,0.9,0.7 c0.1,0.4,0,0.9-0.3,1.1l-3.5,2.6l1.9,5.2c0.1,0.4,0,0.9-0.3,1.1C13.4,15.9,13.2,16,13,16z"></path>
                                                        </svg>
                                                        </div>
                                                        </div>
                                                            <div>
                                                                <span>5.0</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="https://apps.shopify.com/rivyo-product-review?utm_source=in+app+dashboard&utm_medium=banner&utm_campaign=trackify+sticky+add+to+cart+booster+pro" target="_blank" rel="noreferrer" title="Rivyo Product Reviews & QA" className="more-apps-link">
                                        <div className="more-apps-container">
                                            <div className="more-apps-icon-container">
                                                <img srcSet="https://cdn.shopify.com/app-store/listing_images/8ffc62c9384b2409ac15e49c6da0f804/icon/CLzk4Ln0lu8CEAE=.jpg?height=120&amp;quality=90&amp;width=72 2x, https://cdn.shopify.com/app-store/listing_images/8ffc62c9384b2409ac15e49c6da0f804/icon/CLzk4Ln0lu8CEAE=.jpg?height=60&amp;quality=90&amp;width=60 1x" class="vc-app-listing-about-section__icon" height="72" width="72" alt="Rivyo Product Reviews &amp; QA" src=" https://cdn.shopify.com/app-store/listing_images/8ffc62c9384b2409ac15e49c6da0f804/icon/CLzk4Ln0lu8CEAE=.jpg?height=60&amp;quality=90&amp;width=60" />
                                            </div>
                                            <h4 className="more-apps-name">Rivyo Product Reviews & QA</h4>
                                            <div className="more-apps-context">
                                                <div className="more-apps-pricing">Free Plan Available</div>
                                            </div>
                                            <p className="more-apps-details"> Product Reviews, Photos, Amazon/Aliexpress Reviews, Q&amp;A, UGC</p>
                                            <div className="more-apps-review">
                                                <div className="more-apps-rating more-apps-rating-active" data-rating="5.0">
                                                    <div className="more-apps-rating-icon-set" aria-hidden="true">
                                                        <div class="more-apps-rating more-apps-rating-active" data-rating="4"><div class="more-apps-rating-icon-set" aria-hidden="true"><div class="more-apps-rating-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13,16c-0.2,0-0.4-0.1-0.6-0.2L8,12.5l-4.4,3.3c-0.4,0.3-0.8,0.3-1.2,0c-0.4-0.3-0.5-0.7-0.3-1.1l1.9-5.2L0.4,6.8 C0.1,6.5-0.1,6.1,0.1,5.7C0.2,5.3,0.6,5,1,5h4.5l1.5-4.3C7.2,0.3,7.6,0,8,0s0.8,0.3,0.9,0.7L10.5,5H15c0.4,0,0.8,0.3,0.9,0.7 c0.1,0.4,0,0.9-0.3,1.1l-3.5,2.6l1.9,5.2c0.1,0.4,0,0.9-0.3,1.1C13.4,15.9,13.2,16,13,16z"></path>
                                                        </svg>
                                                        </div>
                                                        </div>
                                                            <div>
                                                                <span>5.0</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </Stack>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page >
        );
    }

    async populateData() {

        this.props.setIsLoading(false);
        this.setState({ loading: false });
    }
}