/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Page, Layout, Card, Form, FormLayout, Toast, TextField, DropZone, Thumbnail, Caption, Stack, Checkbox, PageActions, InlineError, Button } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { fetchApi } from './helpers/fetchApi';

const $ = window.$;
var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export class Support extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.toggleToastActive = this.toggleToastActive.bind(this);

        this.state = {
            model: {}, loading: true, toastActive: false, files: [], maxFileSize: 19456,
            toastMessage: 'Thank you for your email! Someone will get back to you ASAP.',
            error:
            {
                name: false,
                email: false,
                invalidEmail: false,
                storeUrl: false,
                message: false,
            }
        };
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            return { model };
        });
    }

    handleBlur(fieldName) {
        var val = this.state.model[fieldName];
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);
            if (val) {
                error[fieldName] = false;
            }
            else {
                error[fieldName] = true;
            }
            if (!error[fieldName] && fieldName === 'email') {
                error.invalidEmail = !pattern.test(val);
            }
            return { error };
        });
    }

    handleDropZoneDrop(files) {
        var tFiles = [];
        this.state.files.forEach(function (file) {
            tFiles.push(file);
        });
        files.forEach(function (file) {
            tFiles.push(file);
        });

        this.setState({ files: tFiles });
    }

    toggleToastActive() {
        this.setState({ toastActive: !this.state.toastActive });
    }

    totalFileSize() {
        var totalFileSize = 0;
        this.state.files.forEach(function (file) {
            totalFileSize += file.size;
        });
        return totalFileSize / 1024;
    }

    deleteFile(e, index) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.files.length > index) {
            this.state.files.splice(index, 1);
            this.setState({ files: this.state.files });
        }
    }

    render() {

        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        const fileUpload = !this.state.files.length && <DropZone.FileUpload />;
        const uploadedFiles = this.state.files.length > 0 && (
            <Stack vertical>
                {this.state.files.map((file, index) => (
                    <Stack alignment="center" key={index}>
                        <Stack.Item>
                            <Thumbnail
                                size="small"
                                alt={file.name}
                                source={
                                    validImageTypes.indexOf(file.type) > 0
                                        ? window.URL.createObjectURL(file)
                                        : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                                }
                            />
                        </Stack.Item>
                        <Stack.Item fill>
                            <div>
                                <Stack>
                                    <Stack.Item fill>
                                        {file.name} <Caption>{(file.size / 1024).toFixed(2)} KB</Caption>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <div className='drop-zone-delete'>
                                            <Button plain onClick={(e) => this.deleteFile(e, index)}>Delete</Button>
                                        </div>
                                    </Stack.Item>
                                </Stack>
                            </div>
                        </Stack.Item>
                    </Stack>
                ))}
            </Stack>
        );

        const toastMarkup = this.state.toastActive ? (
            <Toast onDismiss={this.toggleToastActive} content={this.state.toastMessage} />
        ) : null;

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Get Support">
                    <Form>
                        <Layout>
                            {/* <Layout.Section>
                                <Banner
                                    title="Contact us"
                                    status="info"
                                >
                                    <p>Send us a message and we'll get back to you asap</p>
                                </Banner>
                            </Layout.Section> */}
                            <Layout.AnnotatedSection
                                title="Your Info"
                                description=""
                            >
                                <Card sectioned>
                                    <FormLayout>
                                        <TextField
                                            id="support_name"
                                            value={this.state.model.name}
                                            onChange={(newValue) => this.handleChange('name', newValue)}
                                            label="Name"
                                            error={this.state.error.name ? 'Name is required' : ''}
                                            onBlur={() => this.handleBlur('name')}
                                        />
                                        <TextField
                                            id="support_email"
                                            type="email"
                                            value={this.state.model.email}
                                            onChange={(newValue) => this.handleChange('email', newValue)}
                                            label="Email"
                                            error={this.state.error.email ? 'Email is required' : (this.state.error.invalidEmail ? 'Email is not valid' : '')}
                                            onBlur={() => this.handleBlur('email')}
                                        />
                                        <Checkbox
                                            label="Send copy of the message to the email above as well"
                                            checked={this.state.model.sendCopy}
                                            onChange={(newValue) => this.handleChange('sendCopy', newValue)}
                                        />
                                        <TextField
                                            id="support_storeUrl"
                                            value={this.state.model.storeUrl}
                                            onChange={(newValue) => this.handleChange('storeUrl', newValue)}
                                            label="Store or other related URL(s)"
                                            error={this.state.error.storeUrl ? 'This field is required' : ''}
                                            onBlur={() => this.handleBlur('storeUrl')}
                                            helpText="Paste links to any related pages here"
                                        />
                                        <TextField
                                            value={this.state.model.storefrontPassword}
                                            onChange={(newValue) => this.handleChange('storefrontPassword', newValue)}
                                            label="Storefront Password"
                                            helpText="If your storefront is password protected and cannot be accessed by public, please provide us the storefront password (not your Shopify admin password!)"
                                        />
                                    </FormLayout>
                                </Card>
                            </Layout.AnnotatedSection>
                            <Layout.AnnotatedSection
                                title="Your Message"
                                description="Use Attachments to send us any screenshots or screen recordings"
                            >
                                <Card sectioned>
                                    <FormLayout>
                                        <TextField
                                            id="support_message"
                                            value={this.state.model.message}
                                            onChange={(newValue) => this.handleChange('message', newValue)}
                                            label="Message"
                                            multiline
                                            error={this.state.error.message ? 'Message is required' : ''}
                                            onBlur={() => this.handleBlur('message')}
                                        />
                                        <DropZone label="Attachments" id="attachment" onDrop={(files) => this.handleDropZoneDrop(files)}>
                                            {uploadedFiles}
                                            {fileUpload}
                                        </DropZone>
                                        {
                                            this.totalFileSize() <= this.state.maxFileSize ? '' :
                                                <InlineError message="Maximum size for all attachments is 19 MB" />
                                        }

                                    </FormLayout>
                                </Card>
                            </Layout.AnnotatedSection>
                            <Layout.Section>
                                <PageActions primaryAction={{ content: 'Send', onAction: () => { this.saveChanges() } }} />
                            </Layout.Section>
                        </Layout>
                    </Form>
                    {toastMarkup}
                </Page>
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/support');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ model: data, loading: false });
        }
    }

    async saveChanges() {

        if (!this.state.model.name) {
            this.handleBlur('name');
            $('#support_name').focus();
            return;
        }

        if (!this.state.model.email || !pattern.test(this.state.model.email)) {
            this.handleBlur('email');
            $('#support_email').focus();
            return;
        }

        if (!this.state.model.storeUrl) {
            this.handleBlur('storeUrl');
            $('#support_storeUrl').focus();
            return;
        }

        if (!this.state.model.message) {
            this.handleBlur('message');
            $('#support_message').focus();
            return;
        }

        if (this.totalFileSize() > this.state.maxFileSize) {
            return;
        }

        let formData = new FormData();
        formData.append('Name', this.state.model.name);
        formData.append('Email', this.state.model.email);
        formData.append('Message', this.state.model.message);
        formData.append('StoreUrl', this.state.model.storeUrl);
        formData.append('StorefrontPassword', this.state.model.storefrontPassword);
        formData.append('SendCopy', this.state.model.sendCopy);

        $.each(this.state.files, function (index, file) {
            formData.append('file' + index, file);
        });

        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/sendSupport', { method: 'post', body: formData });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ toastMessage: data.message });
                this.toggleToastActive();
            }
        }
    }
}