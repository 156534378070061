import React, { Component } from 'react';
import { Page, Layout, Card, FormLayout, Banner, SettingToggle, TextStyle, Checkbox, Stack, Select, PageActions } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { ColorPickerCustom } from './ColorPickerCustom';
import { fetchApi } from './helpers/fetchApi';
 

export class StickyCart extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openAppEmbedSettings = this.openAppEmbedSettings.bind(this);
        this.togglePreviewActive = this.togglePreviewActive.bind(this);

        this.state = { initState: {}, model: {}, loading: true, previewActive: true };
    }

    componentDidMount() {
        this.populateData();
        this.props.setSaveChanges(this.saveChanges);
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });
    }
    openAppEmbedSettings() {
        let appEmbeddUrl = "https://" + this.props.common.shopName + "/admin/themes/current/editor?context=apps&&template=product&activateAppId=0a35f0d3-bf5f-46dd-be9d-765a0f04d464%2Fwizesaas/app-embed";
        window.appBridgeRedirectExternal(appEmbeddUrl);
    }

    togglePreviewActive() {
        this.setState({ previewActive: !this.state.previewActive });
        setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
    }

    render() {

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Sticky Cart">
                    <Layout>
                        <Layout.Section

                        >
                            <Banner
                                title="What is Sticky Cart?"
                                status="info"
                            >
                                <p>Sticky Cart is a bubble cart that is always visible making easier for customers to checkout.</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <div id="preview" className={this.state.previewActive ? '' : 'preview-hidden'}>
                                <Card title="Preview" sectioned actions={[{ content: this.state.previewActive ? 'Collapse ↑' : 'Expand ↓', onAction: this.togglePreviewActive }]}>
                                    <div id="wizesaas_sticky_cart" style={{ display: this.state.previewActive ? 'block' : 'none' }}>
                                        <a href="/#" onClick={(e) => e.preventDefault()} className="animated bounceIn" style={{
                                            left: this.state.model.position === 'top_right' || this.state.model.position === 'bottom_right' ? 'auto' : '0',
                                            right: this.state.model.position === 'top_right' || this.state.model.position === 'bottom_right' ? '0' : 'auto',
                                            top: this.state.model.position === 'top_right' || this.state.model.position === 'top_left' ? '0' : 'auto',
                                            bottom: this.state.model.position === 'bottom_right' || this.state.model.position === 'bottom_left' ? '0' : 'auto'
                                        }}>
                                            <span className={this.state.model.stickyCartIcon ? "glyphicon" : "glyphicon glyphicon-shopping-cart"} style={{ backgroundColor: this.state.model.backgroundColor, color: this.state.model.iconColor }}>
                                                {this.state.model.stickyCartIcon ? <img id='wizesaas_sticky_cart_icon' src={this.state.model.stickyCartIcon} width='30' height='30' alt='WIZESAAS sticky cart icon' /> : ""}
                                                <mark style={{ backgroundColor: this.state.model.totalQuantityBackgroundColor, color: this.state.model.totalQuantityTextColor }}>7</mark>
                                            </span>
                                        </a>
                                    </div>
                                </Card>
                            </div>
                        </Layout.Section>
                        <Layout.AnnotatedSection
                            title="Sticky Cart State"
                        >
                            <SettingToggle action={{
                                content: this.props.common.isAppEmbedEnabled ? 'Disable' : 'Enable',
                                onAction: this.openAppEmbedSettings
                            }}
                                enabled={this.props.common.isAppEmbedEnabled}
                                external={true}
                            >
                                Sticky Cart is currently <TextStyle variation="strong">{this.props.common.isAppEmbedEnabled ? 'enabled' : 'disabled'}</TextStyle>.
                            </SettingToggle>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="General Settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Show on tablets and mobile devices"
                                        checked={this.state.model.showOnMobile}
                                        onChange={(newValue) => this.handleChange('showOnMobile', newValue)}
                                    />
                                    <Checkbox
                                        label="Show on desktop computers"
                                        checked={this.state.model.showOnDesktop}
                                        onChange={(newValue) => this.handleChange('showOnDesktop', newValue)}
                                    />
                                    <Checkbox
                                        label="Hide Sticky Cart on Cart page"
                                        checked={this.state.model.hideOnCartPage}
                                        onChange={(newValue) => this.handleChange('hideOnCartPage', newValue)}
                                    />
                                    <Checkbox
                                        label="Hide Sticky Cart when cart is empty"
                                        checked={this.state.model.hideWhenEmpty}
                                        onChange={(newValue) => this.handleChange('hideWhenEmpty', newValue)}
                                    />
                                    
                                    <Select
                                        label="Position"
                                        options={[{ label: 'Top Right', value: 'top_right' }, { label: 'Bottom Right', value: 'bottom_right' }, { label: 'Top Left', value: 'top_left' }, { label: 'Bottom Left', value: 'bottom_left' }]}
                                        onChange={(newValue) => this.handleChange('position', newValue)}
                                        value={this.state.model.position}
                                    />
                                    <Select
                                        label="Click on Sticky Cart redirects"
                                        options={[{ label: 'To Checkout when on Cart page otherwise to Cart', value: '' }, { label: 'To Cart', value: '/cart' }, { label: 'To Checkout', value: '/checkout' }]}
                                        onChange={(newValue) => this.handleChange('postAction', newValue)}
                                        value={this.state.model.postAction}
                                    />
                                    
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Look and Feel"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Background Color"
                                            fieldName="backgroundColor"
                                            value={this.state.model.backgroundColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Icon Color"
                                            fieldName="iconColor"
                                            value={this.state.model.iconColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Total Quantity Text Color"
                                            fieldName="totalQuantityTextColor"
                                            value={this.state.model.totalQuantityTextColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Total Quantity Background Color"
                                            fieldName="totalQuantityBackgroundColor"
                                            value={this.state.model.totalQuantityBackgroundColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.Section>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }} />
                        </Layout.Section>
                    </Layout>
                </Page >
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/stickyCart');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ initState: data, model: data, loading: false });

            window.$('#preview').sticky({ topSpacing: 56, zIndex: 100 });
            window.$('#wizesaas_sticky_cart a').click(function (e) {
                e.preventDefault();
            });
            window.$("#wizesaas_sticky_cart a").hover(function () {
                window.$("#wizesaas_sticky_cart mark").wizesaas_animateCss('swing');
            });
        }
    }

    async saveChanges() {
        
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveStickyCart', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ initState: Object.assign({}, this.state.model) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }
}