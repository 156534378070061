import React, { Component } from 'react';
import { Page, Layout, Card, FormLayout, TextField, Banner, SettingToggle, TextStyle, Checkbox, PageActions, Select, TextContainer, OptionList, ResourceList, ResourceItem } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { ColorPickerCustom } from './ColorPickerCustom';
import { EmojiPickerCustom } from './EmojiPickerCustom';
import { CountryPickerCustom } from './CountryPickerCustom';
import { CartSliderShippingBar } from './CartSliderShippingBar';
import { CartSliderCartReserved } from './CartSliderCartReserved';
import { CartSliderInCartUpsell } from './CartSliderInCartUpsell';
import { fetchApi } from './helpers/fetchApi';

const $ = window.$;

export class CartSlider extends Component {

    constructor(props, ref) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOptions = this.handleChangeOptions.bind(this);
        this.togglePreviewActive = this.togglePreviewActive.bind(this);
        this.handleChangeDemoQty = this.handleChangeDemoQty.bind(this);
        this.handleDemoQtyUpDown = this.handleDemoQtyUpDown.bind(this);
        this.removeItem = this.removeItem.bind(this);

        this.state = {
            initState: {}, model: {}, loading: true, common: this.props.common, selectedBundles: [], previewActive: false, demoQty: 1, visibleItem: true,
            error:
            {
                closeAfterSeconds: false,
                countdownMinutes_CartReserved: false,
                notShowHours_CartReserved: false,
                messageFontSize_CartReserved: false,
                minAmount_ShippingBar: false,
                bundledProductsCount_InCartUpsell: false,
            }
        };
    }

    componentDidMount() {
        this.populateData();
        this.props.setSaveChanges(this.saveChanges);

        // var moveTo = this.props.location.hash;
        // if (moveTo) {
        //     var handle = setInterval(function () {
        //         if (window.$(moveTo).length) {
        //             clearInterval(handle);
        //             window.location.hash = '';
        //             window.location.hash = moveTo;
        //         }
        //     }, 50);
        // }
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });
    }

    handleChangeOptions(newValue) {
        if (newValue === '0') {
            this.handleChange('clearLowQuantityItemsFromCartOnTimerEnd_CartReserved', false);
            this.handleChange('clearCartOnTimerEnd_CartReserved', false);
            this.handleChange('selectedRemoveProductOption_CartReserved', '0');
        } else if (newValue === '1') {
            this.handleChange('clearLowQuantityItemsFromCartOnTimerEnd_CartReserved', true);
            this.handleChange('clearCartOnTimerEnd_CartReserved', false);
            this.handleChange('selectedRemoveProductOption_CartReserved', '1');
        } else if (newValue === '2') {
            this.handleChange('clearCartOnTimerEnd_CartReserved', true);
            this.handleChange('clearLowQuantityItemsFromCartOnTimerEnd_CartReserved', false);
            this.handleChange('selectedRemoveProductOption_CartReserved', '2');
        } 
    }

    selectBundles(newValue) {
        this.setState({ selectedBundles: newValue });
    }

    deleteBundles() {
        var items = this.state.model.bundles_InCartUpsell.filter((option) => this.state.selectedBundles.indexOf(option.id) === -1);
        this.handleChange('bundles_InCartUpsell', items);
        this.selectBundles([]);
    }

    togglePreviewActive() {
        var showDemo = !this.state.previewActive;
        this.setState({ previewActive: showDemo });

        setTimeout(function () {
            if (showDemo) {
                window.$('.wizesaas-cs').addClass('wizesaas-cs-open');
                window.$('body').addClass('wizesaas-cs-modal-open');
                window.$('.wizesaas-cs-content').animate({ 'margin-right': "0" }, 400);
            }
            else {
                window.$('.wizesaas-cs-content').animate({ 'margin-right': -window.$('.wizesaas-cs-content').width() }, 400, function () {
                    window.$('.wizesaas-cs').removeClass('wizesaas-cs-open');
                    window.$('body').removeClass('wizesaas-cs-modal-open');
                });
            }
        }, 0);
    }

    handleChangeDemoQty(event) {
        this.setState({ demoQty: event.target.value });
    }

    handleDemoQtyUpDown(value, e) {
        e.preventDefault();
        var val = this.state.demoQty + value;
        if (val > 0) {
            this.setState({ demoQty: val });
        }
    }

    handleBlur(fieldName, minValue) {
        var val = parseFloat(this.state.model[fieldName]);
        var hasError = !(val >= minValue);

        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);
            error[fieldName] = hasError;
            return { error };
        });

        return hasError;
    }

    removeItem() {
        this.setState({ visibleItem: false });
    }

    getCurrencySymbol() {
        var symbol = this.state.common.currency;
        switch (symbol) {
            case 'USD':
                symbol = '$';
                break;
            case 'EUR':
                symbol = '€';
                break;
            default:
                symbol += ' ';
                break;
        }

        return symbol;
    }

    render() {
        let token = !window.navigate ? `&token=${new URLSearchParams(window.location.search).get("token")}` : "";

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Cart Slider">
                    <div className="wizesaas-cs">
                        <div className="wizesaas-cs-content">
                            <div className="wizesaas-cs-header">
                                <span className="wizesaas-cs-header-title">{this.state.model.cartTitle_Translation} <span className="wizesaas-cs-header-count">{this.state.demoQty}</span></span>
                                <span className="wizesaas-cs-close" onClick={this.togglePreviewActive}></span>
                            </div>
                            <div className="wizesaas-cs-top-container">
                                {
                                    this.state.model.enabled_CartReserved && this.state.model.position_CartReserved === 'top' ?
                                        <CartSliderCartReserved
                                            model={this.state.model}
                                        /> : ''
                                }
                                {
                                    this.state.model.enabled_ShippingBar && this.state.model.position_ShippingBar === 'top' ?
                                        <CartSliderShippingBar
                                            model={this.state.model}
                                            totalPrice={this.state.model.demoProduct ? this.state.model.demoProduct.defaultVariant.price : 0}
                                            currency={this.getCurrencySymbol()}
                                        /> : ''
                                }
                                {
                                    this.state.visibleItem && this.state.model.enabled_InCartUpsell && this.state.model.position_InCartUpsell === 'top' ?
                                        <CartSliderInCartUpsell
                                            model={this.state.model}
                                            product={this.state.model.demoProduct}
                                            currency={this.getCurrencySymbol()}
                                        /> : ''
                                }
                            </div>
                            <div className={'wizesaas-cs-items' + (!this.state.visibleItem ? ' wizesaas-cs-cart-empty' : '')}>
                                {
                                    this.state.visibleItem ?
                                        <div className="wizesaas-cs-item">
                                            <div className="wizesaas-cs-item-img">
                                                <a href={this.state.model.demoProduct.url} target="_blank" rel="noreferrer"><img src={this.state.model.demoProduct.imageUrl} alt={this.state.model.demoProduct.title} /></a>
                                            </div>
                                            <div className="wizesaas-cs-item-main">
                                                <div className="wizesaas-cs-item-main-top">
                                                    <div className="wizesaas-cs-item-main-top-left">
                                                        <div className="wizesaas-cs-item-title">
                                                            <a href={this.state.model.demoProduct.url} target="_blank" rel="noreferrer">{this.state.model.demoProduct.title}</a>
                                                        </div>
                                                        <div className="wizesaas-cs-item-variant">{this.state.model.demoProduct.defaultVariant.title}</div>
                                                    </div>
                                                </div>
                                                <span className="wizesaas-cs-item-close" onClick={this.removeItem}></span>
                                                <div className="wizesaas-cs-item-main-bottom">
                                                    <div className="wizesaas-cs-item-qty">
                                                        <a href="/#" onClick={(e) => this.handleDemoQtyUpDown(-1, e)}><svg width="10" viewBox="0 0 12 2" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 0H.75C.3 0 0 .4 0 1s.3 1 .75 1h10.5c.45 0 .75-.4.75-1s-.3-1-.75-1z"></path></svg></a>
                                                        <input type="text" value={this.state.demoQty} onChange={this.handleChangeDemoQty} />
                                                        <a href="/#" onClick={(e) => this.handleDemoQtyUpDown(1, e)}><svg width="10" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 002 0V7h4a1 1 0 000-2z"></path></svg></a>
                                                    </div>
                                                    <span className="wizesaas-cs-item-price">
                                                        {this.getCurrencySymbol() + this.state.model.demoProduct.defaultVariant.price}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>{this.state.model.cartEmptyText_Translation}</div>
                                }
                            </div>
                            <div className="wizesaas-cs-bottom-container">
                                {
                                    this.state.model.enabled_CartReserved && this.state.model.position_CartReserved === 'bottom' ?
                                        <CartSliderCartReserved
                                            model={this.state.model}
                                        /> : ''
                                }
                                {
                                    this.state.model.enabled_ShippingBar && this.state.model.position_ShippingBar === 'bottom' ?
                                        <CartSliderShippingBar
                                            model={this.state.model}
                                            totalPrice={this.state.model.demoProduct ? this.state.model.demoProduct.defaultVariant.price : 0}
                                            currency={this.getCurrencySymbol()}
                                        /> : ''
                                }
                                {
                                    this.state.visibleItem && this.state.model.enabled_InCartUpsell && this.state.model.position_InCartUpsell === 'bottom' ?
                                        <CartSliderInCartUpsell
                                            model={this.state.model}
                                            product={this.state.model.demoProduct}
                                            currency={this.getCurrencySymbol()}
                                        /> : ''
                                }
                            </div>
                            {
                                this.state.visibleItem ?
                                    <div className={'wizesaas-cs-footer' + (this.state.model.stickyFooter ? ' wizesaas-cs-footer-sticky' : '')}>
                                        <div className="wizesaas-cs-footer-subtotal">
                                            <span>{this.state.model.subtotal_Translation}</span><span>{this.getCurrencySymbol() + this.state.model.demoProduct.defaultVariant.price}</span>
                                        </div>
                                        <div>
                                            <div className="wizesaas-cs-taxes-shipping-note">{this.state.model.taxesAndShippingNote_Translation}</div>
                                            <a href="/#" onClick={(e) => e.preventDefault()} style={{ backgroundColor: this.state.model.checkoutButtonBackgroundColor, color: this.state.model.checkoutButtonColor }} className="wizesaas-cs-checkout-btn">{this.state.model.checkoutButtonText_Translation}</a>
                                            {
                                                this.state.model.showViewCart ?
                                                    <a href="/#" className="wizesaas-cs-view-cart" onClick={(e) => e.preventDefault()}>{this.state.model.viewCartText_Translation}</a> : ''
                                            }
                                            {
                                                this.state.model.showContinueShopping ?
                                                    <a href="/#" className="wizesaas-cs-continue-shopping" onClick={(e) => e.preventDefault()}>{this.state.model.continueShoppingText_Translation}</a> : ''
                                            }
                                        </div>
                                    </div> : ''
                            }
                        </div>
                    </div>
                    <Layout>
                        <Layout.Section>
                            <Banner
                                title="What is Cart Slider?"
                                status="info"
                            >
                                <p>Cart Slider is interactive cart drawer that improves UX and will show on add to cart instead of redirecting to Cart page. Enable Cart Reserved, Shipping Bar, and In Cart Upsell to reduce cart abandonment and increase AOV.</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <div id="preview" className="preview-hidden">
                                <Card title="Preview" sectioned actions={[{ content: this.state.previewActive ? 'Hide' : 'Show', onAction: this.togglePreviewActive }]}>
                                </Card>
                            </div>
                        </Layout.Section>
                        <Layout.AnnotatedSection
                            title="Cart Slider State"
                        >
                            <div className="setting-toggle-card">
                                <Card>
                                    <SettingToggle action={{
                                        content: this.state.model.enabled ? 'Disable' : 'Enable',
                                        onAction: () => { this.handleChange('enabled', !this.state.model.enabled) }
                                    }}
                                        enabled={this.state.model.enabled}
                                    >
                                        Cart Slider is currently <TextStyle variation="strong">{this.state.model.enabled ? 'enabled' : 'disabled'}</TextStyle>.
                                </SettingToggle>
                                    <Card.Section>
                                        <TextContainer>
                                            <Banner>
                                                <p>
                                                    Please note, if your theme has a popup/drawer cart you should disable it in the theme settings.
                                    </p>
                                            </Banner>
                                        </TextContainer>
                                    </Card.Section>
                                </Card>
                            </div>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection title="Settings">
                            <Card>
                                <Card.Section>
                                    <FormLayout>
                                        <Checkbox
                                            label="Open on Add to Cart"
                                            checked={this.state.model.openOnAddToCart}
                                            onChange={(newValue) => this.handleChange('openOnAddToCart', newValue)}
                                        />
                                        {
                                            this.state.model.openOnAddToCart ?
                                                <FormLayout.Group>
                                                    <Checkbox
                                                        label="Close after"
                                                        checked={this.state.model.closeAfter}
                                                        onChange={(newValue) => this.handleChange('closeAfter', newValue)}
                                                    />
                                                    <TextField
                                                        id="cartSlider_closeAfterSeconds"
                                                        suffix="seconds"
                                                        type="number"
                                                        min="2"
                                                        disabled={!this.state.model.closeAfter}
                                                        value={this.state.model.closeAfterSeconds}
                                                        onChange={(newValue) => this.handleChange('closeAfterSeconds', newValue)}
                                                        error={this.state.error.closeAfterSeconds ? 'Value must be greater than or equal to 2' : ''}
                                                        onBlur={() => this.handleBlur('closeAfterSeconds', 2)}
                                                    />
                                                </FormLayout.Group> : ''
                                        }
                                        <Checkbox
                                            label="Show View Cart link"
                                            checked={this.state.model.showViewCart}
                                            onChange={(newValue) => this.handleChange('showViewCart', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Continue shopping link"
                                            checked={this.state.model.showContinueShopping}
                                            onChange={(newValue) => this.handleChange('showContinueShopping', newValue)}
                                        />
                                        <Checkbox
                                            label="Fixed Footer"
                                            checked={this.state.model.stickyFooter}
                                            onChange={(newValue) => this.handleChange('stickyFooter', newValue)}
                                        />
                                        <TextField
                                            value={this.state.model.cartButtonCSSSelector}
                                            onChange={(newValue) => this.handleChange('cartButtonCSSSelector', newValue)}
                                            label="Cart button CSS selector"
                                        />
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Translations">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.cartTitle_Translation}
                                                onChange={(newValue) => this.handleChange('cartTitle_Translation', newValue)}
                                                label="Cart title"
                                            />
                                            <TextField
                                                value={this.state.model.cartEmptyText_Translation}
                                                onChange={(newValue) => this.handleChange('cartEmptyText_Translation', newValue)}
                                                label="Cart empty text"
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.checkoutButtonText_Translation}
                                                onChange={(newValue) => this.handleChange('checkoutButtonText_Translation', newValue)}
                                                label="Checkout button text"
                                            />
                                            <TextField
                                                value={this.state.model.continueShoppingText_Translation}
                                                onChange={(newValue) => this.handleChange('continueShoppingText_Translation', newValue)}
                                                label="Continue shopping text"
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.subtotal_Translation}
                                                onChange={(newValue) => this.handleChange('subtotal_Translation', newValue)}
                                                label="Subtotal label"
                                            />
                                            <TextField
                                                value={this.state.model.viewCartText_Translation}
                                                onChange={(newValue) => this.handleChange('viewCartText_Translation', newValue)}
                                                label="View Cart Text"
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.taxesAndShippingNote_Translation}
                                                onChange={(newValue) => this.handleChange('taxesAndShippingNote_Translation', newValue)}
                                                label="Taxes And Shipping Note"
                                            />
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Look and Feel">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Checkout Button Background Color"
                                                fieldName="checkoutButtonBackgroundColor"
                                                value={this.state.model.checkoutButtonBackgroundColor}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Checkout Button Color"
                                                fieldName="checkoutButtonColor"
                                                value={this.state.model.checkoutButtonColor}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection title="Cart Reserved">
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Enabled"
                                        checked={this.state.model.enabled_CartReserved}
                                        onChange={(newValue) => this.handleChange('enabled_CartReserved', newValue)}
                                    />
                                    <FormLayout.Group>
                                        <TextField
                                            id="cartSlider_countdownMinutes_CartReserved"
                                            label="Timer Duration"
                                            suffix="Minute(s)"
                                            type="number"
                                            min="1"
                                            value={this.state.model.countdownMinutes_CartReserved}
                                            onChange={(newValue) => this.handleChange('countdownMinutes_CartReserved', newValue)}
                                            error={this.state.error.countdownMinutes_CartReserved ? 'Value must be greater than or equal to 1' : ''}
                                            onBlur={() => this.handleBlur('countdownMinutes_CartReserved', 1)}
                                        />
                                        <ColorPickerCustom
                                            label="Color of Timer"
                                            fieldName="colorOfTimer_CartReserved"
                                            value={this.state.model.colorOfTimer_CartReserved}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Background Color"
                                            fieldName="backgroundColor_CartReserved"
                                            value={this.state.model.backgroundColor_CartReserved}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Text Color"
                                            fieldName="textColor_CartReserved"
                                            value={this.state.model.textColor_CartReserved}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Border Color"
                                            fieldName="borderColor_CartReserved"
                                            value={this.state.model.borderColor_CartReserved}
                                            handleChange={this.handleChange}
                                        />
                                        <TextField
                                            label="Border Radius"
                                            suffix="px"
                                            type="number"
                                            value={this.state.model.borderRadius_CartReserved}
                                            onChange={(newValue) => this.handleChange('borderRadius_CartReserved', newValue)}
                                            clearButton
                                            onClearButtonClick={() => this.handleChange('borderRadius_CartReserved', null)}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <Select
                                            label="Remove items from cart after timer expires"
                                            options={[{ label: "Don't remove items", value: '0' }, { label: 'Only remove low inventory items', value: '1' }, { label: 'Remove all items', value: '2' },]}
                                            onChange={(newValue) => this.handleChangeOptions(newValue)}
                                            value={this.state.model.selectedRemoveProductOption_CartReserved}
                                        />

                                        <TextField
                                            label="Thresold inventory level"
                                            suffix="items"
                                            type="number"
                                            min="1"
                                            disabled={!this.state.model.clearLowQuantityItemsFromCartOnTimerEnd_CartReserved}
                                            value={this.state.model.lowQuantityThreshold_CartReserved}
                                            onChange={(newValue) => this.handleChange('lowQuantityThreshold_CartReserved', newValue)}
                                        />
                                    </FormLayout.Group>
                                    <TextField
                                        id="cartSlider_notShowHours_CartReserved"
                                        label="Do not show expired Timer to the same visitor again for a number of hours"
                                        suffix="Hour(s)"
                                        type="number"
                                        min="0"
                                        value={this.state.model.notShowHours_CartReserved}
                                        onChange={(newValue) => this.handleChange('notShowHours_CartReserved', newValue)}
                                        error={this.state.error.notShowHours_CartReserved ? 'Value must be greater than or equal to 0' : ''}
                                        onBlur={() => this.handleBlur('notShowHours_CartReserved', 0)}
                                    />
                                    <TextField
                                        value={this.state.model.message_CartReserved}
                                        onChange={(newValue) => this.handleChange('message_CartReserved', newValue)}
                                        label="Message"
                                    />
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.messageBeforeTimer_CartReserved}
                                            onChange={(newValue) => this.handleChange('messageBeforeTimer_CartReserved', newValue)}
                                            label="Message before timer"
                                        />
                                        <TextField
                                            value={this.state.model.messageAfterTimer_CartReserved}
                                            onChange={(newValue) => this.handleChange('messageAfterTimer_CartReserved', newValue)}
                                            label="Message after timer"
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <EmojiPickerCustom
                                            label="Emoji in front of the message"
                                            fieldName="emoji_CartReserved"
                                            value={this.state.model.emoji_CartReserved}
                                            handleChange={this.handleChange}
                                        />
                                        <TextField
                                            id="cartSlider_countdownMinutes_CartReserved"
                                            label="Message font size"
                                            suffix="px"
                                            type="number"
                                            min="1"
                                            value={this.state.model.messageFontSize_CartReserved}
                                            onChange={(newValue) => this.handleChange('messageFontSize_CartReserved', newValue)}
                                            error={this.state.error.messageFontSize_CartReserved ? 'Value must be greater than or equal to 1' : ''}
                                            onBlur={() => this.handleBlur('messageFontSize_CartReserved', 1)}
                                        />
                                    </FormLayout.Group>
                                    <Select
                                        label="Position"
                                        options={[{ label: 'Top', value: 'top' }, { label: 'Bottom', value: 'bottom' }]}
                                        onChange={(newValue) => this.handleChange('position_CartReserved', newValue)}
                                        value={this.state.model.position_CartReserved}
                                    />
                                    
                                    

                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection title="Shipping Bar">
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Enabled"
                                        checked={this.state.model.enabled_ShippingBar}
                                        onChange={(newValue) => this.handleChange('enabled_ShippingBar', newValue)}
                                    />
                                    <CountryPickerCustom
                                        options={this.state.common.countries}
                                        label="Countries eligible for free shipping (leave empty for all)"
                                        fieldName="eligibleCountries_ShippingBar"
                                        value={this.state.model.eligibleCountries_ShippingBar}
                                        handleChange={this.handleChange}
                                    />
                                    <FormLayout.Group>
                                        <TextField
                                            id="cartSlider_minAmount_ShippingBar"
                                            label="Min Amount to Spend"
                                            suffix={this.state.common.currency}
                                            type="number"
                                            min="0"
                                            value={this.state.model.minAmount_ShippingBar}
                                            onChange={(newValue) => this.handleChange('minAmount_ShippingBar', newValue)}
                                            error={this.state.error.minAmount_ShippingBar ? 'Value must be greater than or equal to 0' : ''}
                                            onBlur={() => this.handleBlur('minAmount_ShippingBar', 0)}
                                        />
                                        <ColorPickerCustom
                                            label="Amount Color"
                                            fieldName="amountColor_ShippingBar"
                                            value={this.state.model.amountColor_ShippingBar}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Background Color"
                                            fieldName="backgroundColor_ShippingBar"
                                            value={this.state.model.backgroundColor_ShippingBar}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Text Color"
                                            fieldName="textColor_ShippingBar"
                                            value={this.state.model.textColor_ShippingBar}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.initialMessageBeforeAmount_ShippingBar}
                                            onChange={(newValue) => this.handleChange('initialMessageBeforeAmount_ShippingBar', newValue)}
                                            label="Initial message before amount"
                                        />
                                        <TextField
                                            value={this.state.model.initialMessageAfterAmount_ShippingBar}
                                            onChange={(newValue) => this.handleChange('initialMessageAfterAmount_ShippingBar', newValue)}
                                            label="Initial message after amount"
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.progressMessageBeforeAmount_ShippingBar}
                                            onChange={(newValue) => this.handleChange('progressMessageBeforeAmount_ShippingBar', newValue)}
                                            label="Progress message before amount"
                                        />
                                        <TextField
                                            value={this.state.model.progressMessageAfterAmount_ShippingBar}
                                            onChange={(newValue) => this.handleChange('progressMessageAfterAmount_ShippingBar', newValue)}
                                            label="Progress message after amount"
                                        />
                                    </FormLayout.Group>
                                    <TextField
                                        value={this.state.model.goalAchievedMessage_ShippingBar}
                                        onChange={(newValue) => this.handleChange('goalAchievedMessage_ShippingBar', newValue)}
                                        label="Goal achieved message"
                                        helpText={<div>You can use emojis as well, copy them from <a href="https://getemoji.com/" rel="nofollow noopener noreferrer" target="_blank">this page</a>.</div>}
                                    />
                                    <Checkbox
                                        label="Show customer's country"
                                        checked={this.state.model.showShopperCountry_ShippingBar}
                                        onChange={(newValue) => this.handleChange('showShopperCountry_ShippingBar', newValue)}
                                    />
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.messageBeforeCountry_ShippingBar}
                                            onChange={(newValue) => this.handleChange('messageBeforeCountry_ShippingBar', newValue)}
                                            label="Message before country"
                                        />
                                        <TextField
                                            value={this.state.model.messageAfterCountry_ShippingBar}
                                            onChange={(newValue) => this.handleChange('messageAfterCountry_ShippingBar', newValue)}
                                            label="Message after country"
                                        />
                                    </FormLayout.Group>
                                    <Checkbox
                                        label="Show Progress Bar"
                                        checked={this.state.model.showProgressBar_ShippingBar}
                                        onChange={(newValue) => this.handleChange('showProgressBar_ShippingBar', newValue)}
                                    />
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Progress Background Color"
                                            fieldName="progressBackgroundColor_ShippingBar"
                                            value={this.state.model.progressBackgroundColor_ShippingBar}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Progress Bar Color"
                                            fieldName="progressBarColor_ShippingBar"
                                            value={this.state.model.progressBarColor_ShippingBar}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <Checkbox
                                        label="Show Close Button"
                                        checked={this.state.model.showCloseButton_ShippingBar}
                                        onChange={(newValue) => this.handleChange('showCloseButton_ShippingBar', newValue)}
                                    />
                                    <Select
                                        label="Position"
                                        options={[{ label: 'Top', value: 'top' }, { label: 'Bottom', value: 'bottom' }]}
                                        onChange={(newValue) => this.handleChange('position_ShippingBar', newValue)}
                                        value={this.state.model.position_ShippingBar}
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection title="In Cart Upsell">
                            <Card>
                                <Card.Section>
                                    <FormLayout>
                                        <Checkbox
                                            label="Enabled"
                                            checked={this.state.model.enabled_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('enabled_InCartUpsell', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Price"
                                            checked={this.state.model.showPrice_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('showPrice_InCartUpsell', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Compare Price"
                                            checked={this.state.model.showComparePrice_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('showComparePrice_InCartUpsell', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Variants Dropdown"
                                            checked={this.state.model.showVariantsDropdown_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('showVariantsDropdown_InCartUpsell', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Quantity Box"
                                            checked={this.state.model.showQuantityBox_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('showQuantityBox_InCartUpsell', newValue)}
                                        />
                                        <Checkbox
                                            label="Enable Tracking"
                                            helpText="This attribute can be used for app auditing and will appear in your Shopify order details page. The attribute name is _Added from Quick Buy Button's Cart Slider. This is not visible to your customers."
                                            checked={this.state.model.trackingEnabled_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('trackingEnabled_InCartUpsell', newValue)}
                                        />
                                        <TextField
                                            value={this.state.model.overwriteProductName_Translation_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('overwriteProductName_Translation_InCartUpsell', newValue)}
                                            label="Overwrite Product Name"
                                        />
                                        <Select
                                            label="Position"
                                            options={[{ label: 'Top', value: 'top' }, { label: 'Bottom', value: 'bottom' }]}
                                            onChange={(newValue) => this.handleChange('position_InCartUpsell', newValue)}
                                            value={this.state.model.position_InCartUpsell}
                                        />
                                        <TextContainer>
                                            <Banner title="Product exclusion">
                                                <p>
                                                    Sometimes you don't want to show some particular product as an upsell.
                                                    Products can be excluded from the upsell by tagging them with the exact text <b>Hidden upsell recommendation</b>
                                                </p>
                                            </Banner>
                                        </TextContainer>
                                        <TextContainer>
                                            <Banner title="Disabling recommendations">
                                                <p>
                                                    If you want some product to not show any recommendations you can tag it with the exact text <b>Upsell recommendations disabled</b>
                                                </p>
                                            </Banner>
                                        </TextContainer>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Translations">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.addToCart_Translation_InCartUpsell}
                                                onChange={(newValue) => this.handleChange('addToCart_Translation_InCartUpsell', newValue)}
                                                label="Add to cart"
                                            />
                                            <TextField
                                                value={this.state.model.buyNow_Translation_InCartUpsell}
                                                onChange={(newValue) => this.handleChange('buyNow_Translation_InCartUpsell', newValue)}
                                                label="Buy now"
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.titleBeforeText_Translation_InCartUpsell}
                                                onChange={(newValue) => this.handleChange('titleBeforeText_Translation_InCartUpsell', newValue)}
                                                label="Text Before Product Name"
                                            />
                                            <TextField
                                                value={this.state.model.titleAfterText_Translation_InCartUpsell}
                                                onChange={(newValue) => this.handleChange('titleAfterText_Translation_InCartUpsell', newValue)}
                                                label="Text After Product Name"
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.soldOut_Translation_InCartUpsell}
                                                onChange={(newValue) => this.handleChange('soldOut_Translation_InCartUpsell', newValue)}
                                                label="Sold out"
                                            />
                                            <TextField
                                                value={this.state.model.addedToCartMessage_Translation_InCartUpsell}
                                                onChange={(newValue) => this.handleChange('addedToCartMessage_Translation_InCartUpsell', newValue)}
                                                label="Added to Cart Message"
                                            />
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Look and Feel">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Background Color"
                                                fieldName="backgroundColor_InCartUpsell"
                                                value={this.state.model.backgroundColor_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Title Color"
                                                fieldName="titleColor_InCartUpsell"
                                                value={this.state.model.titleColor_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Product Link Color"
                                                fieldName="productLinkColor_InCartUpsell"
                                                value={this.state.model.productLinkColor_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                            <div></div>
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Product Price Color"
                                                fieldName="productPriceColor_InCartUpsell"
                                                value={this.state.model.productPriceColor_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Product Compare Price Color"
                                                fieldName="productComparePriceColor_InCartUpsell"
                                                value={this.state.model.productComparePriceColor_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Button Background Color"
                                                fieldName="buttonBackgroundColor_InCartUpsell"
                                                value={this.state.model.buttonBackgroundColor_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Button Background Color on Hover"
                                                fieldName="buttonBackgroundColorOnHover_InCartUpsell"
                                                value={this.state.model.buttonBackgroundColorOnHover_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Button Text Color"
                                                fieldName="buttonTextColor_InCartUpsell"
                                                value={this.state.model.buttonTextColor_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Button Text Color on Hover"
                                                fieldName="buttonTextColorOnHover_InCartUpsell"
                                                value={this.state.model.buttonTextColorOnHover_InCartUpsell}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title={<h3 id="custom-bundles" aria-label="Custom Bundles" className="Polaris-Subheading">Custom Bundles</h3>} actions={[{ content: 'Add bundle', url: '/upsell-bundle' + this.state.common.shopQueryString + token}]}>
                                    <FormLayout>
                                        <ResourceList
                                            resourceName={{ singular: 'bundle', plural: 'bundles' }}
                                            items={this.state.model.bundles_InCartUpsell ? this.state.model.bundles_InCartUpsell : []}
                                            renderItem={(item) => {
                                                const { id, name } = item;

                                                return (
                                                    <ResourceItem
                                                        id={id}
                                                        url={'/upsell-bundle/' + id + this.state.common.shopQueryString}
                                                        accessibilityLabel={`View details for ${name}`}
                                                        name={name}
                                                    >
                                                        <h3>
                                                            <TextStyle variation="strong">{name}</TextStyle>
                                                        </h3>
                                                    </ResourceItem>
                                                );
                                            }}
                                            selectedItems={this.state.selectedBundles}
                                            onSelectionChange={(newValue) => this.selectBundles(newValue)}
                                            bulkActions={[{
                                                content: 'Delete bundles',
                                                onAction: () => { this.deleteBundles() },
                                            }]}
                                        />
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Global Bundles">
                                    <FormLayout>
                                        <Checkbox
                                            label="Global Bundles Enabled"
                                            checked={this.state.model.globalBundlesEnabled_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('globalBundlesEnabled_InCartUpsell', newValue)}
                                        />
                                        <OptionList
                                            title={<span>Recommendation Methods<span className="custom-help-text"> (Priority of recommendation methods is as follows)</span></span>}
                                            onChange={(newValue) => this.handleChange('recommendationMethods_InCartUpsell', newValue.sort())}
                                            options={[
                                                { value: '0', label: 'Custom Bundles', disabled: true },
                                                { value: '1', label: 'Smart recommendations' },
                                                { value: '2', label: 'Global recommendations' },
                                                { value: '3', label: 'Same collection' },
                                                { value: '4', label: 'Same product type' },
                                                { value: '5', label: 'Same vendor' },
                                                { value: '6', label: 'Random products' },
                                            ]}
                                            selected={this.state.model.recommendationMethods_InCartUpsell}
                                            allowMultiple
                                        />
                                        <TextContainer>
                                            <Banner>
                                                <p>
                                                    To add products as global recommendations tag them with the exact text  <b>Global upsell recommendations</b>
                                                </p>
                                            </Banner>
                                        </TextContainer>
                                        <Checkbox
                                            label="Random Order"
                                            checked={this.state.model.randomOrder_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('randomOrder_InCartUpsell', newValue)}
                                        />
                                        <TextField
                                            id="cartSlider_bundledProductsCount_InCartUpsell"
                                            label="Bundled Products Count"
                                            type="number"
                                            min="1"
                                            value={this.state.model.bundledProductsCount_InCartUpsell}
                                            onChange={(newValue) => this.handleChange('bundledProductsCount_InCartUpsell', newValue)}
                                            error={this.state.error.bundledProductsCount_InCartUpsell ? 'Value must be greater than or equal to 1' : ''}
                                            onBlur={() => this.handleBlur('bundledProductsCount_InCartUpsell', 1)}
                                        />
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Layout.AnnotatedSection>

                        <Layout.AnnotatedSection title="Coupon Form">
                            <Card>
                                <Card.Section>
                                    <FormLayout>
                                        <Checkbox
                                            label="Enabled"
                                            checked={this.state.model.showCouponForm}
                                            onChange={(newValue) => this.handleChange('showCouponForm', newValue)}
                                        />                                      
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Translations">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.textBoxPlaceholder_Translation}
                                                onChange={(newValue) => this.handleChange('textBoxPlaceholder_Translation', newValue)}
                                                label="Textbox placeholder"
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.applyButtonText_Translation}
                                                onChange={(newValue) => this.handleChange('applyButtonText_Translation', newValue)}
                                                label="Apply button text"
                                            />                                           
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.validCouponCodeNote_Translation}
                                                onChange={(newValue) => this.handleChange('validCouponCodeNote_Translation', newValue)}
                                                label="Valid coupon code note"
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                value={this.state.model.invalidCouponCodeNote_Translation}
                                                onChange={(newValue) => this.handleChange('invalidCouponCodeNote_Translation', newValue)}
                                                label="Invalid coupon code note"
                                            />
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Look and Feel">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Apply Button Background Color"
                                                fieldName="applyButtonBackgroundColor"
                                                value={this.state.model.applyButtonBackgroundColor}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Apply Button Color"
                                                fieldName="applyButtonColor"
                                                value={this.state.model.applyButtonColor}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Layout.AnnotatedSection>


                        <Layout.Section>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }} />
                        </Layout.Section>
                    </Layout>
                </Page >
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/cartSlider');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            if (!this.state.common.userName) {
                const commonResponse = await fetchApi('api/admin/common');
                const commonData = await commonResponse.json();

                if (commonData.redirectUrl) {
                    this.navigate(commonData.redirectUrl);
                }
                else {
                    this.props.setIsLoading(false);
                    this.setState({ initState: JSON.parse(JSON.stringify(data)), model: data, loading: false, visibleItem: data.demoProduct !== null, common: commonData });
                }
            }
            else {
                this.props.setIsLoading(false);
                this.setState({ initState: JSON.parse(JSON.stringify(data)), model: data, loading: false, visibleItem: data.demoProduct !== null });
            }

            var tgl = this.togglePreviewActive;
            window.$('.wizesaas-cs').unbind('click').bind('click', function (e) {
                if (e.target.className && e.target.className.split(' ').indexOf('wizesaas-cs') > -1) {
                    tgl();
                }
            });
        }

        if (typeof (this.state.model.selectedRemoveProductOption_CartReserved) == undefined ||
            (typeof (this.state.model.selectedRemoveProductOption_CartReserved) != undefined && !this.state.model.selectedRemoveProductOption_CartReserved)) {

            if (this.state.model.clearCartOnTimerEnd_CartReserved) {
                this.setState({ selectedRemoveProductOption_CartReserved: '2' });
            } else {
                this.setState({ selectedRemoveProductOption_CartReserved: '0' });
            }
        }

    }

    async saveChanges() {

        var hasError = this.handleBlur('closeAfterSeconds', 2);
        if (hasError) {
            $('#cartSlider_closeAfterSeconds').focus();
            return;
        }

        hasError = this.handleBlur('countdownMinutes_CartReserved', 1);
        if (hasError) {
            $('#cartSlider_countdownMinutes_CartReserved').focus();
            return;
        }

        hasError = this.handleBlur('notShowHours_CartReserved', 0);
        if (hasError) {
            $('#cartSlider_notShowHours_CartReserved').focus();
            return;
        }

        hasError = this.handleBlur('messageFontSize_CartReserved', 1);
        if (hasError) {
            $('#cartSlider_messageFontSize_CartReserved').focus();
            return;
        }

        hasError = this.handleBlur('minAmount_ShippingBar', 0);
        if (hasError) {
            $('#cartSlider_minAmount_ShippingBar').focus();
            return;
        }

        hasError = this.handleBlur('bundledProductsCount_InCartUpsell', 1);
        if (hasError) {
            $('#cartSlider_bundledProductsCount_InCartUpsell').focus();
            return;
        }

        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveCartSlider', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ initState: JSON.parse(JSON.stringify(this.state.model)) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }

}