import React, { Component } from 'react';
import { Page, PageActions, Layout, FormLayout, Card, Checkbox, TextField, Icon, Button, Modal, ResourceList, ResourceItem, TextStyle, Spinner, Thumbnail } from '@shopify/polaris';
import { SkeletonPage2Custom } from './SkeletonPage2Custom';
import { SearchMinor } from '@shopify/polaris-icons';
import { fetchApi } from './helpers/fetchApi';

export class UpsellBundle extends Component {

    constructor(props, ref) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleModalProductsActive = this.toggleModalProductsActive.bind(this);
        this.selectProductsDone = this.selectProductsDone.bind(this);

        var idVar = window.location.pathname.replace('/upsell-bundle', '').replace('/', '');
        var id = 0;
        if (idVar) {
            id = parseFloat(idVar);
        }

        this.state = {
            initState: {}, model: { id: id, products: [] }, loading: true, modalProductsActive: false,
            productSearchTerm: '', productLoading: false, products: [], selectedProducts: []
        };
    }

    componentDidMount() {
        this.populateData();
        this.props.setSaveChanges(this.saveChanges);
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });
    }

    toggleModalProductsActive() {
        this.setState({ modalProductsActive: !this.state.modalProductsActive });
    }

    browseProducts() {
        if (!this.state.modalProductsActive && !this.state.products.length && !this.state.productSearchTerm) {
            this.searchProducts('');
        }
        else {
            this.toggleModalProductsActive();
        }
    }

    selectProducts(newValue) {
        this.setState({ selectedProducts: newValue });
    }

    selectProductsDone() {
        var products = this.state.model.products;
        if (!products) {
            products = [];
        }

        var searchedProducts = this.state.products;
        var selectedProducts = this.state.selectedProducts;

        selectedProducts.forEach(function (id) {
            var items = products.filter((p) => p.id === id);
            if (!items.length) {
                var item = searchedProducts.filter((p) => p.id === id)[0];
                products.push({ id: item.id, title: item.title, thumbImageUrl: item.thumbImageUrl });
            }
        });

        products = products.filter((option) => selectedProducts.indexOf(option.id) !== -1);

        this.handleChange('products', products);
        this.toggleModalProductsActive();
    }

    removeProduct(id) {
        var products = this.state.model.products;
        var item = products.filter((p) => p.id === id)[0];
        products.splice(products.indexOf(item), 1);
        this.handleChange('products', products);
    }

    render() {
        let token = !window.navigate ? `token=${new URLSearchParams(window.location.search).get("token")}` : "";

        const modalProducts = (
            <Modal
                open={this.state.modalProductsActive}
                onClose={this.toggleModalProductsActive}
                title="Products"
                primaryAction={{
                    content: 'Done',
                    onAction: this.selectProductsDone
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: this.toggleModalProductsActive,
                    },
                ]}
            >
                <div className="product-modal-searchbox">
                    <TextField
                        id="modalSearch"
                        value={this.state.productSearchTerm}
                        placeholder="Search products"
                        prefix={<Icon source={SearchMinor} />}
                        onChange={(newValue) => { this.setState({ productSearchTerm: newValue }); this.searchProducts(newValue); }}
                    />
                </div>
                <div className="product-modal-list">
                    <div>
                        {
                            this.state.productLoading ?
                                <div className="product-modal-spinner">
                                    <Spinner accessibilityLabel="Spinner example" size="large" color="teal" />
                                </div>
                                :
                                <ResourceList
                                    items={this.state.products}
                                    renderItem={(item) => {
                                        const { id, title, thumbImageUrl } = item;
                                        return (
                                            <ResourceItem
                                                id={id}
                                                name={title}
                                                media={<Thumbnail source={thumbImageUrl} size="small" alt={title} />}
                                            >
                                                <h3><TextStyle>{title}</TextStyle></h3>
                                            </ResourceItem>
                                        );
                                    }}
                                    selectedItems={this.state.selectedProducts}
                                    onSelectionChange={(newValue) => this.selectProducts(newValue)}
                                    selectable={true}
                                    showHeader={false}
                                />
                        }
                    </div>
                </div>
            </Modal>
        );

        return (
            this.state.loading ?
                <SkeletonPage2Custom />
                :
                <Page title={this.state.model.id > 0 ? this.state.model.name : 'Add bundle'} breadcrumbs={[{ content: 'Cart Slider', url: '/cart-slider#custom-bundles?' + token }]}>
                    <Layout>
                        <Layout.Section>
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Enabled"
                                        checked={this.state.model.enabled}
                                        onChange={(newValue) => this.handleChange('enabled', newValue)}
                                    />
                                    <TextField
                                        value={this.state.model.name}
                                        onChange={(newValue) => this.handleChange('name', newValue)}
                                        label="Name"
                                        helpText="This is the private name of this Custom Recommendation, only you can see it."
                                    />
                                    <TextField
                                        value={this.state.productSearchTerm}
                                        label="Select products to include in this bundle (minimum 2 products)"
                                        placeholder="Search products"
                                        helpText="When any of the above selected products are in cart, other products from this Custom Recommendation will show in the In Cart Upsell widget as recommendations. For this Custom Recommendation to work add at least 2 products."
                                        prefix={<Icon source={SearchMinor} />}
                                        connectedRight={<Button onClick={(newValue) => this.browseProducts()}>Browse</Button>}
                                        onChange={(newValue) => { this.setState({ productSearchTerm: newValue }); this.searchProducts(newValue); }}
                                    />
                                    <ResourceList
                                        items={this.state.model.products ? this.state.model.products : []}
                                        renderItem={(item) => {
                                            const { id, title, thumbImageUrl } = item;
                                            return (
                                                <ResourceItem
                                                    id={id}
                                                    name={title}
                                                    media={<Thumbnail source={thumbImageUrl} size="small" alt={title} />}
                                                    shortcutActions={[
                                                        {
                                                            content: 'Remove',
                                                            accessibilityLabel: `Delete ${title}`,
                                                            onAction: () => { this.removeProduct(id) }
                                                        },
                                                    ]}
                                                    persistActions
                                                >
                                                    <h3><TextStyle>{title}</TextStyle></h3>
                                                </ResourceItem>
                                            );
                                        }}
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.Section>
                        <Layout.Section secondary>
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        value={this.state.model.buttonText}
                                        onChange={(newValue) => this.handleChange('buttonText', newValue)}
                                        label="Button Text"
                                        helpText="The text of the Add To Cart button."
                                    />
                                    <TextField
                                        value={this.state.model.titleBeforeText}
                                        onChange={(newValue) => this.handleChange('titleBeforeText', newValue)}
                                        label="Text Before Product Name"
                                    />
                                    <TextField
                                        value={this.state.model.titleAfterText}
                                        onChange={(newValue) => this.handleChange('titleAfterText', newValue)}
                                        label="Text After Product Name"
                                    />
                                    <TextField
                                        value={this.state.model.overwriteProductName}
                                        onChange={(newValue) => this.handleChange('overwriteProductName', newValue)}
                                        label="Overwrite Product Name"
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.Section>
                        <Layout.Section>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }} />
                        </Layout.Section>
                    </Layout>
                    {modalProducts}
                </Page >
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/upsellBundle?id=' + this.state.model.id);
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ initState: JSON.parse(JSON.stringify(data)), model: data, loading: false });
            if (data.products && data.products.length) {
                var ids = data.products.map(x => x.id);
                this.setState({ selectedProducts: ids });
            }
        }
    }

    async searchProducts(term) {
        if (!this.state.modalProductsActive) {
            this.toggleModalProductsActive();
            setTimeout(function () {
                window.$('#modalSearch').focus();
            }, 500);
        }

        this.setState({ productLoading: true });
        const response = await fetchApi('api/admin/searchProducts?term=' + term);
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.setState({ products: data, productLoading: false });
        }
    }

    async saveChanges() {
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveUpsellBundle', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {

                this.handleChange('id', data.id);
                this.setState({ initState: JSON.parse(JSON.stringify(this.state.model)) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }
}