import React, { Component } from 'react';
import { Select } from '@shopify/polaris';

export class EmojiPickerCustom extends Component {

    constructor(props, ref) {
        super(props);

        this.state = { value: this.props.value };
    }

    handleEmojiChange(newValue) {
        this.setState({ value: newValue });

        this.props.handleChange(this.props.fieldName, newValue);
    }

    render() {

        return (
            <Select
                label={this.props.label}
                options={[{ label: '', value: '' }, { label: '😁', value: '😁' }, { label: '🔥', value: '🔥' }, { label: '🤩', value: '🤩' }, { label: '😇', value: '😇' }, { label: '😍', value: '😍' },
                    { label: '😎', value: '😎' }, { label: '🤠', value: '🤠' }, { label: '😱', value: '😱' }, { label: '🙏', value: '🙏' }, { label: '👐', value: '👐' }, { label: '🙌', value: '🙌' },
                    { label: '✨', value: '✨' }, { label: '⚡️', value: '⚡️' }, { label: '🌞', value: '🌞' }, { label: '🚚', value: '🚚' }, { label: '🛫', value: '🛫' }, { label: '🎁', value: '🎁' }]}
                onChange={(newValue) => this.handleEmojiChange(newValue)}
                value={this.state.value}
            />
        );
    }

}