import React, { Component } from 'react';
import { Page, Layout, Card, FormLayout, TextField, Banner, Checkbox, ButtonGroup, Button, Stack, Select, PageActions, SettingToggle, TextStyle } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { ColorPickerCustom } from './ColorPickerCustom';
import { DesktopMajor, MobileMajor } from '@shopify/polaris-icons';
import { fetchApi } from './helpers/fetchApi';


var btnAnimation = "";

export class StickyAddToCartBar extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePreviewActive = this.togglePreviewActive.bind(this);

        this.state = { initState: {}, model: {}, loading: true, previewActive: true, previewDesktop: true };
    }

    componentDidMount() {
        this.populateData();
        this.props.setSaveChanges(this.saveChanges);
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });

        if (fieldName === 'buttonAnimation') {
            btnAnimation = newValue;
            window.$('.wizesaas_btn').wizesaas_animateCss(btnAnimation);
        }
    }

    handlePreview(previewDesktop) {
        this.setState({ previewDesktop: previewDesktop });
        setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
    }

    togglePreviewActive() {
        this.setState({ previewActive: !this.state.previewActive });
        setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
    }


    
    render() {

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Sticky Buy Button">
                    <Layout>
                        <Layout.Section>
                            <Banner
                                title="What is Sticky Buy Button?"
                                status="info"
                            >
                                <p>The Sticky Buy Button is a convenient feature that appears on product page when customer scrolls the page. This allows customers to swiftly add the product to cart without the need to scroll back up. Additionally, you can enable the "Direct Checkout" functionality to enable customers to be redirected straight to the checkout page..</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <div id="preview" className={(this.state.previewDesktop ? 'desktop' : 'mobile') + (this.state.previewActive ? '' : ' preview-hidden')}>
                                <Card title="Preview" sectioned actions={[{ content: this.state.previewActive ? 'Collapse ↑' : 'Expand ↓', onAction: this.togglePreviewActive }]}>
                                    <Card.Section>
                                        <div className="wizesaas_bar designNo0 designNo3 desktop_bar" style={{
                                            width: this.state.previewDesktop ? '100%' : '414px',
                                            backgroundColor: this.state.model.barBackgroundColor ? this.state.model.barBackgroundColor : '#fff',
                                            display: (!this.state.previewDesktop && this.state.model.useFullWidthButtonMobile) || !this.state.previewActive ? 'none' : 'block'
                                        }}>
                                            <div className="wizesaas_thumb-img">
                                                <img className="wizesaas_thumb" alt="Awesome Sneakers" src="https://cdn.shopify.com/s/files/1/1832/6893/products/ni313171_600_thum1_10_674_17041.jpg?v=1489009994"
                                                    style={{ display: (this.state.previewDesktop && this.state.model.showProductImageDesktop) || (!this.state.previewDesktop && this.state.model.showProductImageMobile) ? 'block' : 'none' }} />
                                            </div>
                                            <div className="wizesaas_title">
                                                <a className="wizesaas_title_a" href="/#" onClick={(e) => e.preventDefault()}
                                                    style={{
                                                        color: this.state.model.productTitleColor ? this.state.model.productTitleColor : '#3d4246',
                                                        display: (this.state.previewDesktop && this.state.model.showProductTitleDesktop) || (!this.state.previewDesktop && this.state.model.showProductTitleMobile) ? 'inline-block' : 'none'
                                                    }}>
                                                    Awesome Sneakers<span className="wizesaas_variant_title"> - Red / 11</span>
                                                </a>
                                                <div className="wizesaas_reviews_stars" style={{ display: (this.state.previewDesktop && this.state.model.showReviewStarsDesktop) || (!this.state.previewDesktop && this.state.model.showReviewStarsMobile) ? 'block' : 'none' }}>
                                                    <meta itemProp="bestRating" content="5" />
                                                    <meta itemProp="worstRating" content="1" />
                                                    <meta itemProp="reviewCount" content="1" />
                                                    <meta itemProp="ratingValue" content="5.0" />
                                                    <i className="spr-icon spr-icon-star"></i><i className="spr-icon spr-icon-star"></i><i className="spr-icon spr-icon-star"></i><i className="spr-icon spr-icon-star"></i><i className="spr-icon spr-icon-star"></i>
                                                    <a href="/#" onClick={(e) => e.preventDefault()} className="wizesaas_reviews_count">(1)</a>
                                                </div>
                                            </div>
                                            <div className="wizesaas_btn">
                                                <button style={{
                                                    color: this.state.model.buttonTextColor ? this.state.model.buttonTextColor : '#fff',
                                                    backgroundColor: this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8',
                                                    borderColor: this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8',
                                                }}
                                                    type="submit" name="add" className="primary product-form__cart-submit">{this.state.model.buttonText ? this.state.model.buttonText : 'ADD TO CART'}
                                                </button>
                                            </div>
                                            <div className="wizesaas_confirmation">
                                                Product Added!
                                            </div>
                                            <div className="wizesaas_price" style={{
                                                color: this.state.model.priceColor ? this.state.model.priceColor : '#212b36',
                                                display: (this.state.previewDesktop && this.state.model.showPriceDesktop) || (!this.state.previewDesktop && this.state.model.showPriceMobile) ? 'flex' : 'none'
                                            }}>
                                                $129.00
                                            </div>
                                            <div className="wizesaas_was_price" style={{
                                                color: this.state.model.comparePriceColor ? this.state.model.comparePriceColor : '#999',
                                                display: (this.state.previewDesktop && this.state.model.showComparePriceDesktop) || (!this.state.previewDesktop && this.state.model.showComparePriceMobile) ? 'flex' : 'none'
                                            }}>
                                                $159.00
                                            </div>
                                            <div className="wizesaas_variants">
                                                <select className="single-option-selector single-option-selector-product-template product-form__input" id="SingleOptionSelector-0" data-index="option1" defaultValue="Red"
                                                    style={{ display: (this.state.previewDesktop && this.state.model.variantsEnabledDesktop) || (!this.state.previewDesktop && this.state.model.variantsEnabledMobile) ? 'inline-block' : 'none' }}>
                                                    <option value="Red">Red</option>
                                                </select>
                                                <select className="single-option-selector single-option-selector-product-template product-form__input" id="SingleOptionSelector-1" data-index="option2" defaultValue="11"
                                                    style={{ display: (this.state.previewDesktop && this.state.model.variantsEnabledDesktop) || (!this.state.previewDesktop && this.state.model.variantsEnabledMobile) ? 'inline-block' : 'none' }}>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                </select>
                                                <div className="wizesaas_qty" style={{ display: (this.state.previewDesktop && this.state.model.qtyEnabledDesktop) || (!this.state.previewDesktop && this.state.model.qtyEnabledMobile) ? 'flex' : 'none' }}>
                                                    <input type="number" name="quantity" defaultValue="1" min="1" pattern="[0-9]*" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wizesaas_bar designNo2" style={{ display: !this.state.previewDesktop && this.state.model.useFullWidthButtonMobile && this.state.previewActive ? 'block' : 'none' }}>
                                            <div className="wizesaas_variants" style={{
                                                float: !this.state.model.variantsEnabledMobile && this.state.model.qtyEnabledMobile ? 'left' : 'none',
                                                width: !this.state.model.variantsEnabledMobile && this.state.model.qtyEnabledMobile ? '10%' : '100%',
                                            }}>
                                                <select className="single-option-selector single-option-selector-product-template product-form__input" id="SingleOptionSelector-0" data-index="option1" defaultValue="Red"
                                                    style={{
                                                        display: this.state.model.variantsEnabledMobile ? 'inline-block' : 'none',
                                                        width: this.state.model.qtyEnabledMobile ? '45%' : '50%'
                                                    }}>
                                                    <option value="Red">Red</option>
                                                </select>
                                                <select className="single-option-selector single-option-selector-product-template product-form__input" id="SingleOptionSelector-1" data-index="option2" defaultValue="11"
                                                    style={{
                                                        display: this.state.model.variantsEnabledMobile ? 'inline-block' : 'none',
                                                        width: this.state.model.qtyEnabledMobile ? '45%' : '50%'
                                                    }}>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                </select>
                                                <input type="number" name="quantity" defaultValue="1" min="1" pattern="[0-9]*" style={{
                                                    display: this.state.model.qtyEnabledMobile ? 'inline-block' : 'none',
                                                    width: !this.state.model.variantsEnabledMobile && this.state.model.qtyEnabledMobile ? '100%' : '10%',
                                                    height: !this.state.model.variantsEnabledMobile && this.state.model.qtyEnabledMobile ? '42px' : 'auto',
                                                }} />
                                            </div>
                                            <div className="wizesaas_btn" style={{
                                                float: !this.state.model.variantsEnabledMobile && this.state.model.qtyEnabledMobile ? 'right' : 'none',
                                                width: !this.state.model.variantsEnabledMobile && this.state.model.qtyEnabledMobile ? '90%' : 'auto',
                                            }}>
                                                <button style={{
                                                    color: this.state.model.buttonTextColor ? this.state.model.buttonTextColor : '#fff',
                                                    backgroundColor: this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8',
                                                    borderColor: this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8',
                                                }}
                                                    type="submit" name="add" className="primary product-form__cart-submit">{this.state.model.buttonText ? this.state.model.buttonText : 'ADD TO CART'}
                                                </button>
                                            </div>
                                        </div>
                                    </Card.Section>
                                    <Stack alignment="center" distribution="center">
                                        <div style={{ display: this.state.previewActive ? 'block' : 'none' }}>
                                            <ButtonGroup segmented con>
                                                <Button pressed={this.state.previewDesktop} onClick={(newValue) => this.handlePreview(true)} icon={DesktopMajor}>Desktop Preview</Button>
                                                <Button pressed={!this.state.previewDesktop} onClick={(newValue) => this.handlePreview(false)} icon={MobileMajor}>Mobile Preview</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Stack>
                                </Card>
                            </div>
                        </Layout.Section>
                        <Layout.AnnotatedSection
                            title=""
                        >
                            <SettingToggle action={{
                                content: this.state.model.enabled ? 'Disable' : 'Enable',
                                onAction: () => { this.handleChange('enabled', !this.state.model.enabled) }
                            }}
                                enabled={this.state.model.enabled}
                            >
                                Sticky Buy Button is <TextStyle variation="strong">{this.state.model.enabled ? 'enabled' : 'disabled'}</TextStyle>.
                            </SettingToggle>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="General Settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Skip to Checkout when clicked on Sticky Add to Cart Button"
                                        checked={this.state.model.skipCart}
                                        onChange={(newValue) => this.handleChange('skipCart', newValue)}
                                    />
                                    <Select
                                        label="Button Animation"
                                        helpText="Repeats every 5 seconds"
                                        options={[{ label: 'None', value: 'none' }, { label: 'Bounce', value: 'bounce' }, { label: 'Flash', value: 'flash' }, { label: 'Pulse', value: 'pulse' }, { label: 'Rubber band', value: 'rubberBand' }, { label: 'Shake', value: 'shake' }, { label: 'Swing', value: 'swing' }, { label: 'Tada', value: 'tada' }, { label: 'Wobble', value: 'wobble' }, { label: 'Jello', value: 'jello' }]}
                                        onChange={(newValue) => this.handleChange('buttonAnimation', newValue)}
                                        value={this.state.model.buttonAnimation}
                                    />
                                    <Checkbox
                                        label="Enable Tracking"
                                        helpText="This attribute can be used for app auditing and will appear in your Shopify order details page. The attribute name is _Added with Sticky Buy Button's Sticky Add To Cart Bar. This is not visible to your customers."
                                        checked={this.state.model.trackingEnabled}
                                        onChange={(newValue) => this.handleChange('trackingEnabled', newValue)}
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Look and Feel"
                            description="App will automatically match your site's branding but you can also adjust it's look and feel to your liking here."
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        value={this.state.model.buttonText}
                                        onChange={(newValue) => this.handleChange('buttonText', newValue)}
                                        label="Override Button Text"
                                    />
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Override Button Color"
                                            fieldName="buttonColor"
                                            value={this.state.model.buttonColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Override Button Text Color"
                                            fieldName="buttonTextColor"
                                            value={this.state.model.buttonTextColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Override Bar Background Color"
                                            fieldName="barBackgroundColor"
                                            value={this.state.model.barBackgroundColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Override Product Title Color"
                                            fieldName="productTitleColor"
                                            value={this.state.model.productTitleColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Override Price Color"
                                            fieldName="priceColor"
                                            value={this.state.model.priceColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Override Compare Price Color"
                                            fieldName="comparePriceColor"
                                            value={this.state.model.comparePriceColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Desktop Settings"
                            description=""
                        >
                            <Card sectioned>
                                <Card.Section>
                                    <FormLayout>
                                        <Checkbox
                                            label="Show on desktop computers"
                                            checked={this.state.model.showOnDesktop}
                                            onChange={(newValue) => this.handleChange('showOnDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Show when user starts scrolling instead of when user scrolls below main Add To Cart button"
                                            checked={this.state.model.showOnLoadDesktop}
                                            onChange={(newValue) => this.handleChange('showOnLoadDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Hide original Add To Cart button"
                                            checked={this.state.model.hideOriginalButtonDesktop}
                                            onChange={(newValue) => this.handleChange('hideOriginalButtonDesktop', newValue)}
                                        />
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section>
                                    <FormLayout>
                                        <Checkbox
                                            label="Show Product Image"
                                            checked={this.state.model.showProductImageDesktop}
                                            onChange={(newValue) => this.handleChange('showProductImageDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Product Title"
                                            checked={this.state.model.showProductTitleDesktop}
                                            onChange={(newValue) => this.handleChange('showProductTitleDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Review Stars"
                                            checked={this.state.model.showReviewStarsDesktop}
                                            onChange={(newValue) => this.handleChange('showReviewStarsDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Variant select"
                                            checked={this.state.model.variantsEnabledDesktop}
                                            onChange={(newValue) => this.handleChange('variantsEnabledDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Quantity select"
                                            checked={this.state.model.qtyEnabledDesktop}
                                            onChange={(newValue) => this.handleChange('qtyEnabledDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Quantity - and + buttons"
                                            checked={this.state.model.qtyAddRemoveEnabledDesktop}
                                            onChange={(newValue) => this.handleChange('qtyAddRemoveEnabledDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Price"
                                            checked={this.state.model.showPriceDesktop}
                                            onChange={(newValue) => this.handleChange('showPriceDesktop', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Compare Price"
                                            checked={this.state.model.showComparePriceDesktop}
                                            onChange={(newValue) => this.handleChange('showComparePriceDesktop', newValue)}
                                        />
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section>
                                    <FormLayout>
                                        <p>Bar position</p>
                                        <ButtonGroup segmented con>
                                            <Button pressed={this.state.model.barPositionDesktop === 'top'} onClick={(newValue) => this.handleChange('barPositionDesktop', 'top')}>
                                                Top
                                            </Button>
                                            <Button pressed={this.state.model.barPositionDesktop === 'bottom'} onClick={(newValue) => this.handleChange('barPositionDesktop', 'bottom')}>
                                                Bottom
                                            </Button>
                                        </ButtonGroup>
                                        <TextField
                                            label="Position Offset"
                                            suffix="px"
                                            type="number"
                                            value={this.state.model.customPositionDesktop}
                                            onChange={(newValue) => this.handleChange('customPositionDesktop', newValue)}
                                            helpText="Use this to move Sticky Add To Cart Bar up or down. Depending on Bar position (top or bottom) this will push it for selected amount of pixels in corresponding direction."
                                            clearButton
                                            onClearButtonClick={() => this.handleChange('customPositionDesktop', null)}
                                        />
                                        <TextField
                                            label="Stack Order"
                                            type="number"
                                            value={this.state.model.zIndexDesktop}
                                            onChange={(newValue) => this.handleChange('zIndexDesktop', newValue)}
                                            helpText="Use this to push Sticky Add To Cart Bar either in front or behind other elements. An element with greater stack order will be in front of an element with a lower stack order."
                                            clearButton
                                            onClearButtonClick={() => this.handleChange('zIndexDesktop', null)}
                                        />
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Mobile Settings"
                            description=""
                        >
                            <Card sectioned>
                                <Card.Section>
                                    <FormLayout>
                                        <Checkbox
                                            label="Show on tablets and mobile devices"
                                            checked={this.state.model.showOnMobile}
                                            onChange={(newValue) => this.handleChange('showOnMobile', newValue)}
                                        />
                                        <Checkbox
                                            label="Show when user starts scrolling instead of when user scrolls below main Add To Cart button"
                                            checked={this.state.model.showOnLoadMobile}
                                            onChange={(newValue) => this.handleChange('showOnLoadMobile', newValue)}
                                        />
                                        <Checkbox
                                            label="Hide original Add To Cart button"
                                            checked={this.state.model.hideOriginalButtonMobile}
                                            onChange={(newValue) => this.handleChange('hideOriginalButtonMobile', newValue)}
                                        />
                                        <Checkbox
                                            label="Show full width Add To Cart button instead of bar"
                                            checked={this.state.model.useFullWidthButtonMobile}
                                            onChange={(newValue) => this.handleChange('useFullWidthButtonMobile', newValue)}
                                        />
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section>
                                    <FormLayout>
                                        {
                                            this.state.model.useFullWidthButtonMobile ? '' :
                                                <Checkbox
                                                    label="Show Product Image"
                                                    checked={this.state.model.showProductImageMobile}
                                                    onChange={(newValue) => this.handleChange('showProductImageMobile', newValue)}
                                                />
                                        }
                                        {
                                            this.state.model.useFullWidthButtonMobile ? '' :
                                                <Checkbox
                                                    label="Show Product Title"
                                                    checked={this.state.model.showProductTitleMobile}
                                                    onChange={(newValue) => this.handleChange('showProductTitleMobile', newValue)}
                                                />
                                        }
                                        {
                                            this.state.model.useFullWidthButtonMobile ? '' :
                                                <Checkbox
                                                    label="Show Review Stars"
                                                    checked={this.state.model.showReviewStarsMobile}
                                                    onChange={(newValue) => this.handleChange('showReviewStarsMobile', newValue)}
                                                />
                                        }
                                        <Checkbox
                                            label="Show Variant select"
                                            checked={this.state.model.variantsEnabledMobile}
                                            onChange={(newValue) => this.handleChange('variantsEnabledMobile', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Quantity select"
                                            checked={this.state.model.qtyEnabledMobile}
                                            onChange={(newValue) => this.handleChange('qtyEnabledMobile', newValue)}
                                        />
                                        <Checkbox
                                            label="Show Quantity - and + buttons"
                                            checked={this.state.model.qtyAddRemoveEnabledMobile}
                                            onChange={(newValue) => this.handleChange('qtyAddRemoveEnabledMobile', newValue)}
                                        />
                                        {
                                            this.state.model.useFullWidthButtonMobile ? '' :
                                                <Checkbox
                                                    label="Show Price"
                                                    checked={this.state.model.showPriceMobile}
                                                    onChange={(newValue) => this.handleChange('showPriceMobile', newValue)}
                                                />
                                        }
                                        {
                                            this.state.model.useFullWidthButtonMobile ? '' :
                                                <Checkbox
                                                    label="Show Compare Price"
                                                    checked={this.state.model.showComparePriceMobile}
                                                    onChange={(newValue) => this.handleChange('showComparePriceMobile', newValue)}
                                                />
                                        }
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section>
                                    <FormLayout>
                                        <p>Bar position</p>
                                        <ButtonGroup segmented con>
                                            <Button pressed={this.state.model.barPositionMobile === 'top'} onClick={(newValue) => this.handleChange('barPositionMobile', 'top')}>
                                                Top
                                            </Button>
                                            <Button pressed={this.state.model.barPositionMobile === 'bottom'} onClick={(newValue) => this.handleChange('barPositionMobile', 'bottom')}>
                                                Bottom
                                            </Button>
                                        </ButtonGroup>
                                        <TextField
                                            label="Position Offset"
                                            suffix="px"
                                            type="number"
                                            value={this.state.model.customPositionMobile}
                                            onChange={(newValue) => this.handleChange('customPositionMobile', newValue)}
                                            helpText="Use this to move Sticky Add To Cart Bar up or down. Depending on Bar position (top or bottom) this will push it for selected amount of pixels in corresponding direction."
                                            clearButton
                                            onClearButtonClick={() => this.handleChange('customPositionMobile', null)}
                                        />
                                        <TextField
                                            label="Stack Order"
                                            type="number"
                                            value={this.state.model.zIndexMobile}
                                            onChange={(newValue) => this.handleChange('zIndexMobile', newValue)}
                                            helpText="Use this to push Sticky Add To Cart Bar either in front or behind other elements. An element with greater stack order will be in front of an element with a lower stack order."
                                            clearButton
                                            onClearButtonClick={() => this.handleChange('zIndexMobile', null)}
                                        />
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.Section>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }} />
                        </Layout.Section>
                    </Layout>
                </Page >
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/stickyAddToCartBar');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ initState: data, model: data, loading: false });

            window.$('#preview').sticky({ topSpacing: 56, zIndex: 100 });
            window.$('.wizesaas_title_a').click(function (e) {
                e.preventDefault();
            });
            window.$('.wizesaas_reviews_count').click(function (e) {
                e.preventDefault();
            });

            btnAnimation = data.buttonAnimation;
            setInterval(function () {
                if (window.$('.wizesaas_btn:hover').length === 0 && window.$('.wizesaas_btn:visible').length > 0 && btnAnimation !== 'None') {
                    window.$('.wizesaas_btn').wizesaas_animateCss(btnAnimation);
                }
            }, 5000);
        }
    }

    async saveChanges() {
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveStickyAddToCartBar', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ initState: Object.assign({}, this.state.model) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }
}