import React, { Component } from 'react';
import { Page, Layout, Banner } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';

export class UnpaidDashboard extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);
        this.state = { loading: true, errorMessage: '', shopName: '', code: '', shopNameError: '', url: '' };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        const errorMessage = this.state.errorMessage ? (
            <p>{this.state.errorMessage}</p>
        ) : null;
        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page>
                    <Layout>
                        <Layout.Section>
                            <Banner
                                title="REQUIRED: Approve Subscription"
                                status="warning"
                            >
                                <p>Please approve the subscription to use the app. <a target='_blank' href={this.state.url} rel="noreferrer"> Approve Subscription </a> </p>
                                {errorMessage}
                            </Banner>
                        </Layout.Section>
                    </Layout>
                </Page>
        );
    }

    async populateData() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if(typeof(params.redirect) !== undefined && params.redirect){
            this.setState({common: {username:''}});
            this.setState({url:params.redirect})
        }
        
        // const response = await fetchApi('account/GetRechargeSubscriptionLink');
        // const data = await response.json();
        // console.log('recharge link:',data);
        this.props.setIsLoading(false);
        this.setState({ loading: false });
    }

    
}