import React, { Component } from 'react';
import { Page, Layout, Card, FormLayout, TextField, Banner, SettingToggle,Button, TextStyle, Checkbox, Select, PageActions, TextContainer } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { ColorPickerCustom } from './ColorPickerCustom';
import { fetchApi } from './helpers/fetchApi';

export class QuickBuyButton extends Component {

    constructor(props, ref) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePreviewActive = this.togglePreviewActive.bind(this);

        this.state = { initState: {}, model: {}, loading: true, previewActive: true };
    }

    componentDidMount() {
        this.populateData();
        this.props.setSaveChanges(this.saveChanges);
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });
        if (fieldName === 'buttonStyle') {
            setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
        }
    }

    togglePreviewActive() {
        this.setState({ previewActive: !this.state.previewActive });
        setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
    }

    render() {

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Quick Buy Button">
                    <Layout>
                        <Layout.Section>
                            <Banner
                                title="What is Quick Buy Button?"
                                status="info"
                            >
                                <p>Quick Buy Button on product listings allows your customers to add products from any page. And with new Quantity selector they can add as much products as they like, right there from the collection page, homepage etc...</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <div id="preview" className={this.state.previewActive ? '' : 'preview-hidden'}>
                                <Card title="Preview" sectioned actions={[{ content: this.state.previewActive ? 'Collapse ↑' : 'Expand ↓', onAction: this.togglePreviewActive }]}>
                                    <div id="wizesaas_quick_buy_preview" style={{ display: this.state.previewActive ? 'block' : 'none' }}>
                                        <div className="wizesaas_quick_buy" style={{
                                            left: this.state.model.position === 'top_right' || this.state.model.position === 'bottom_right' ? 'auto' : (this.state.model.showQuantitySelector ? '54px' : '4px'),
                                            right: this.state.model.position === 'top_right' || this.state.model.position === 'bottom_right' ? '4px' : 'auto',
                                            top: this.state.model.position === 'top_right' || this.state.model.position === 'top_left' ? '4px' : 'auto',
                                            bottom: this.state.model.position === 'bottom_right' || this.state.model.position === 'bottom_left' ? '40px' : 'auto',
                                            backgroundColor: this.state.model.backgroundColor,
                                            display: this.state.model.buttonStyle === 'icon_button' || this.state.model.buttonStyle === 'plus_icon_button' ? 'block' : 'none'
                                        }}>
                                            <div>
                                                {this.state.model.cartIcon ?
                                                    <span className="wizesaas_glyphicon" style={{ color: this.state.model.iconColor }}><img id="wizesaas_quick_buy_cart_icon" src={this.state.model.cartIcon} width='20' height='20' alt='WIZESAAS quick buy button icon' /></span>
                                                    :
                                                    this.state.model.buttonStyle === 'icon_button' ?
                                                        <span className={"wizesaas_glyphicon wizesaas_glyphicon-shopping-cart"} style={{ color: this.state.model.iconColor }}></span>
                                                        :
                                                        <span className="wizesaas_glyphicon wizesaas_glyphicon-plus" style={{ color: this.state.model.iconColor }}><img class='plus_icon_button_quick_buy' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAB00lEQVRIiWL8//8/A90BAwMDAAAA//9iItNSAQYGhgsMDAwJZOlmYGAAAAAA//9iAPmYDLzhPwIYkKz//38GAAAAAP//osTH2NjEAQYGBgAAAAD//yLXYsoAAwMDAAAA//8aGIsZGBgAAAAA//8aGIsZGBgAAAAA//8aGIsZGBgAAAAA//8aGIsZGBgAAAAA//8aGIsZGBgAAAAA//8aGIsZGBgAAAAA//8aGIsZGBgAAAAA//8aGIsZGBgAAAAA//9igdKg0qeABH0KSGxQee1ApD5Q+b6BgYGBAQAAAP//ApWbAv///7+AVPbSGiT8//+fAQAAAP//GpigZmBgAAAAAP//gtXHpAY1KHjloeyFDAwMD4jUBwlqBgYGAAAAAP//IrdaPIAUHw4k6///nwEAAAD//xqYoGZgYAAAAAD//xoYixkYGAAAAAD//xoYixkYGAAAAAD//xoYixkYGAAAAAD//xoYixkYGAAAAAD//xoYixkYGAAAAAD//xoYixkYGAAAAAD//xoYixkYGAAAAAD//xoYixkYGAAAAAD//yLX4g842MQBBgYGAAAAAP//IrfIhNVo4JqGZPz/PwMAAAD//xqYThsDAwMAAAD//wMAWjOcFLlYIaIAAAAASUVORK5CYII=' width='20' height='20' alt='WIZESAAS quick buy button icon' /></span>

                                                    }
                                            </div>
                                        </div>
                                        <input type="number" aria-label="Quantity" className="wizesaas_qb_quantity" defaultValue="1" min="1" style={{
                                            left: this.state.model.position === 'top_right' || this.state.model.position === 'bottom_right' ? 'auto' : '4px',
                                            right: this.state.model.position === 'top_right' || this.state.model.position === 'bottom_right' ? '4px' : 'auto',
                                            top: this.state.model.position === 'top_right' || this.state.model.position === 'top_left' ? '4px' : 'auto',
                                            bottom: this.state.model.position === 'bottom_right' || this.state.model.position === 'bottom_left' ? '40px' : 'auto',
                                            borderColor: this.state.model.backgroundColor,
                                            display: this.state.model.showQuantitySelector && (this.state.model.buttonStyle === 'icon_button' || this.state.model.buttonStyle === 'plus_icon_button') ? 'block' : 'none'
                                        }} />
                                    </div>
                                    <div className="wizesaas_quick_buy_tb" style={{ textAlign: this.state.model.positionTB === 'auto' ? 'center' : this.state.model.positionTB, display: this.state.previewActive && (this.state.model.buttonStyle !== 'icon_button' && this.state.model.buttonStyle !== 'plus_icon_button') ? 'block' : 'none' }}>
                                        <span className="wizesaas_quick_buy-action" style={{ color: this.state.model.iconColor, backgroundColor: this.state.model.backgroundColor }}>{this.state.model.postAction !== 'checkout' ? this.state.model.addToCart_Translation : this.state.model.buyNow_Translation}</span>
                                    </div>
                                </Card>
                            </div>
                        </Layout.Section>
                        <Layout.AnnotatedSection
                            title="Quick Buy Button State"
                        >
                            <SettingToggle action={{
                                content: this.state.model.enabled ? 'Disable' : 'Enable',
                                onAction: () => { this.handleChange('enabled', !this.state.model.enabled) }
                            }}
                                enabled={this.state.model.enabled}
                            >
                                Quick Buy Button is currently <TextStyle variation="strong">{this.state.model.enabled ? 'enabled' : 'disabled'}</TextStyle>.
                            </SettingToggle>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Direct Checkout Settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Skip to Checkout when Add to Cart button is clicked"
                                        checked={this.state.model.skipCart}
                                        onChange={(newValue) => this.handleChange('skipCart', newValue)}
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection title="Settings">
                            <Card sectioned>
                                <FormLayout>
                                    <Select
                                        label="Button Style"
                                        options={
                                            [
                                                { label: 'Icon Button', value: 'icon_button' },
                                                { label: 'Plus Icon Button', value: 'plus_icon_button' },
                                                { label: 'Text Button', value: 'text_button' },
                                                { label: 'Text Button Expanded', value: 'textButtonExpanded' }
                                            ]}
                                        onChange={(newValue) => this.handleChange('buttonStyle', newValue)}
                                        value={this.state.model.buttonStyle}
                                    />
                                    <Checkbox
                                        label="Show on tablets and mobile devices"
                                        checked={this.state.model.showOnMobile}
                                        onChange={(newValue) => this.handleChange('showOnMobile', newValue)}
                                    />
                                    <Checkbox
                                        label="Show on desktop computers"
                                        checked={this.state.model.showOnDesktop}
                                        onChange={(newValue) => this.handleChange('showOnDesktop', newValue)}
                                    />
                                    {
                                        this.state.model.buttonStyle === 'icon_button' || this.state.model.buttonStyle === 'plus_icon_button' ?
                                            <Checkbox
                                                label="Show Quantity selector"
                                                checked={this.state.model.showQuantitySelector}
                                                onChange={(newValue) => this.handleChange('showQuantitySelector', newValue)}
                                            />
                                            : ''
                                    }
                                    <Checkbox
                                        label="Show price in variant selector"
                                        checked={this.state.model.showPrice}
                                        onChange={(newValue) => this.handleChange('showPrice', newValue)}
                                    />
                                    {
                                        this.state.model.buttonStyle === 'icon_button' || this.state.model.buttonStyle === 'plus_icon_button' ?
                                            <Select
                                                label="Position"
                                                options={[{ label: 'Top Right', value: 'top_right' }, { label: 'Bottom Right', value: 'bottom_right' }, { label: 'Top Left', value: 'top_left' }, { label: 'Bottom Left', value: 'bottom_left' }]}
                                                onChange={(newValue) => this.handleChange('position', newValue)}
                                                value={this.state.model.position}
                                            />
                                            :
                                            <Select
                                                label="Position"
                                                options={[{ label: 'Auto', value: 'auto' }, { label: 'Left', value: 'left' }, { label: 'Center', value: 'center' }, { label: 'Right', value: 'right' }]}
                                                onChange={(newValue) => this.handleChange('positionTB', newValue)}
                                                value={this.state.model.positionTB}
                                            />
                                    }
                                    <Select
                                        label="When Quick Buy Button is clicked, add product to cart and"
                                        options={[{ label: 'Stay on current page (Ajaxified)', value: 'no-redirect' }, { label: 'Go To Cart', value: 'cart' }, { label: 'Go To Product Page', value: 'product' }, { label: 'Go To Checkout', value: 'checkout' }]}
                                        onChange={(newValue) => this.handleChange('postAction', newValue)}
                                        value={this.state.model.postAction}
                                    />
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Background Color"
                                            fieldName="backgroundColor"
                                            value={this.state.model.backgroundColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label={this.state.model.buttonStyle === 'icon_button' || this.state.model.buttonStyle === 'plus_icon_button' ? 'Icon Color' : 'Text Color'}
                                            fieldName="iconColor"
                                            value={this.state.model.iconColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>

                                    {
                                        this.state.model.buttonStyle === 'textButtonExpanded' ?
                                            <FormLayout.Group>
   
                                            <ColorPickerCustom
                                                    label="Menu Background Color"
                                                    fieldName="menuBackgroundColor"
                                                    value={this.state.model.menuBackgroundColor}
                                                    handleChange={this.handleChange}
                                                />
                                            <ColorPickerCustom
                                                    label="Options Background Color"
                                                    fieldName="optionsBackgroundColor"
                                                    value={this.state.model.optionsBackgroundColor}
                                                    handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                    label="Options Text Color"
                                                    fieldName="optionsTextColor"
                                                    value={this.state.model.optionsTextColor}
                                                    handleChange={this.handleChange}
                                                />
                                                <ColorPickerCustom
                                                    label="Option Name Text Color"
                                                    fieldName="optionsNameTextColor"
                                                    value={this.state.model.optionsNameTextColor}
                                                    handleChange={this.handleChange}
                                                />
                                            <ColorPickerCustom
                                                    label="Place Order Button Background Color"
                                                    fieldName="placeOrderButtonBackgroundColor"
                                                    value={this.state.model.placeOrderButtonBackgroundColor}
                                                    handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                    label="Place Order Button Text Color"
                                                    fieldName="placeOrderButtonTextColor"
                                                    value={this.state.model.placeOrderButtonTextColor}
                                                    handleChange={this.handleChange}
                                            />
                                            <TextField
                                                        value={this.state.model.placeOrderButtonText}
                                                        onChange={(newValue) => this.handleChange('placeOrderButtonText', newValue)}
                                                        label="Place Order Button Text"
                                                />
                                            <p></p>
                                            <Button   onClick={() => {

                                                        this.setState(prevState => {
                                                            let model = Object.assign({}, prevState.model);
                                                            model.placeOrderButtonText = model.defaultPlaceOrderButtonText;
                                                            model.menuBackgroundColor = model.defaultMenuBackgroundColor;
                                                            model.optionsBackgroundColor = model.defaultOptionsBackgroundColor;
                                                            model.optionsTextColor = model.defaultOptionsTextColor;
                                                            model.optionsNameTextColor = model.defaultOptionsNameTextColor;
                                                            model.placeOrderButtonBackgroundColor = model.defaultPlaceOrderButtonBackgroundColor;
                                                            model.placeOrderButtonTextColor = model.defaultPlaceOrderButtonTextColor;
                                                            this.handleChange(model)
                                                            return { model };
                                                        })
                                                        
                                                    }}
                                                    >Reset Default
                                            </Button>

                                                
                                                
                                            </FormLayout.Group>
                                            : ''
                                        }

                                    {false && <FormLayout.Group>
                                        <label> Cart icon (20x20) <small><b>[NEW FEATURE]</b></small> <br />
                                            <input id="cartIcon" type="file" className="Polaris-Button" accept="image/*" onClick={() => document.querySelector('#cartIcon').value = ""} onChange={() => {
                                                setTimeout(async () => {

                                                    const toBase64 = file => new Promise((resolve, reject) => {
                                                        const reader = new FileReader();
                                                        reader.readAsDataURL(file);
                                                        reader.onload = () => {
                                                            if (file.size > 4096) {
                                                                alert('Cart icon file size should not be greater than 4kb');
                                                                resolve("");
                                                                return;
                                                            }
                                                            resolve(reader.result);
                                                        }
                                                        reader.onerror = error => reject(error);
                                                    });

                                                    if (document.querySelector('#cartIcon').files.length > 0) {
                                                        const file = document.querySelector('#cartIcon').files[0];
                                                        var cartIconBase64 = await toBase64(file);
                                                        this.setState(prevState => {
                                                            let model = Object.assign({}, prevState.model);
                                                            model.cartIcon = cartIconBase64;
                                                            return { model };
                                                        });
                                                    } else {
                                                        this.setState(prevState => {
                                                            let model = Object.assign({}, prevState.model);
                                                            model.cartIcon = "";
                                                            return { model };
                                                        });
                                                    }
                                                }, 500);
                                            }} />
                                        </label>
                                        <span>
                                            <br />
                                            <button className="Polaris-Button" onClick={() => {
                                                this.setState(prevState => {
                                                    let model = Object.assign({}, prevState.model);  // creating copy of state variable jasper
                                                    model.cartIcon = '';                     // update the name property, assign a new value
                                                    return { model };                                 // return new object jasper object
                                                })
                                            }} >Reset cart icon</button>
                                        </span>
                                    </FormLayout.Group>
                                    }
                                    { false &&
                                        <Checkbox
                                            label="Enable Tracking"
                                            helpText="This attribute can be used for app auditing and will appear in your Shopify order details page. The attribute name is _Added with Quick Buy Button's Quick Buy Button. This is not visible to your customers."
                                            checked={this.state.model.trackingEnabled}
                                            onChange={(newValue) => this.handleChange('trackingEnabled', newValue)}
                                        />

                                    }

                                    <TextField
                                        value={this.state.model.disableUrls}
                                        onChange={(newValue) => this.handleChange('disableUrls', newValue)}
                                        label="Disabling Quick Buy Button by Product URLs"
                                        helpText="To disable Quick Buy Button on a product, add a link to it, for example, https://yourstore.myshopify.com/products/awesome-product. Place each product URL in a new line."
                                        multiline
                                    />
                                    {
                                        this.state.model.buttonStyle === 'icon_button' || this.state.model.buttonStyle === 'plus_icon_button' ? '' :
                                            <TextContainer>
                                                <Banner title="Disabling Quick Buy Button by Tag">
                                                    <p>To disable Quick Buy Button on a product, add a tag to it with this exact text <b>Quick Buy Button disabled</b>.</p>
                                                </Banner>
                                            </TextContainer>
                                    }
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection title="Translations">
                            <Card sectioned>
                                <FormLayout>
                                    {
                                        this.state.model.buttonStyle === 'icon_button'? '' :
                                            <FormLayout.Group>
                                                {
                                                    this.state.model.postAction !== 'checkout' ?
                                                        <TextField
                                                            value={this.state.model.addToCart_Translation}
                                                            onChange={(newValue) => this.handleChange('addToCart_Translation', newValue)}
                                                            label="Add to cart"
                                                        />
                                                        :
                                                        <TextField
                                                            value={this.state.model.buyNow_Translation}
                                                            onChange={(newValue) => this.handleChange('buyNow_Translation', newValue)}
                                                            label="Buy now"
                                                        />
                                                }
                                                <TextField
                                                    value={this.state.model.soldOut_Translation}
                                                    onChange={(newValue) => this.handleChange('soldOut_Translation', newValue)}
                                                    label="Sold out"
                                                />
                                            </FormLayout.Group>
                                    }
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.addedToCartMessage_Translation}
                                            onChange={(newValue) => this.handleChange('addedToCartMessage_Translation', newValue)}
                                            label="Added to Cart Message"
                                        />
                                        <div></div>
                                    </FormLayout.Group>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.Section>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }} />
                        </Layout.Section>
                    </Layout>
                </Page >
        );
    }
  
    async populateData() {
        const response = await fetchApi('api/admin/quickBuyButton');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ initState: data, model: data, loading: false });

            window.$('#preview').sticky({ topSpacing: 56, zIndex: 100 });
        }
    }
    
    async saveChanges() {
      
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveQuickBuyButton', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ initState: Object.assign({}, this.state.model) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }
}