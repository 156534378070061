export const configs = {
    dashboardMenuEnabled: process.env.REACT_APP_DASHBOARD_MENU_ENABLED === "true",
    stickyBuyButtonMenuEnabled: process.env.REACT_APP_STICKY_BUY_BUTTON_MENU_ENABLED === "true",
    quickBuyButtonMenuEnabled: process.env.REACT_APP_QUICK_BUY_BUTTON_MENU_ENABLED === "true",
    quickViewMenuEnabled: process.env.REACT_APP_QUICK_VIEW_MENU_ENABLED === "true",
    stickyCheckoutBarMenuEnabled: process.env.REACT_APP_STICKY_CHECKOUT_BAR_MENU_ENABLED === "true",
    cartSliderMenuEnabled: process.env.REACT_APP_CART_SLIDER_MENU_ENABLED === "true",
    stickyCartMenuEnabled: process.env.REACT_APP_STICKY_CART_MENU_ENABLED === "true",
    customizeMenuEnabled: process.env.REACT_APP_CUSTOMIZE_MENU_ENABLED === "true",
    analyticsMenuEnabled: process.env.REACT_APP_ANALYTICS_MENU_ENABLED === "true",
    moreAppsMenuEnabled: process.env.REACT_APP_MORE_APPS_MENU_ENABLED === "true",
    openStorefrontMenuEnabled: process.env.REACT_APP_OPEN_STOREFRONT_MENU_ENABLED === "true",
    openShopifyAdminMenuEnabled: process.env.REACT_APP_OPEN_SHOPIFY_ADMIN_MENU_ENABLED === "true"
}