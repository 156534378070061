import React, { Component } from 'react';
import { Page, Layout, Card, FormLayout, TextField, Banner, SettingToggle, TextStyle, Checkbox, Button, Stack, ButtonGroup, PageActions, TextContainer, Select } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { ColorPickerCustom } from './ColorPickerCustom';
import { DesktopMajor, MobileMajor } from '@shopify/polaris-icons';
import { fetchApi } from './helpers/fetchApi';

export class QuickView extends Component {

    constructor(props, ref) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePreviewActive = this.togglePreviewActive.bind(this);
        this.openAppEmbedSettings = this.openAppEmbedSettings.bind(this);

        this.state = { initState: {}, model: {}, loading: true, previewActive: true, previewDesktop: true };
    }

    openAppEmbedSettings() {
        window.appBridgeRedirectExternal("https://admin.shopify.com/themes/current/editor?context=apps&appEmbed=0a35f0d3-bf5f-46dd-be9d-765a0f04d464");
    }

    componentDidMount() {
        this.populateData();
        this.props.setSaveChanges(this.saveChanges);
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });
    }

    handlePreview(previewDesktop) {
        this.setState({ previewDesktop: previewDesktop });
        setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
    }

    togglePreviewActive() {
        this.setState({ previewActive: !this.state.previewActive });
        setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
    }

    render() {

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Quick View">
                    <Layout>
                        <Layout.Section>
                            <Banner
                                title="What is Quick View?"
                                status="info"
                            >
                                <p>Quick View will allow your customers to view details and Add-To-Cart form of any product on any page.</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <div id="preview" className={(this.state.previewDesktop ? 'desktop' : 'mobile') + (this.state.previewActive ? '' : ' preview-hidden')}>
                                <Card title="Preview" sectioned actions={[{ content: this.state.previewActive ? 'Collapse ↑' : 'Expand ↓', onAction: this.togglePreviewActive }]}>
                                    <Card.Section>
                                        <div id="wizesaas_quick_view_preview" style={{ display: this.state.previewActive ? 'block' : 'none' }}>
                                            <div className={'wizesaas_quick_view' + (!this.state.previewDesktop && this.state.model.mobileButtonType === 'round' ? ' wizesaas-mobile-bt-round' : '') + (' wizesaas_qv_mobile_' + this.state.model.positionMobile)} style={{ backgroundColor: this.state.model.buttonBackgroundColor, color: this.state.model.buttonColor, borderColor: this.state.model.buttonBorderColor }}>
                                                <span>{this.state.model.quickView_Translation}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="-139 144 20 13"><path d="M-119.3 150.2c-.2-.2-4.4-6-9.8-6s-9.6 5.8-9.8 6c-.2.2-.2.5 0 .8.2.2 4.4 6 9.8 6s9.6-5.8 9.8-6c.1-.3.1-.6 0-.8zm-9.8 5.5c-4 0-7.4-3.8-8.4-5.1 1-1.3 4.4-5.1 8.4-5.1s7.4 3.8 8.4 5.1c-1 1.2-4.4 5.1-8.4 5.1z"></path><path d="M-129.1 146.6c-2.2 0-3.9 1.8-3.9 4s1.8 4 3.9 4c2.2 0 3.9-1.8 3.9-4s-1.7-4-3.9-4zm0 6.6c-1.4 0-2.6-1.2-2.6-2.6 0-1.5 1.2-2.6 2.6-2.6s2.6 1.2 2.6 2.6c0 1.4-1.2 2.6-2.6 2.6z"></path></svg>
                                            </div>
                                        </div>
                                    </Card.Section>
                                    <Stack alignment="center" distribution="center">
                                        <div style={{ display: this.state.previewActive ? 'block' : 'none' }}>
                                            <ButtonGroup segmented con>
                                                <Button pressed={this.state.previewDesktop} onClick={(newValue) => this.handlePreview(true)} icon={DesktopMajor}>Desktop Preview</Button>
                                                <Button pressed={!this.state.previewDesktop} onClick={(newValue) => this.handlePreview(false)} icon={MobileMajor}>Mobile Preview</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Stack>
                                </Card>
                            </div>
                        </Layout.Section>
                        <Layout.AnnotatedSection
                            title="Sticky Cart State"
                        >
                            <SettingToggle action={{
                                content: this.props.common.isAppEmbedEnabled ? 'Disable' : 'Enable',
                                onAction: this.openAppEmbedSettings
                            }}
                                enabled={this.props.common.isAppEmbedEnabled}
                                external={true}
                            >
                                Sticky Cart is currently <TextStyle variation="strong">{this.props.common.isAppEmbedEnabled ? 'enabled' : 'disabled'}</TextStyle>.
                            </SettingToggle>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection title="Settings">
                            <Card sectioned>
                                <FormLayout>
                                    <Select
                                        label="Button Layout on Mobile"
                                        options={[{ label: 'Round', value: 'round' }, { label: 'Square', value: 'square' }]}
                                        onChange={(newValue) => this.handleChange('mobileButtonType', newValue)}
                                        value={this.state.model.mobileButtonType}
                                    />
                                    <Select
                                        label="Position on Mobile"
                                        options={[{ label: 'Top Right', value: 'top_right' }, { label: 'Bottom Right', value: 'bottom_right' }, { label: 'Top Left', value: 'top_left' }, { label: 'Bottom Left', value: 'bottom_left' }]}
                                        onChange={(newValue) => this.handleChange('positionMobile', newValue)}
                                        value={this.state.model.positionMobile}
                                    />
                                    <Checkbox
                                        label="Enable Tracking"
                                        helpText="This attribute can be used for app auditing and will appear in your Shopify order details page. The attribute name is _Added via Quick View of Sticky Buy Button. This is not visible to your customers."
                                        checked={this.state.model.trackingEnabled}
                                        onChange={(newValue) => this.handleChange('trackingEnabled', newValue)}
                                    />
                                    <TextContainer>
                                        <Banner title="Disabling Quick View">
                                            <p>
                                                To disable Quick View button on a product, add a tag to it with this exact text <b>Quick View disabled</b>.
                                            </p>
                                        </Banner>
                                    </TextContainer>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Translations"
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.quickView_Translation}
                                            onChange={(newValue) => this.handleChange('quickView_Translation', newValue)}
                                            label="Quick View"
                                        />
                                        <TextField
                                            value={this.state.model.quantity_Translation}
                                            onChange={(newValue) => this.handleChange('quantity_Translation', newValue)}
                                            label="Quantity"
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.addToCart_Translation}
                                            onChange={(newValue) => this.handleChange('addToCart_Translation', newValue)}
                                            label="Add to cart"
                                        />
                                        <TextField
                                            value={this.state.model.buyNow_Translation}
                                            onChange={(newValue) => this.handleChange('buyNow_Translation', newValue)}
                                            label="Buy now"
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.soldOut_Translation}
                                            onChange={(newValue) => this.handleChange('soldOut_Translation', newValue)}
                                            label="Sold out"
                                        />
                                        <TextField
                                            value={this.state.model.viewDetails_Translation}
                                            onChange={(newValue) => this.handleChange('viewDetails_Translation', newValue)}
                                            label="View Details"
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.addedToCartMessage_Translation}
                                            onChange={(newValue) => this.handleChange('addedToCartMessage_Translation', newValue)}
                                            label="Added to Cart Message"
                                        />
                                        <TextField
                                            value={this.state.model.viewCart_Translation}
                                            onChange={(newValue) => this.handleChange('viewCart_Translation', newValue)}
                                            label="View cart"
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.or_Translation}
                                            onChange={(newValue) => this.handleChange('or_Translation', newValue)}
                                            label="or"
                                        />
                                        <TextField
                                            value={this.state.model.continueShopping_Translation}
                                            onChange={(newValue) => this.handleChange('continueShopping_Translation', newValue)}
                                            label="Continue shopping"
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Look and Feel"
                        >
                            <Card>
                                <Card.Section title="Quick View Button">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Text Color"
                                                fieldName="buttonColor"
                                                value={this.state.model.buttonColor}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Background Color"
                                                fieldName="buttonBackgroundColor"
                                                value={this.state.model.buttonBackgroundColor}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Border Color"
                                                fieldName="buttonBorderColor"
                                                value={this.state.model.buttonBorderColor}
                                                handleChange={this.handleChange}
                                            />
                                            <div></div>
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Add to Cart Button">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Text Color"
                                                fieldName="atcColor"
                                                value={this.state.model.atcColor}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Background Color"
                                                fieldName="atcBackgroundColor"
                                                value={this.state.model.atcBackgroundColor}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Border Color"
                                                fieldName="atcBorderColor"
                                                value={this.state.model.atcBorderColor}
                                                handleChange={this.handleChange}
                                            />
                                            <div></div>
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Buy Now Button">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Text Color"
                                                fieldName="buyNowColor"
                                                value={this.state.model.buyNowColor}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Background Color"
                                                fieldName="buyNowBackgroundColor"
                                                value={this.state.model.buyNowBackgroundColor}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Border Color"
                                                fieldName="buyNowBorderColor"
                                                value={this.state.model.buyNowBorderColor}
                                                handleChange={this.handleChange}
                                            />
                                            <div></div>
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="View Details Button">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Text Color"
                                                fieldName="viewDetailsColor"
                                                value={this.state.model.viewDetailsColor}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Background Color"
                                                fieldName="viewDetailsBackgroundColor"
                                                value={this.state.model.viewDetailsBackgroundColor}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Border Color"
                                                fieldName="viewDetailsBorderColor"
                                                value={this.state.model.viewDetailsBorderColor}
                                                handleChange={this.handleChange}
                                            />
                                            <div></div>
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                                <Card.Section title="Product Prices">
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <ColorPickerCustom
                                                label="Price Color"
                                                fieldName="productPriceColor"
                                                value={this.state.model.productPriceColor}
                                                handleChange={this.handleChange}
                                            />
                                            <ColorPickerCustom
                                                label="Compare Price Color"
                                                fieldName="productComparePriceColor"
                                                value={this.state.model.productComparePriceColor}
                                                handleChange={this.handleChange}
                                            />
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.Section>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }} />
                        </Layout.Section>
                    </Layout>
                </Page >
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/quickView');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ initState: data, model: data, loading: false });

            window.$('#preview').sticky({ topSpacing: 56, zIndex: 100 });
        }
    }

    async saveChanges() {
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveQuickView', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ initState: Object.assign({}, this.state.model) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }
}