import React, { Component } from 'react';
import Slider from "react-slick";

export class CartSliderInCartUpsell extends Component {

    constructor(props, ref) {
        super(props);

        this.handleChangeDemoQty = this.handleChangeDemoQty.bind(this);
        this.handleDemoQtyUpDown = this.handleDemoQtyUpDown.bind(this);

        this.state = { demoQty: 1 };
    }

    handleChangeDemoQty(event) {
        this.setState({ demoQty: event.target.value });
    }

    handleDemoQtyUpDown(value, e) {
        e.preventDefault();
        var val = this.state.demoQty + value;
        if (val > 0) {
            this.setState({ demoQty: val });
        }
    }

    render() {

        var sliderSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <div className="wizesaas-cs-upsell">
                <Slider {...sliderSettings}>
                    <div className="wizesaas-cs-upsell-item-container">
                        <div className="wizesaas-cs-upsell-item">
                            <div className="wizesaas-cs-upsell-item-header" style={{ color: this.props.model.titleColor_InCartUpsell }}>
                                <span>{this.props.model.titleBeforeText_Translation_InCartUpsell}</span>
                                <span>{this.props.model.overwriteProductName_Translation_InCartUpsell ? this.props.model.overwriteProductName_Translation_InCartUpsell : this.props.product.title}</span>
                                <span>{this.props.model.titleAfterText_Translation_InCartUpsell}</span>
                            </div>
                            <div className="wizesaas-cs-upsell-item-inner">
                                <div className="wizesaas-cs-upsell-item-image">
                                    <a href={this.props.product.url} target="_blank" rel="noreferrer"><img src={this.props.product.imageUrl} alt={this.props.product.title} /></a>
                                </div>
                                <div className="wizesaas-cs-upsell-item-content">
                                    <div className="wizesaas-cs-upsell-item-title" style={{ color: this.props.model.productLinkColor_InCartUpsell }}>{this.props.product.title}</div>
                                    {
                                        this.props.model.showVariantsDropdown_InCartUpsell ?
                                            <div className="wizesaas-cs-upsell-item-variantddl">
                                                <select>
                                                    <option>Variant 1</option>
                                                    <option>Variant 2</option>
                                                </select>
                                            </div> : ''
                                    }
                                    {
                                        this.props.model.showQuantityBox_InCartUpsell ?
                                            <div className="wizesaas-cs-item-qty">
                                                <a href="/#" onClick={(e) => this.handleDemoQtyUpDown(-1, e)}><svg width="10" viewBox="0 0 12 2" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 0H.75C.3 0 0 .4 0 1s.3 1 .75 1h10.5c.45 0 .75-.4.75-1s-.3-1-.75-1z"></path></svg></a>
                                                <input type="text" value={this.state.demoQty} onChange={this.handleChangeDemoQty} />
                                                <a href="/#" onClick={(e) => this.handleDemoQtyUpDown(1, e)}><svg width="10" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 002 0V7h4a1 1 0 000-2z"></path></svg></a>
                                            </div> : ''
                                    }
                                </div>
                                <div className="wizesaas-cs-upsell-item-btn">
                                    {
                                        this.props.model.showPrice_InCartUpsell && this.props.model.showComparePrice_InCartUpsell ?
                                            <div className="wizesaas-cs-upsell-item-compare-price" style={{ color: this.props.model.productComparePriceColor_InCartUpsell }}>
                                                {this.props.currency + (this.props.product.defaultVariant.price + 25)}
                                            </div> : ''
                                    }
                                    {
                                        this.props.model.showPrice_InCartUpsell ?
                                            <div className="wizesaas-cs-upsell-item-price" style={{ color: this.props.model.productPriceColor_InCartUpsell }}>
                                                {this.props.currency + this.props.product.defaultVariant.price}
                                            </div> : ''
                                    }
                                    <a href="/#" onClick={(e) => e.preventDefault()} className="wizesaas-cs-upsell-item-atc" style={{ backgroundColor: this.props.model.buttonBackgroundColor_InCartUpsell, color: this.props.model.buttonTextColor_InCartUpsell }}>{this.props.model.addToCart_Translation_InCartUpsell}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wizesaas-cs-upsell-item-container">
                        <div className="wizesaas-cs-upsell-item">
                            <div className="wizesaas-cs-upsell-item-header" style={{ color: this.props.model.titleColor_InCartUpsell }}>
                                <span>{this.props.model.titleBeforeText_Translation_InCartUpsell}</span>
                                <span>{this.props.model.overwriteProductName_Translation_InCartUpsell ? this.props.model.overwriteProductName_Translation_InCartUpsell : this.props.product.title}</span>
                                <span>{this.props.model.titleAfterText_Translation_InCartUpsell}</span>
                            </div>
                            <div className="wizesaas-cs-upsell-item-inner">
                                <div className="wizesaas-cs-upsell-item-image">
                                    <a href={this.props.product.url} target="_blank" rel="noreferrer"><img src={this.props.product.imageUrl} alt={this.props.product.title} /></a>
                                </div>
                                <div className="wizesaas-cs-upsell-item-content">
                                    <div className="wizesaas-cs-upsell-item-title" style={{ color: this.props.model.productLinkColor_InCartUpsell }}>{this.props.product.title}</div>
                                    {
                                        this.props.model.showVariantsDropdown_InCartUpsell ?
                                            <div className="wizesaas-cs-upsell-item-variantddl">
                                                <select>
                                                    <option>Variant 1</option>
                                                    <option>Variant 2</option>
                                                </select>
                                            </div> : ''
                                    }
                                    {
                                        this.props.model.showQuantityBox_InCartUpsell ?
                                            <div className="wizesaas-cs-item-qty">
                                                <a href="/#" onClick={(e) => this.handleDemoQtyUpDown(-1, e)}><svg width="10" viewBox="0 0 12 2" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 0H.75C.3 0 0 .4 0 1s.3 1 .75 1h10.5c.45 0 .75-.4.75-1s-.3-1-.75-1z"></path></svg></a>
                                                <input type="text" value={this.state.demoQty} onChange={this.handleChangeDemoQty} />
                                                <a href="/#" onClick={(e) => this.handleDemoQtyUpDown(1, e)}><svg width="10" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 002 0V7h4a1 1 0 000-2z"></path></svg></a>
                                            </div> : ''
                                    }
                                </div>
                                <div className="wizesaas-cs-upsell-item-btn">
                                    {
                                        this.props.model.showPrice_InCartUpsell && this.props.model.showComparePrice_InCartUpsell ?
                                            <div className="wizesaas-cs-upsell-item-compare-price" style={{ color: this.props.model.productComparePriceColor_InCartUpsell }}>
                                                {this.props.currency + (this.props.product.defaultVariant.price + 25)}
                                            </div> : ''
                                    }
                                    {
                                        this.props.model.showPrice_InCartUpsell ?
                                            <div className="wizesaas-cs-upsell-item-price" style={{ color: this.props.model.productPriceColor_InCartUpsell }}>
                                                {this.props.currency + this.props.product.defaultVariant.price}
                                            </div> : ''
                                    }
                                    <a href="/#" onClick={(e) => e.preventDefault()} className="wizesaas-cs-upsell-item-atc" style={{ backgroundColor: this.props.model.buttonBackgroundColor_InCartUpsell, color: this.props.model.buttonTextColor_InCartUpsell }}>{this.props.model.addToCart_Translation_InCartUpsell}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }

}