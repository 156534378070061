import React, { Component } from 'react';

export class CartSliderShippingBar extends Component {

    constructor(props, ref) {
        super(props);
    }

    getBeforeAmountMessage_ShippingBar() {

        var totalPrice = this.props.totalPrice ? parseFloat(this.props.totalPrice) : 0;
        var minAmount = this.props.model.minAmount_ShippingBar ? parseFloat(this.props.model.minAmount_ShippingBar) : 0;

        var message = '';

        if (totalPrice === 0 && minAmount > 0) {
            message = this.props.model.initialMessageBeforeAmount_ShippingBar;
        }
        else if (totalPrice < minAmount) {
            message = this.props.model.progressMessageBeforeAmount_ShippingBar;
        }

        return message;
    }

    getAmmount_ShippingBar() {

        var totalPrice = this.props.totalPrice ? parseFloat(this.props.totalPrice) : 0;
        var minAmount = this.props.model.minAmount_ShippingBar ? parseFloat(this.props.model.minAmount_ShippingBar) : 0;

        var message = '';

        if (totalPrice === 0 && minAmount > 0) {
            message = this.props.currency + parseFloat(parseFloat(minAmount).toFixed(2)).toString();
        }
        else if (totalPrice < minAmount) {
            message = this.props.currency + parseFloat(parseFloat(minAmount - totalPrice).toFixed(2)).toString();
        }

        return message;
    }

    getAfterAmountMessage_ShippingBar() {

        var totalPrice = this.props.totalPrice ? parseFloat(this.props.totalPrice) : 0;
        var minAmount = this.props.model.minAmount_ShippingBar ? parseFloat(this.props.model.minAmount_ShippingBar) : 0;

        var message = '';

        if (totalPrice === 0 && minAmount > 0) {
            message = this.props.model.initialMessageAfterAmount_ShippingBar;
        }
        else if (totalPrice < minAmount) {
            message = this.props.model.progressMessageAfterAmount_ShippingBar;
        }

        return message;
    }

    getGoalAchievedMessage_ShippingBar() {

        var totalPrice = this.props.totalPrice ? parseFloat(this.props.totalPrice) : 0;
        var minAmount = this.props.model.minAmount_ShippingBar ? parseFloat(this.props.model.minAmount_ShippingBar) : 0;

        var message = '';

        if (totalPrice >= minAmount) {
            message = this.props.model.showShopperCountry_ShippingBar && (this.props.model.goalAchievedMessage_ShippingBar.endsWith('.') || this.props.model.goalAchievedMessage_ShippingBar.endsWith('!')) ? this.props.model.goalAchievedMessage_ShippingBar.slice(0, -1) : this.props.model.goalAchievedMessage_ShippingBar;
        }

        return message;
    }

    getCountryMessage_ShippingBar() {

        var message = this.props.model.showShopperCountry_ShippingBar ? this.props.model.messageBeforeCountry_ShippingBar + ' [COUNTRY]' + this.props.model.messageAfterCountry_ShippingBar : '';
        return message;
    }

    getProgress_ShippingBar() {

        var totalPrice = this.props.totalPrice ? parseFloat(this.props.totalPrice) : 0;
        var minAmount = this.props.model.minAmount_ShippingBar ? parseFloat(this.props.model.minAmount_ShippingBar) : 0;

        var progress = '0';

        if (totalPrice > minAmount) {
            progress = '100%';
        }
        else if (totalPrice < minAmount) {
            progress = (totalPrice * 100 / minAmount) + '%';
        }

        return progress;
    }

    render() {

        return (
            <div className={'wizesaas-cs-shippingbar' + (this.props.model.showCloseButton_ShippingBar ? ' with-close' : '')} style={{ backgroundColor: this.props.model.backgroundColor_ShippingBar, color: this.props.model.textColor_ShippingBar }}>
                <span style={{ display: this.getBeforeAmountMessage_ShippingBar() ? 'inline-block' : 'none' }}>{this.getBeforeAmountMessage_ShippingBar()}</span>
                <span style={{ display: this.getAmmount_ShippingBar() ? 'inline-block' : 'none', color: this.props.model.amountColor_ShippingBar, fontWeight: 'bold' }}>{this.getAmmount_ShippingBar()}</span>
                <span style={{ display: this.getAfterAmountMessage_ShippingBar() ? 'inline-block' : 'none' }}>{this.getAfterAmountMessage_ShippingBar()}</span>
                <span style={{ display: this.getGoalAchievedMessage_ShippingBar() ? 'inline-block' : 'none' }}>{this.getGoalAchievedMessage_ShippingBar()}</span>
                <span style={{ display: this.getCountryMessage_ShippingBar() ? 'inline-block' : 'none' }}>{this.getCountryMessage_ShippingBar()}</span>
                {
                    this.props.model.showCloseButton_ShippingBar ?
                        <span className="wizesaas-cs-shippingbar-close" style={{ '--close-color': this.props.model.textColor_ShippingBar }}></span> : ''
                }
                {
                    this.props.model.showProgressBar_ShippingBar ?
                        <div className="wizesaas-cs-shippingbar-progress" style={{ backgroundColor: this.props.model.progressBackgroundColor_ShippingBar }}>
                            <div className="wizesaas-cs-shippingbar-progress-inner" style={{ width: this.getProgress_ShippingBar(), backgroundColor: this.props.model.progressBarColor_ShippingBar }}></div>
                        </div> : ''
                }
            </div>
        );
    }
}