import React, { Component } from 'react';
import { Page, Layout, Card, FormLayout, TextField, Banner, SettingToggle, TextStyle, Checkbox, ButtonGroup, Button, Stack, Select, PageActions } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { ColorPickerCustom } from './ColorPickerCustom';
import { DesktopMajor, MobileMajor } from '@shopify/polaris-icons';
import { fetchApi } from './helpers/fetchApi';

var btnAnimation = "";

export class StickyCheckoutBar extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);

        this.saveChanges = this.saveChanges.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePreviewActive = this.togglePreviewActive.bind(this);

        this.state = { initState: {}, model: {}, loading: true, previewActive: true, previewDesktop: true };
    }

    componentDidMount() {
        this.populateData();
        this.props.setSaveChanges(this.saveChanges);
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });

        if (fieldName === 'buttonAnimation') {
            btnAnimation = newValue;
            window.$('.wizesaas_checkout_btn:visible').wizesaas_animateCss(btnAnimation);
        }
    }

    handlePreview(previewDesktop) {
        this.setState({ previewDesktop: previewDesktop });
        setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
    }

    togglePreviewActive() {
        this.setState({ previewActive: !this.state.previewActive });
        setTimeout(function () { window.$('#preview').sticky('update'); }, 0);
    }

    render() {

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Sticky Checkout Bar">
                    <Layout>
                        <Layout.Section>
                            <Banner
                                title="What is Sticky Checkout Bar/Button?"
                                status="info"
                            >
                                <p>Sticky Checkout Bar/Button will show on Cart page when user starts scrolling down. It will show number of items in the cart, total price and Checkout button. Its main goal is to improve user's experience and make checkout easier. On desktop devices it will show as a bar with a button. On mobile devices it will show as a full width button. Note that if cart is empty Sticky Checkout Bar/Button will not show.</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <div id="preview" className={(this.state.previewDesktop ? 'desktop' : 'mobile') + (this.state.previewActive ? '' : ' preview-hidden')}>
                                <Card title="Preview" sectioned actions={[{ content: this.state.previewActive ? 'Collapse ↑' : 'Expand ↓', onAction: this.togglePreviewActive }]}>
                                    <Card.Section>
                                        <div id="wizesaas_checkout_bar" style={{
                                            backgroundColor: this.state.model.barBackgroundColor ? this.state.model.barBackgroundColor : '#fff',
                                            color: this.state.model.barTextColor ? this.state.model.barTextColor : '#3d4246',
                                            display: this.state.previewDesktop && this.state.previewActive ? 'block' : 'none'
                                        }}>
                                            <div className="wizesaas_checkout_items-holder" style={{ display: this.state.model.showTotalItemsDesktop ? 'inline-block' : 'none' }}>
                                                <span className="wizesaas_checkout_items">2 {this.state.model.ItemsText ? this.state.model.ItemsText : 'ITEMS'}</span>
                                            </div>
                                            <div className="wizesaas_checkout_btn-holder">
                                                <button style={{
                                                    color: this.state.model.buttonTextColor ? this.state.model.buttonTextColor : '#fff',
                                                    backgroundColor: this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8',
                                                    borderColor: this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8',
                                                }}
                                                    data-color={this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8'} data-text-color={this.state.model.buttonTextColor ? this.state.model.buttonTextColor : '#fff'}
                                                    data-hover-color={this.state.model.buttonHoverColor ? this.state.model.buttonHoverColor : '#7796a8'} data-hover-text-color={this.state.model.buttonTextHoverColor ? this.state.model.buttonTextHoverColor : '#fff'}
                                                    type="submit" name="add" className="wizesaas_checkout_btn">{this.state.model.buttonText ? this.state.model.buttonText : 'Check out'}
                                                </button>
                                            </div>
                                            <div className="wizesaas_checkout_price-holder" style={{ display: this.state.model.showTotalPriceDesktop ? 'block' : 'none' }}>
                                                <span className="wizesaas_checkout_price">$129.00</span>
                                            </div>
                                        </div>
                                        <button className="wizesaas_checkout_btn wizesaas_checkout_btn_mobile" style={{
                                            color: this.state.model.buttonTextColor ? this.state.model.buttonTextColor : '#fff',
                                            backgroundColor: this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8',
                                            borderColor: this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8',
                                            display: this.state.previewDesktop || !this.state.previewActive ? 'none' : 'block'
                                        }}
                                            data-color={this.state.model.buttonColor ? this.state.model.buttonColor : '#7796a8'} data-text-color={this.state.model.buttonTextColor ? this.state.model.buttonTextColor : '#fff'}
                                            data-hover-color={this.state.model.buttonHoverColor ? this.state.model.buttonHoverColor : '#7796a8'} data-hover-text-color={this.state.model.buttonTextHoverColor ? this.state.model.buttonTextHoverColor : '#fff'}
                                        >
                                            <span className="wizesaas_checkout_items" style={{ display: this.state.model.showTotalItemsMobile ? 'block' : 'none' }}>2 {this.state.model.ItemsText ? this.state.model.ItemsText : 'ITEMS'}</span>
                                            {this.state.model.buttonText ? this.state.model.buttonText : 'Check out'}
                                            <span className="wizesaas_checkout_price" style={{ display: this.state.model.showTotalPriceMobile ? 'block' : 'none' }}>$129.00</span>
                                        </button>
                                    </Card.Section>
                                    <Stack alignment="center" distribution="center">
                                        <div style={{ display: this.state.previewActive ? 'block' : 'none' }}>
                                            <ButtonGroup segmented con>
                                                <Button pressed={this.state.previewDesktop} onClick={(newValue) => this.handlePreview(true)} icon={DesktopMajor}>Desktop Preview</Button>
                                                <Button pressed={!this.state.previewDesktop} onClick={(newValue) => this.handlePreview(false)} icon={MobileMajor}>Mobile Preview</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Stack>
                                </Card>
                            </div>
                        </Layout.Section>
                        <Layout.AnnotatedSection
                            title="Sticky Checkout Bar State"
                        >
                            <SettingToggle action={{
                                content: this.state.model.enabled ? 'Disable' : 'Enable',
                                onAction: () => { this.handleChange('enabled', !this.state.model.enabled) }
                            }}
                                enabled={this.state.model.enabled}
                            >
                                Sticky Checkout Bar is currently <TextStyle variation="strong">{this.state.model.enabled ? 'enabled' : 'disabled'}</TextStyle>.
                            </SettingToggle>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="General Settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <Select
                                        label="Button Animation"
                                        helpText="Repeats every 5 seconds"
                                        options={[{ label: 'None', value: 'none' }, { label: 'Bounce', value: 'bounce' }, { label: 'Flash', value: 'flash' }, { label: 'Pulse', value: 'pulse' }, { label: 'Rubber band', value: 'rubberBand' }, { label: 'Shake', value: 'shake' }, { label: 'Swing', value: 'swing' }, { label: 'Tada', value: 'tada' }, { label: 'Wobble', value: 'wobble' }, { label: 'Jello', value: 'jello' }]}
                                        onChange={(newValue) => this.handleChange('buttonAnimation', newValue)}
                                        value={this.state.model.buttonAnimation}
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Look and Feel"
                            description="App will automatically match your site's branding but you can also adjust it's look and feel to your liking here."
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        value={this.state.model.buttonText}
                                        onChange={(newValue) => this.handleChange('buttonText', newValue)}
                                        label="Override Button Text"
                                    />
                                    <FormLayout.Group>
                                        <TextField
                                            value={this.state.model.ItemText}
                                            onChange={(newValue) => this.handleChange('ItemText', newValue)}
                                            label='Translate "Item" Text (singular)'
                                        />
                                        <TextField
                                            value={this.state.model.ItemsText}
                                            onChange={(newValue) => this.handleChange('ItemsText', newValue)}
                                            label='Translate "Items" Text (plural)'
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Override Button Color"
                                            fieldName="buttonColor"
                                            value={this.state.model.buttonColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Override Button Hover Color"
                                            fieldName="buttonHoverColor"
                                            value={this.state.model.buttonHoverColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Override Button Text Color"
                                            fieldName="buttonTextColor"
                                            value={this.state.model.buttonTextColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Override Button Text Hover Color"
                                            fieldName="buttonTextHoverColor"
                                            value={this.state.model.buttonTextHoverColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <ColorPickerCustom
                                            label="Override Bar Background Color"
                                            fieldName="barBackgroundColor"
                                            value={this.state.model.barBackgroundColor}
                                            handleChange={this.handleChange}
                                        />
                                        <ColorPickerCustom
                                            label="Override Bar Text Color"
                                            fieldName="barTextColor"
                                            value={this.state.model.barTextColor}
                                            handleChange={this.handleChange}
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Desktop Settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Show on desktop computers"
                                        checked={this.state.model.showOnDesktop}
                                        onChange={(newValue) => this.handleChange('showOnDesktop', newValue)}
                                    />
                                    <Checkbox
                                        label="Show Total Price"
                                        checked={this.state.model.showTotalPriceDesktop}
                                        onChange={(newValue) => this.handleChange('showTotalPriceDesktop', newValue)}
                                    />
                                    <Checkbox
                                        label="Show Total Items"
                                        checked={this.state.model.showTotalItemsDesktop}
                                        onChange={(newValue) => this.handleChange('showTotalItemsDesktop', newValue)}
                                    />
                                    <p>Bar position</p>
                                    <ButtonGroup segmented con>
                                        <Button pressed={this.state.model.barPositionDesktop === 'top'} onClick={(newValue) => this.handleChange('barPositionDesktop', 'top')}>
                                            Top
                                        </Button>
                                        <Button pressed={this.state.model.barPositionDesktop === 'bottom'} onClick={(newValue) => this.handleChange('barPositionDesktop', 'bottom')}>
                                            Bottom
                                        </Button>
                                    </ButtonGroup>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Mobile Settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <Checkbox
                                        label="Show on tablets and mobile devices"
                                        checked={this.state.model.showOnMobile}
                                        onChange={(newValue) => this.handleChange('showOnMobile', newValue)}
                                    />
                                    <Checkbox
                                        label="Show Total Price"
                                        checked={this.state.model.showTotalPriceMobile}
                                        onChange={(newValue) => this.handleChange('showTotalPriceMobile', newValue)}
                                    />
                                    <Checkbox
                                        label="Show Total Items"
                                        checked={this.state.model.showTotalItemsMobile}
                                        onChange={(newValue) => this.handleChange('showTotalItemsMobile', newValue)}
                                    />
                                    <p>Bar position</p>
                                    <ButtonGroup segmented con>
                                        <Button pressed={this.state.model.barPositionMobile === 'top'} onClick={(newValue) => this.handleChange('barPositionMobile', 'top')}>
                                            Top
                                        </Button>
                                        <Button pressed={this.state.model.barPositionMobile === 'bottom'} onClick={(newValue) => this.handleChange('barPositionMobile', 'bottom')}>
                                            Bottom
                                        </Button>
                                    </ButtonGroup>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.Section>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }} />
                        </Layout.Section>
                    </Layout>
                </Page >
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/stickyCheckoutBar');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ initState: data, model: data, loading: false });

            window.$('#preview').sticky({ topSpacing: 56, zIndex: 100 });
            window.$('.wizesaas_checkout_btn').hover(
                function () {
                    window.$(this).css('background-color', window.$(this).attr('data-hover-color'));
                    window.$(this).css('border-color', window.$(this).attr('data-hover-color'));
                    window.$(this).css('color', window.$(this).attr('data-hover-text-color'));
                },
                function () {
                    window.$(this).css('background-color', window.$(this).attr('data-color'));
                    window.$(this).css('border-color', window.$(this).attr('data-color'));
                    window.$(this).css('color', window.$(this).attr('data-text-color'));
                });

            btnAnimation = data.buttonAnimation;
            setInterval(function () {
                if (window.$('.wizesaas_checkout_btn:hover').length === 0 && window.$('.wizesaas_checkout_btn:visible').length > 0 && btnAnimation !== 'None') {
                    window.$('.wizesaas_checkout_btn:visible').wizesaas_animateCss(btnAnimation);
                }
            }, 5000);
        }
    }

    async saveChanges() {
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveStickyCheckoutBar', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ initState: Object.assign({}, this.state.model) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }
}