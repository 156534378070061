import React, { Component } from 'react';
import { Page, Layout, Banner } from '@shopify/polaris';

export class Error extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);
    }

    componentDidMount() {
        this.props.setIsLoading(false);
    }

    render() {
        return (
            <Page title="Error">
                <Layout>
                    <Layout.Section>
                        <Banner title="Ops something went wrong" status="critical">
                            <p>This most likely means there is a server error on our side. Administrators are notified and are resolving this.</p>
                            <p>Everything will be back to normal asap.</p>
                            <p>Thank you for your understanding, patience and sorry for any inconvenience caused.</p>
                        </Banner>
                    </Layout.Section>
                </Layout>
            </Page>
        );
    }
}