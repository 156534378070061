import React, { Component } from 'react';
import { Page, Layout, Banner, Card, FormLayout, TextField, Button } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { fetchApi } from './helpers/fetchApi';

export class Login extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);
        this.state = { loading: true, errorMessage: '', shopName: '', code: '', shopNameError: '' };
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(fieldName, newValue) {
        if (fieldName === 'shopName') {
            this.setState({ shopName: newValue });
            this.setState({ shopNameError: '' });
        }
        else if (fieldName === 'code') {
            this.setState({ code: newValue });
        }
    }

    logIn() {
        if (!this.state.shopName) {
            this.setState({ shopNameError: 'Shop name field is required.' });
            return;
        }

        this.submitLogin();
    }

    render() {
        const errorMessage = this.state.errorMessage ? (
            <p>{this.state.errorMessage}</p>
        ) : null;

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Log in">
                    <Layout>
                        <Layout.Section>
                            <Banner
                                title="Log into Sticky Buy Button"
                                status="warning"
                            >
                                <p>It seems your session ended. Please log into the app by going to your <b>Shopify store admin -> Apps -> Sticky Buy Button</b> or use the form below.</p>
                                {errorMessage}
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        label="Shop name"
                                        placeholder="shopname"
                                        suffix=".myshopify.com"
                                        value={this.state.shopName}
                                        error={this.state.shopNameError}
                                        onChange={(newValue) => this.handleChange('shopName', newValue)} />
                                    <TextField
                                        label="Code (optional)"
                                        placeholder="code"
                                        value={this.state.code}
                                        onChange={(newValue) => this.handleChange('code', newValue)} />
                                    <Button primary={true} onClick={() => this.logIn()}>Log In</Button>
                                </FormLayout>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page>
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/login');
        const data = await response.json();

        this.props.setIsLoading(false);
        this.setState({ errorMessage: data.errorMessage, loading: false });
    }

    async submitLogin() {
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/submitLogin', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ shopName: this.state.shopName, discountCode: this.state.code }) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            window.location.href = data.redirectUrl + '?shop=' + this.state.shopName + '&token=' + data.token;
        }
    }
}