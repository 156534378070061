import React, { Component } from 'react';
import { Page, Layout, Card, Banner, Stack, ResourceList, ResourceItem, Thumbnail, TextStyle, Tooltip, EmptyState } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { fetchApi } from './helpers/fetchApi';

export class Analytics extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);

        this.state = {
            initState: {}, model: {}, loading: true
        };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {

        const topPerformers = (
            <ResourceList
                items={this.state.model.topPerformers}
                renderItem={(item) => {
                    const { url, title, imgSrc, mobileClicks, desktopClicks, totalClicks, totalPriceFormatted } = item;

                    return (
                        <ResourceItem
                            url={url}
                            media={
                                <Thumbnail source={imgSrc} size="medium" alt={title} />
                            }
                            accessibilityLabel={`View details for ${title}`}
                            name={title}
                        >
                            <h3>
                                <TextStyle variation="strong">{title}</TextStyle>
                            </h3>
                            <div>{totalClicks + ' times clicked (' + mobileClicks + ' mobile & ' + desktopClicks + ' desktop)'}</div>
                            <div>
                                <Tooltip content="Total potential revenue">
                                    <span>{totalPriceFormatted + ' ' + this.state.model.currency}</span>
                                </Tooltip>
                            </div>
                        </ResourceItem>
                    );
                }}
            />
        );

        const emptyState = (
            <EmptyState heading="Nothing to see here yet!">
                <p>When someone clicks on the Sticky or Quick Buy button you will see the details here!</p>
            </EmptyState>
        );

        const mostRecentClicks = (
            <ResourceList
                items={this.state.model.mostRecentClicks}
                renderItem={(item) => {
                    const { url, title, imgSrc, date, priceFormatted } = item;

                    return (
                        <ResourceItem
                            url={url}
                            media={
                                <Thumbnail source={imgSrc} size="medium" alt={title} />
                            }
                            accessibilityLabel={`View details for ${title}`}
                            name={title}
                        >
                            <h3>
                                <TextStyle variation="strong">{title}</TextStyle>
                            </h3>
                            <div><span className="stats-date">{date}</span></div>
                            <div>
                                <Tooltip content="Average product price per click">
                                    <span>{priceFormatted + ' ' + this.state.model.currency}</span>
                                </Tooltip>
                            </div>
                        </ResourceItem>
                    );
                }}
            />
        );

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Analytics">
                    <Layout>
                        <Layout.Section>
                            <Banner
                                status="info"
                            >
                                <p>You can see the latest 15 clicks here. Top 10 performers shows the most clicked products.</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.Section>
                            <Stack distribution="fillEvenly">
                                <Card sectioned title="Most recent 15 clicks">
                                    {this.state.model.mostRecentClicks.length > 0 ? mostRecentClicks : emptyState}
                                </Card>
                                <Card sectioned title="Top 10 Performers">
                                    {this.state.model.topPerformers.length > 0 ? topPerformers : emptyState}
                                </Card>
                            </Stack>
                        </Layout.Section>
                        <Layout.Section>
                            <Card sectioned title="Google Analytics">
                                <Banner
                                    status="info"
                                >
                                    <p>If your store has Google Analytics installed, clicks on Sticky or Quick Buy button will automatically appear in the Behavior -> Events section of your Google Analytics as shown below. No setup is needed.</p>
                                </Banner>
                                <img className="img-ga" src="/images/ga-wizesaas.png" alt="Google Analytics" />
                            </Card>
                        </Layout.Section>
                        <Layout.Section>
                            <Card sectioned title="Facebook pixel tracking">
                                <Banner
                                    status="info"
                                >
                                    <p>Similar to GA, if your store has FB pixel installed, clicks on Sticky or Quick Buy button will be tracked the same as your main ATC button. No setup is needed.</p><br />
                                    <p>If <b>Straight to Checkout</b> is enabled then FB InitiateCheckout event will be fired, otherwise FB AddToCart event is fired (same event as when your main ATC button is clicked).</p>
                                </Banner>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page>
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/analytics');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ initState: data, model: data, loading: false });

            window.$('.Polaris-ResourceItem__Link').click(function (e) {
                e.preventDefault();
                window.open(window.$(this).parent().data('href'), '_blank');
            });

            window.$.each(window.$(".stats-date"), function (i, v) {
                window.$(v).text(window.moment.utc(window.$(v).text(), "M/D/YYYY h:mm:ss A").local().format("LL LTS"));
            });
        }
    }
}