import React, { Component } from 'react';
import { Button, Stack, Popover, TextField, ColorPicker, hsbToRgb, rgbToHsb, rgbString } from '@shopify/polaris';

export class ColorPickerCustom extends Component {
    
    constructor(props, ref) {
        super(props);
        this.state = {
            active: false,
            hsbColor: this.rgbStringToHsb(this.props.value),
            color: this.props.value
        };
    }

    
    handleColorChange(newValue) {
        newValue.alpha = parseFloat(newValue.alpha.toFixed(4));
        var rgbaColor = rgbString(hsbToRgb(newValue));
        this.setState({ hsbColor: newValue, color: rgbaColor });

        this.props.handleChange(this.props.fieldName, rgbaColor);
    }

    handleColorStringChange(newValue) {
        var color = this.rgbStringToHsb(newValue);
        this.setState({ hsbColor: color, color: newValue });

        this.props.handleChange(this.props.fieldName, newValue);
    }

    handlePopoverOpen() {
        this.setState({ active: true });
    }

    handlePopoverClose() {
        this.setState({ active: false });
    }

    rgbStringToHsb(value) {
        if (!value) {
            return { hue: 0, brightness: 0, saturation: 0, alpha: 1 };
        }

        var rgbValues;
        if (value.startsWith('#')) {
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            value = value.replace(shorthandRegex, function (m, r, g, b) { return r + r + g + g + b + b; });
            rgbValues = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);
            if (!rgbValues) { rgbValues = [""]; }
            return rgbToHsb({
                red: parseInt(rgbValues[1], 16),
                green: parseInt(rgbValues[2], 16),
                blue: parseInt(rgbValues[3], 16),
                alpha: 1
            });
        }
        else {
            rgbValues = value.replace(/[^\d*.?\d*,]/g, "").split(",");
            return rgbToHsb({
                red: parseFloat(rgbValues[0]),
                green: parseFloat(rgbValues[1]),
                blue: parseFloat(rgbValues[2]),
                alpha: parseFloat(rgbValues[3])
            });
        }
    }

    render() {
       
        let propHsb = this.rgbStringToHsb(this.props.value);

        if ((this.state.hsbColor.hue && this.state.hsbColor.hue !== propHsb.hue) ||
            (this.state.hsbColor.saturation && this.state.hsbColor.saturation !== propHsb.saturation) ||
            (this.state.hsbColor.brightness && this.state.hsbColor.brightness !== propHsb.brightness) ||
            (this.state.hsbColor.alpha && this.state.hsbColor.alpha !== propHsb.alpha) 
        ) {
            var color = this.rgbStringToHsb(this.props.value);
            this.setState({ hsbColor: color, color: this.props.value });
        }
        const activator = (
            <Button onClick={() => this.handlePopoverOpen()} fullWidth={true} textAlign="left">
                <Stack alignment="center" spacing="tight">
                    <div className="color-picker-indicator" style={{ background: this.state.color }} />
                    <span>{this.state.color}</span>
                </Stack>
            </Button>
        );

        return (
            <div>
                <div className="Polaris-Labelled__LabelWrapper"><div className="Polaris-Label"><label className="Polaris-Label__Text">{this.props.label}</label></div></div>
                <Popover
                    active={this.state.active}
                    activator={activator}
                    onClose={() => this.handlePopoverClose()}
                >
                    <Popover.Section>
                        <ColorPicker
                            onChange={(newValue) => this.handleColorChange(newValue)}
                            color={this.state.hsbColor}
                            allowAlpha
                        />
                    </Popover.Section>
                    <Popover.Section>
                        <TextField
                            value={this.state.color}
                            onChange={(newValue) => this.handleColorStringChange(newValue)}
                        />
                    </Popover.Section>
                </Popover>
            </div>
        );
    }

}