import './custom.css'

import React, { Component } from 'react';
import { ContextualSaveBar, Icon, Frame, Loading, Modal, Navigation, TextContainer, Toast, TopBar, AppProvider } from '@shopify/polaris';
import {
    StarFilledMinor, HomeMajor, CodeMajor, QuickSaleMajor, BuyButtonMajor, CartMajor, BuyButtonButtonLayoutMajor,
    AnalyticsMajor, AppsMajor, LogOutMinor, ViewMajor, ImportStoreMajor, InventoryMajor, SidebarRightMajor, QuestionMarkMajor
} from '@shopify/polaris-icons';

import en from '@shopify/polaris/locales/en.json';
import { Link } from 'react-router-dom';


import { Dashboard } from './components/Dashboard';
import { StickyAddToCartBar} from './components/StickyAddToCartBar';
import { StickyCheckoutBar } from './components/StickyCheckoutBar';
import { StickyCart } from './components/StickyCart';
import { QuickBuyButton } from './components/QuickBuyButton';
import { QuickView } from './components/QuickView';
import { CartSlider } from './components/CartSlider';
import { Customize } from './components/Customize';
import { Analytics } from './components/Analytics';
import { Login } from './components/Login';
import { Support } from './components/Support';
import { MoreApps } from './components/MoreApps';
import { Error } from './components/Error';
import { UpsellBundle } from './components/UpsellBundle';
import { UnpaidDashboard } from './components/UnpaidDashboard';

import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import {
  AppBridgeProvider,
  QueryProvider,
} from "./components/providers";

import { fetchApi } from './components/helpers/fetchApi';
import { configs } from './components/helpers/appConfig';




class App extends Component {

    constructor(props) {
        super(props);

        this.setIsLoading = this.setIsLoading.bind(this);
        this.setToastActive = this.setToastActive.bind(this);
        this.setIsDirty = this.setIsDirty.bind(this);
        this.navigate = this.navigate.bind(this);
        this.toggleMobileNavigationActive = this.toggleMobileNavigationActive.bind(this);
        this.toggleUserMenuActive = this.toggleUserMenuActive.bind(this);
        this.toggleToastActive = this.toggleToastActive.bind(this);
        this.toggleModalLeaveActive = this.toggleModalLeaveActive.bind(this);
        this.toggleModalReviewActive = this.toggleModalReviewActive.bind(this);
        this.toggleModalPermissionsActive = this.toggleModalPermissionsActive.bind(this);
        this.handleDiscard = this.handleDiscard.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleModalLeave = this.handleModalLeave.bind(this);
        this.handleLeaveNavigation = this.handleLeaveNavigation.bind(this);
        this.handleReviewOuch = this.handleReviewOuch.bind(this);
        this.handleModalPermissions = this.handleModalPermissions.bind(this);
        this.handleLogOut = this.handleLogOut.bind(this);
        this.logOut = this.logOut.bind(this);
        this.doOnboardingStep = this.doOnboardingStep.bind(this);
        this.reInstall = this.reInstall.bind(this);

        this.state = {
            isLoading: false,
            isDirty: false,
            mobileNavigationActive: false,
            userMenuActive: false,
            toastActive: false,
            modalLeaveActive: false,
            modalReviewActive: false,
            modalReviewOuch: false,
            modalPermissionsActive: false,
            modalPermissionsGrantAccess: false,
            nextLocation: '',
            common: {}
        };
    }
    
    componentDidMount() {
        
        if(window.location.search && window.location.search.indexOf("redirectUri=") !== -1){
            const params = new URLSearchParams(window.location.search);
            const redirectUri = params.get("redirectUri");
            const url = new URL(decodeURIComponent(redirectUri));
            window.appBridgeRedirect(url);
        }else{
            setTimeout(()=> this.populateData(), 500);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && !this.state.common.adminEnabled) {
            window.Intercom("update");
        }
    }

    setIsLoading(isLoading) {
        this.setState({ isLoading: isLoading });
    }

    setToastActive(toastActive) {
        this.setState({ toastActive: toastActive });
    }

    setIsDirty(isDirty) {
        this.setState({ isDirty: isDirty });
        if (isDirty) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    saveChanges() { }

    nextOnboardingStep() { }

    navigate(location) {
        if (!this.state.common.adminEnabled) {
            window.Intercom("update");
        }

        if(!window.navigate) // for non-embedded app
            window.location.href = location;
        
        var shopQueryString = this.state.common.shopQueryString;
        setTimeout(function () {
            //window.location.href = location + (shopQueryString ? shopQueryString : '');
            //history.push(location + (shopQueryString ? shopQueryString : ''));
            window.navigate(location + (shopQueryString ? shopQueryString : '') + window.location.search);
        }, 0);
    }

    toggleMobileNavigationActive() {
        this.setState({ mobileNavigationActive: !this.state.mobileNavigationActive });
    }

    toggleUserMenuActive() {
        this.setState({ userMenuActive: !this.state.userMenuActive });
    }

    toggleToastActive() {
        this.setState({ toastActive: !this.state.toastActive });
    }

    toggleModalLeaveActive() {
        this.setState({ modalLeaveActive: !this.state.modalLeaveActive });
    }

    toggleModalReviewActive() {
        this.setState({ modalReviewActive: !this.state.modalReviewActive, modalReviewOuch: false });
    }

    toggleModalPermissionsActive() {
        this.setState({ modalPermissionsActive: !this.state.modalPermissionsActive });
    }

    handleDiscard() {
        this.setIsDirty(false);
    }

    handleSave() {
        this.saveChanges();
    }

    handleModalLeave() {
        this.toggleModalLeaveActive();
        this.setIsDirty(false);
        this.navigate(this.state.nextLocation);
    }

    handleLeaveNavigation = (nextLocation) => {
        if (window.location.pathname === nextLocation.pathname) {
            return true;
        }
        else {
            this.setState({ nextLocation: nextLocation.pathname });
            this.toggleModalLeaveActive();
            return false;
        }
    }

    handleRate(value) {
        this.doOnboardingStep(3);

        if (value <= 4) {
            this.setState({ modalReviewOuch: true });
        }
        else {
            window.open('https://apps.shopify.com/sticky-buy-button?#modal-show=ReviewListingModal', '_blank');
            this.toggleModalReviewActive();
        }
    }

    handleReviewOuch() {
        this.toggleModalReviewActive();
        this.navigate('support');
    }

    handleLogOut() {
        this.logOut();
    }

    handleModalPermissions() {
        this.setState({ modalPermissionsGrantAccess: true });
        this.reInstall();
    }
    
    render() {
        let token = !window.navigate ? `&token=${new URLSearchParams(window.location.search).get("token")}` : "";
        let logout = {
            items: [
                { content: 'Log out', icon: LogOutMinor, onAction: this.handleLogOut }
            ],
        };
        
        const userMenuActions = [
            {
                items: [
                    { content: 'Open Storefront', icon: ImportStoreMajor, onAction: () => { window.open(this.state.common.storefrontUrl, '_blank'); } },
                ]
            },
        ];

        if (process.env.REACT_APP_HIDE_LOG_OUT !== "true") {
            userMenuActions.push(logout);
        }

        

               
        const userMenuMarkup = this.state.common.userName ? (
            <TopBar.UserMenu
                actions={userMenuActions}
                name={this.state.common.userName}
                detail={this.state.common.shopName}
                initials={this.state.common.userNameInitials}
                open={this.state.userMenuActive}
                onToggle={this.toggleUserMenuActive}
            />
        ) : null;

        const topBarMarkup = (
            <TopBar
                showNavigationToggle
                userMenu={userMenuMarkup}
                onNavigationToggle={this.toggleMobileNavigationActive}
            />
        );
        let navItems = []
        if (configs.dashboardMenuEnabled) {
            let dashBoardMenu = {
                url: '/' + this.state.common.shopQueryString + token,
                label: 'Dashboard',
                icon: HomeMajor,
                exactMatch: true,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            }
            navItems.push(dashBoardMenu)
        }

        if (configs.stickyBuyButtonMenuEnabled) {
            let stickyBuyButtonMenu = {
                url: '/sticky-buy-button' + this.state.common.shopQueryString + token,
                label: 'Sticky Buy Button',
                icon: BuyButtonMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };

            navItems.push(stickyBuyButtonMenu)
        }

        if (configs.quickBuyButtonMenuEnabled) {
            let quickBuyButtonMenu = {
                url: '/quick-buy-button' + this.state.common.shopQueryString + token,
                label: 'Quick Buy Button',
                icon: QuickSaleMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };

            navItems.push(quickBuyButtonMenu)
        }

        if (configs.quickViewMenuEnabled) {
            let quickViewMenu = {
                url: '/quick-view' + this.state.common.shopQueryString + token,
                label: 'Quick View',
                icon: ViewMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };

            navItems.push(quickViewMenu)
        }

        if (configs.stickyCheckoutBarMenuEnabled) {
            let stickyCheckoutBarMenu = {
                url: '/sticky-checkout-bar' + this.state.common.shopQueryString + token,
                label: 'Sticky Checkout Bar',
                icon: BuyButtonButtonLayoutMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };

            navItems.push(stickyCheckoutBarMenu)
        }

        if (configs.cartSliderMenuEnabled) {
            let cartSliderMenu = {
                url: '/cart-slider' + this.state.common.shopQueryString + token,
                label: 'Cart Slider',
                icon: SidebarRightMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };

            navItems.push(cartSliderMenu)
        }

        if (configs.stickyCartMenuEnabled) {
            let stickyCartMenu = {
                url: '/sticky-cart' + this.state.common.shopQueryString + token,
                label: 'Sticky Cart',
                icon: CartMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };

            navItems.push(stickyCartMenu)
        }

        if (configs.customizeMenuEnabled) {
            let advancedMenu = {
                url: '/customize' + this.state.common.shopQueryString + token,
                label: 'Customize',
                icon: CodeMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };

            navItems.push(advancedMenu)
        }

        if (configs.analyticsMenuEnabled) {
            let analyticsMenu = {
                url: '/analytics' + this.state.common.shopQueryString + token,
                label: 'Analytics',
                icon: AnalyticsMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };
                
            navItems.push(analyticsMenu)
        }

        let navApps = [];

        

        if (configs.moreAppsMenuEnabled) {
            let moreAppsMenu = {
                url: '/more-apps' + this.state.common.shopQueryString + token,
                label: 'More Apps',
                icon: AppsMajor,
                onClick: () => {
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            };

            navApps.push(moreAppsMenu)
        }


        //navApps.push(
        //    {
        //        url: '/support' + this.state.common.shopQueryString + token,
        //        label: 'Get Support',
        //        icon: QuestionMarkMajor,
        //        onClick: () => {
        //            if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
        //        }
        //    });
            
        //navApps.push({
        //        label: 'Write a Review',
        //        icon: FavoriteMajor,
        //        onClick: () => {
        //            //this.toggleModalReviewActive();
        //            window.open('https://apps.shopify.com/sticky-add-to-cart-bar?#modal-show=ReviewListingModal', '_blank');
        //            if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
        //        }
        //});
        

        let navAdmin = []

        if (configs.openStorefrontMenuEnabled) {
            let openStoreFrontMenu = {
                label: 'Open Storefront',
                icon: ImportStoreMajor,
                onClick: () => {
                    window.open(this.state.common.storefrontUrl, '_blank');
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            }

            navAdmin.push(openStoreFrontMenu)
        }

        if (configs.openShopifyAdminMenuEnabled) {
            let openShopifyAdminMenu = {
                label: 'Open Shopify Admin',
                icon: InventoryMajor,
                onClick: () => {
                    window.open(this.state.common.shopifyAdminUrl, '_blank');
                    if (this.state.mobileNavigationActive) { this.toggleMobileNavigationActive(); }
                }
            }

            navAdmin.push(openShopifyAdminMenu)
        }


        let navigationMarkup = this.state.common.userName &&  (
            <Navigation location={window.location.pathname}>
                <Navigation.Section
                    separator
                    title=""
                    items={navItems}
                />
            
                <Navigation.Section
                    items={navApps}
                    separator
                /> 
                {
                    this.state.common.adminEnabled &&
                    <Navigation.Section
                        items={navAdmin}
                        separator
                    />
                }
            </Navigation>
        );
       
        if(window.location.href.includes("unpaid") ){
            navigationMarkup = <Navigation location={window.location.pathname}>
            <Navigation.Section
                separator
                title=""
                items={[]}
            />
            </Navigation>
        }
        
        const loadingMarkup = this.state.isLoading ? <Loading /> : null;

        const contextualSaveBarMarkup = this.state.isDirty ? (
            <ContextualSaveBar
                message="Unsaved changes"
                saveAction={{
                    onAction: this.handleSave,
                }}
                discardAction={{
                    onAction: this.handleDiscard,
                }}
            />
        ) : null;

        const toastMarkup = this.state.toastActive ? (
            <Toast onDismiss={this.toggleToastActive} content="Changes saved" />
        ) : null;

        const modalLeave = (
            <Modal
                open={this.state.modalLeaveActive}
                onClose={this.toggleModalLeaveActive}
                title="You have unsaved changes"
                primaryAction={{
                    content: 'Leave page',
                    onAction: this.handleModalLeave,
                    destructive: true
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: this.toggleModalLeaveActive,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>If you leave this page, all unsaved changes will be lost.</p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        );

       
        const modalReview = (
            <Modal
                open={this.state.modalReviewActive}
                onClose={this.toggleModalReviewActive}
                title="Write a review"
                primaryAction={this.state.modalReviewOuch ? {
                    content: 'OK',
                    onAction: this.handleReviewOuch
                } : null}
            >
                <Modal.Section>
                    <TextContainer>
                        <p className="review-title" style={{ display: this.state.modalReviewOuch ? 'none' : 'block' }}>How would you rate Quick Buy Button?</p>
                        <div className="review-star-container" style={{ display: this.state.modalReviewOuch ? 'none' : 'block' }}>
                            <span className="review-star" onClick={() => this.handleRate(1)}><Icon source={StarFilledMinor} /></span>
                            <span className="review-star" onClick={() => this.handleRate(2)}><Icon source={StarFilledMinor} /></span>
                            <span className="review-star" onClick={() => this.handleRate(3)}><Icon source={StarFilledMinor} /></span>
                            <span className="review-star" onClick={() => this.handleRate(4)}><Icon source={StarFilledMinor} /></span>
                            <span className="review-star" onClick={() => this.handleRate(5)}><Icon source={StarFilledMinor} /></span>
                        </div>
                        <p className="review-title" style={{ display: this.state.modalReviewOuch ? 'block' : 'none' }}>We want to do better and make it perfect for you!</p>
                        <p className="review-subtitle" style={{ display: this.state.modalReviewOuch ? 'block' : 'none' }}>Let us know your feedback. We have a team ready to help you ASAP. Click OK to continue.</p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        );

        const modalPermissions = (
            <Modal
                open={this.state.modalPermissionsActive}
                onClose={this.toggleModalPermissionsActive}
                title="More permissions needed"
                primaryAction={{
                    content: 'Grant Access',
                    onAction: this.handleModalPermissions,
                    loading: this.state.modalPermissionsGrantAccess
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: this.toggleModalPermissionsActive,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>You need to give more permissions to get access to this feature.</p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        );
        

        const appLogo = {
            width: 119,
            topBarSource: '/images/sticky-checkout-logo.png',
            contextualSaveBarSource: '/images/sticky-checkout-logo.png',
            url: '/' + this.state.common.shopQueryString + token,
            accessibilityLabel: 'Sticky Checkout',
        }; 
        // const theme = {
        //     colors: {
        //         topBar: {
        //             background: '#3b4f66',
        //         },
        //     },
        //     logo: {
        //         width: 119,
        //         topBarSource: 'images/sticky-buy-button-logo.png',
        //         contextualSaveBarSource: '/images/sticky-buy-button-logo.png',
        //         url: '/' + this.state.common.shopQueryString + token,
        //         accessibilityLabel: 'Quick Buy Button',
        //     },
        // };

        function AdapterLink({ url, ...rest }) {
            return <Link to={url} {...rest} />
        }
        function inIframe () {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        }
        let isEmbedded = inIframe();
        let frame = (<>
        <Frame
                                topBar={topBarMarkup}
                                navigation={navigationMarkup}
                                showMobileNavigation={this.state.mobileNavigationActive}
                                onNavigationDismiss={this.toggleMobileNavigationActive}
                                logo={appLogo}
                                >
                                {contextualSaveBarMarkup}
                                {loadingMarkup}
                                {toastMarkup}
                                {modalLeave}
                                {modalReview}
                                {modalPermissions}
                                {/* <Prompt when={this.state.isDirty} message={this.handleLeaveNavigation} /> */}
                                <Routes>
                                    <Route exact path='/'element={
                                        <Dashboard {...this.props}
                                            setIsLoading={this.setIsLoading} 
                                            setToastActive={this.setToastActive} 
                                            setIsDirty={this.setIsDirty} 
                                            setSaveChanges={click => this.saveChanges = click} 
                                            navigate={this.navigate} 
                                            toggleModalReviewActive={this.toggleModalReviewActive} 
                                            doOnboardingStep={this.doOnboardingStep} 
                                            setNextOnboardingStep={click => this.nextOnboardingStep = click} 
                                            toggleModalPermissionsActive={this.toggleModalPermissionsActive} 
                                            reInstall={this.reInstall} />} />
                                        <Route exact path='/' element={<Dashboard {...this.props} setIsLoading={this.setIsLoading} setToastActive={this.setToastActive} setIsDirty={this.setIsDirty} setSaveChanges={click => this.saveChanges = click} navigate={this.navigate} toggleModalReviewActive={this.toggleModalReviewActive} doOnboardingStep={this.doOnboardingStep} setNextOnboardingStep={click => this.nextOnboardingStep = click} toggleModalPermissionsActive={this.toggleModalPermissionsActive} reInstall={this.reInstall}  />} />
                                        { configs.stickyBuyButtonMenuEnabled &&  
                                            <Route path='/sticky-buy-button' 
                                                element={ 
                                                    <StickyAddToCartBar 
                                                        {...this.props}
                                                        setIsLoading={this.setIsLoading} 
                                                        setToastActive={this.setToastActive} 
                                                        setIsDirty={this.setIsDirty} 
                                                        setSaveChanges={click => this.saveChanges = click} 
                                                        navigate={this.navigate} 
                                                        common={this.state.common} 
                                                    />}  
                                            />
                                        } 
                                        
                                        { configs.quickBuyButtonMenuEnabled &&  <Route path='/quick-buy-button' element={ <QuickBuyButton {...this.props} setIsLoading={this.setIsLoading} setToastActive={this.setToastActive} setIsDirty={this.setIsDirty} setSaveChanges={click => this.saveChanges = click} navigate={this.navigate} common={this.state.common} />}  /> }
                                        { configs.quickViewMenuEnabled && 
                                                <Route path='/quick-view' 
                                                    element={ 
                                                        <QuickView 
                                                            {...this.props} 
                                                            setIsLoading={this.setIsLoading} 
                                                            setToastActive={this.setToastActive} 
                                                            setIsDirty={this.setIsDirty} 
                                                            setSaveChanges={click => this.saveChanges = click} 
                                                            navigate={this.navigate} common={this.state.common} 
                                                        />}  
                                                /> 
                                        }
                                        { configs.stickyCheckoutBarMenuEnabled &&  
                                                <Route path='/sticky-checkout-bar' 
                                                    element={ <StickyCheckoutBar 
                                                        {...this.props} 
                                                        setIsLoading={this.setIsLoading} 
                                                        setToastActive={this.setToastActive} 
                                                        setIsDirty={this.setIsDirty} 
                                                        setSaveChanges={click => this.saveChanges = click} 
                                                        navigate={this.navigate} 
                                                    />} 
                                                /> 
                                        }
                                        { configs.cartSliderMenuEnabled &&  <Route path='/cart-slider' element={ <CartSlider {...this.props} setIsLoading={this.setIsLoading} setToastActive={this.setToastActive} setIsDirty={this.setIsDirty} setSaveChanges={click => this.saveChanges = click} navigate={this.navigate} common={this.state.common} />} /> }
                                        { configs.stickyCartMenuEnabled &&  <Route path='/sticky-cart' element={ <StickyCart {...this.props} setIsLoading={this.setIsLoading} setToastActive={this.setToastActive} setIsDirty={this.setIsDirty} setSaveChanges={click => this.saveChanges = click} navigate={this.navigate} common={this.state.common} />} /> }
                                        { configs.customizeMenuEnabled &&  <Route path='/customize' element={ <Customize {...this.props} setIsLoading={this.setIsLoading} setToastActive={this.setToastActive} setIsDirty={this.setIsDirty} setSaveChanges={click => this.saveChanges = click} navigate={this.navigate} />} /> }
                                        { configs.analyticsMenuEnabled &&  <Route path='/analytics' element={ <Analytics {...this.props} setIsLoading={this.setIsLoading} navigate={this.navigate} />} /> } 
                                        { configs.moreAppsMenuEnabled &&  <Route path='/more-apps' element={ <MoreApps {...this.props} setIsLoading={this.setIsLoading} />} /> }
                                        {/*<Route path='/support' element={ <Support {...this.props} setIsLoading={this.setIsLoading} navigate={this.navigate} />} />*/}
                                        <Route path='/login' element={ <Login {...this.props} setIsLoading={this.setIsLoading} />} />
                                        <Route path='/upsell-bundle' element={ <UpsellBundle {...this.props} setIsLoading={this.setIsLoading} setToastActive={this.setToastActive} setIsDirty={this.setIsDirty} setSaveChanges={click => this.saveChanges = click} navigate={this.navigate} />} />
                                        <Route path='/error' element={ <Error {...this.props} setIsLoading={this.setIsLoading} />} />
                                        <Route path='/unpaidDashboard' element={<UnpaidDashboard {...this.props} setIsLoading={this.setIsLoading} />} /> 

                                        <Route path='/Account/Install' element={ <Dashboard {...this.props} setIsLoading={this.setIsLoading} setToastActive={this.setToastActive} setIsDirty={this.setIsDirty} setSaveChanges={click => this.saveChanges = click} navigate={this.navigate} toggleModalReviewActive={this.toggleModalReviewActive} doOnboardingStep={this.doOnboardingStep} setNextOnboardingStep={click => this.nextOnboardingStep = click} toggleModalPermissionsActive={this.toggleModalPermissionsActive} reInstall={this.reInstall} />} />
                                </Routes>
                            </Frame>
        </>
            
        );
        let layout = null;
        if(isEmbedded){
            layout =    <AppBridgeProvider>
                            <QueryProvider>
                                {frame}
                            </QueryProvider>  
                        </AppBridgeProvider>
        }else{
            layout = frame;
        }
        
        return (
            <AppProvider i18n={en} linkComponent={AdapterLink}>
                <BrowserRouter>
                    {layout}
                </BrowserRouter>
                    
            </AppProvider>
            
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/shared');
        if(response.status === 401 && window.location.pathname.startsWith('/login') === false ){
            this.navigate('/login');
        }
        const data = await response.json();

        // try{
        //     var userId = data.userId; // Replace your_user_id with your own if available.
        //     window.hj('identify', userId, {
        //         // Add your own custom attributes here. Some EXAMPLES:
        //         'user_id': userId,
        //         'shop_id': data.shopName, // Signup date in ISO-8601 format.
        //         'shop_name': data.shopName,
        //         'shop_owner': data.userName,
        //         'shopify_domain': data.shopName, // Send strings with quotes around them.
        //         'myshopify_domain': data.shopName, // Send numbers without quotes.
        //         // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
        //         // 'Last refund date': null, // Send null when no value exists for a user.
        //     });
        // }catch(err){
        //     console.log(err);
        // }

        if (data.redirectUrl) {
            this.navigate(data.redirectUrl);
            if (!data.adminEnabled) {
                window.Intercom("boot", {
                    app_id: data.intercomAppId,
                    "App name": data.appName,
                });
            }
        }
        else {
            this.setState({ common: data });
            if (!data.adminEnabled) {
                window.Intercom("boot", {
                    app_id: data.intercomAppId,
                    name: data.userName,
                    email: data.email,
                    phone: data.phone,
                    user_id: data.userId,
                    user_hash: data.userHash,
                    created_at: data.createdAt,
                    "Shop name": data.shopName,
                    "App name": data.appName,
                    "Plan": data.plan,
                    "Plan display name": data.planDisplayName,
                    "Is installed": data.installed,
                    "Installed time": data.installedAt,
                    "Uninstalled time": data.uninstalledAt,
                    "Days installed": data.daysInstalled,
                });
            }
        }
    }

    async logOut() {
        const response = await fetchApi('api/admin/logOut', { method: 'post', headers: { 'Content-Type': 'application/json' } });
        const data = await response.json();

        if (response.ok === true && data.redirectUrl) {
            this.setState({ common: {} });
            this.navigate(data.redirectUrl);
        }
    }

    async doOnboardingStep(step) {
        const response = await fetchApi('api/admin/doOnboardingStep', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ step: step }) });
        const data = await response.json();

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.nextOnboardingStep(step);
            }
        }
    }

    async reInstall() {
        const response = await fetchApi('api/admin/reinstall', { method: 'post', headers: { 'Content-Type': 'application/json' } });
        const data = await response.json();

        if (response.ok === true) {
            window.location.href = data.redirectUrl;
        }
    }
}


export default App;