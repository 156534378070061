import React, { Component } from 'react';

export class CartSliderCartReserved extends Component {

    constructor(props, ref) {
        super(props);

        this.showTimer = this.showTimer.bind(this);
        window.passedSecond = 0;
    }

    componentDidMount() {

        var timer = this.showTimer;
        timer();

        setInterval(function () {
            var passedSeconds = window.passedSecond;//localStorage.getItem('cart-slider-cartReserved-passedSecond');
            var passedTime = (passedSeconds !== null ? parseInt(passedSeconds) : 0) + 1;
            window.passedSecond = passedTime;

            timer();
        }, 1000);
    }

    showTimer() {
        var duration = this.props.model.countdownMinutes_CartReserved;

        if (duration === 0) {
            return;
        }

        var passedSeconds = window.passedSecond;//localStorage.getItem('cart-slider-cartReserved-passedSecond');
        var passedTime = 1000 * (passedSeconds !== null ? parseInt(passedSeconds) : 0);
        var totalTime = 60000 * duration;
        var actualTime = totalTime - passedTime;

        if (actualTime < 0) {
            window.passedSecond = 0;//localStorage.setItem('cart-slider-cartReserved-passedSecond', 0);
            return;
        }

        var actualDate = new Date(actualTime);
        window.$('.wizesaas-cs-cartReserved-timer').text((actualDate.getUTCMinutes() < 10 ? '0' : '') + actualDate.getUTCMinutes() + ':' + (actualDate.getUTCSeconds() < 10 ? '0' : '') + actualDate.getUTCSeconds());
    }

    render() {

        return (
            <div className="wizesaas-cs-cartReserved" style={{
                backgroundColor: this.props.model.backgroundColor_CartReserved,
                color: this.props.model.textColor_CartReserved,
                borderColor: this.props.model.borderColor_CartReserved ? this.props.model.borderColor_CartReserved : this.props.model.backgroundColor_CartReserved,
                fontSize: this.props.model.messageFontSize_CartReserved + 'px',
                borderRadius: this.props.model.borderRadius_CartReserved ? this.props.model.borderRadius_CartReserved + 'px' : '0px'
            }}>
                <div style={{ paddingRight: this.props.model.emoji_CartReserved ? '10px' : '0px' }}>
                    <span style={{ fontSize: (this.props.model.messageFontSize_CartReserved * 2) + 'px' }}>{this.props.model.emoji_CartReserved}</span>
                </div>
                <div>
                    <div>{this.props.model.message_CartReserved}</div>
                    <div className="wizesaas-cs-cartReserved-bold">
                        <span>{this.props.model.messageBeforeTimer_CartReserved}</span>
                        <span className="wizesaas-cs-cartReserved-timer" style={{ color: this.props.model.colorOfTimer_CartReserved }}></span>
                        <span>{this.props.model.messageAfterTimer_CartReserved}</span>
                    </div>
                </div>
            </div>
        );
    }
}