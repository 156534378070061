import React, { Component } from 'react';
import { TextContainer, Autocomplete, Stack, Tag } from '@shopify/polaris';

export class CountryPickerCustom extends Component {

    constructor(props, ref) {
        super(props);

        const options = JSON.parse(JSON.stringify(props.options));

        this.state = {
            filteredOptions: options,
            selectedOptions: this.props.value ? this.props.value : [],
            value: ''
        };
    }

    handleSearch(newValue) {
        this.setState({ value: newValue });

        const options = JSON.parse(JSON.stringify(this.props.options));

        if (newValue === '') {
            this.setState({ filteredOptions: options });
            return;
        }

        const filterRegex = new RegExp(newValue, 'i');
        const resultOptions = options.filter((option) =>
            option.label.match(filterRegex),
        );
        this.setState({ filteredOptions: resultOptions });
    }

    handleSelect(newValue) {
        this.setState({ selectedOptions: newValue });

        this.props.handleChange(this.props.fieldName, newValue);
    }

    handleRemove(newValue) {
        this.state.selectedOptions.splice(this.state.selectedOptions.indexOf(newValue), 1);
        this.setState({ selectedOptions: this.state.selectedOptions });

        this.props.handleChange(this.props.fieldName, this.state.selectedOptions);
    }

    render() {

        const tagsMarkup = this.state.selectedOptions.map((option) => {

            var tagLabel = this.props.options.filter((p) =>
                p.value === option,
            )[0].label;

            return (
                <Tag key={`option${option}`} onRemove={(tag) => this.handleRemove(option)}>
                    {tagLabel}
                </Tag>
            );
        });

        const textField = (
            <Autocomplete.TextField
                onChange={(newValue) => this.handleSearch(newValue)}
                label={this.props.label}
                value={this.state.value}
                placeholder="Search"
            />
        );

        return (
            <div className="country-picker-custom">
                <Autocomplete
                    allowMultiple
                    options={this.state.filteredOptions}
                    selected={this.state.selectedOptions}
                    textField={textField}
                    onSelect={(newValue) => this.handleSelect(newValue)}
                    listTitle="Choose Countries"
                    preferredPosition="above"
                />
                <TextContainer>
                    <Stack>{tagsMarkup}</Stack>
                </TextContainer>
            </div>
        );
    }
}