import React, { Component } from 'react';
import { Page, Layout, Card, FormLayout, TextField, Banner, PageActions } from '@shopify/polaris';
import { SkeletonPageCustom } from './SkeletonPageCustom';
import { fetchApi } from './helpers/fetchApi';

export class Customize extends Component {

    constructor(props) {
        super(props);
        props.setIsLoading(true);
        this.saveChanges = this.saveChanges.bind(this);

        this.state = {
            initState: {}, model: {}, loading: true
        };
    }

    componentDidMount() {
        this.populateData();
        this.props.setSaveChanges(this.saveChanges);
    }

    handleChange(fieldName, newValue) {
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model[fieldName] = newValue;
            this.props.setIsDirty(JSON.stringify(this.state.initState) !== JSON.stringify(model));
            return { model };
        });
    }

    render() {

        return (
            this.state.loading ?
                <SkeletonPageCustom />
                :
                <Page title="Customize">
                    <Layout>
                        <Layout.Section

                        >
                            <Banner
                                title="What is this for?"
                                status="info"
                            >
                                <p>If you feel comfortable with custom code you can add CSS and JavaScript here. Otherwise we do not recommend adding anything here.</p>
                            </Banner>
                        </Layout.Section>
                        <Layout.AnnotatedSection
                            title="Custom CSS"
                            description="Put your custom CSS code here."
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        value={this.state.model.customCSS}
                                        onChange={(newValue) => this.handleChange('customCSS', newValue)}
                                        label="Custom CSS styling"
                                        multiline
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Custom JavaScript"
                            description="Put your custom JavaScript code here."
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        value={this.state.model.customJavaScript}
                                        onChange={(newValue) => this.handleChange('customJavaScript', newValue)}
                                        label="Custom JavaScript code"
                                        multiline
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        {
                            this.state.model.adminEnabled ?
                                <Layout.AnnotatedSection
                                    title="Selectors"
                                >
                                    <Card sectioned>
                                        <FormLayout>
                                            <TextField
                                                value={this.state.model.formSelector}
                                                onChange={(newValue) => this.handleChange('formSelector', newValue)}
                                                label="Form Selector (Only 1 form selector supported. Don't use comma.)"
                                            />
                                            <TextField
                                                value={this.state.model.buyButtonSelector}
                                                onChange={(newValue) => this.handleChange('buyButtonSelector', newValue)}
                                                label="Buy Button Selector"
                                            />
                                            <TextField
                                                value={this.state.model.variantsSelector}
                                                onChange={(newValue) => this.handleChange('variantsSelector', newValue)}
                                                label="Variants Selector"
                                            />
                                            <TextField
                                                value={this.state.model.reviewsStarsSelector}
                                                onChange={(newValue) => this.handleChange('reviewsStarsSelector', newValue)}
                                                label="Reviews Stars Selector"
                                            />
                                            <TextField
                                                value={this.state.model.priceSelector}
                                                onChange={(newValue) => this.handleChange('priceSelector', newValue)}
                                                label="Price Selector"
                                            />
                                            <TextField
                                                value={this.state.model.wasPriceSelector}
                                                onChange={(newValue) => this.handleChange('wasPriceSelector', newValue)}
                                                label="Was Price Selector"
                                            />
                                            <TextField
                                                value={this.state.model.homepageProductUrl}
                                                onChange={(newValue) => this.handleChange('homepageProductUrl', newValue)}
                                                label="Homepage Product Url"
                                            />
                                        </FormLayout>
                                    </Card>
                                </Layout.AnnotatedSection> : ''
                        }
                        <Layout.Section>
                            <PageActions primaryAction={{ content: 'Save', onAction: () => { this.saveChanges() } }} />
                        </Layout.Section>
                    </Layout>
                </Page>
        );
    }

    async populateData() {
        const response = await fetchApi('api/admin/customize');
        const data = await response.json();

        if (data.redirectUrl) {
            this.props.navigate(data.redirectUrl);
        }
        else {
            this.props.setIsLoading(false);
            this.setState({ initState: data, model: data, loading: false });
        }
    }

    async saveChanges() {
        this.props.setIsLoading(true);
        const response = await fetchApi('api/admin/saveCustomize', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.model) });
        const data = await response.json();
        this.props.setIsLoading(false);

        if (response.ok === true) {
            if (data.redirectUrl) {
                this.props.navigate(data.redirectUrl);
            }
            else {
                this.setState({ initState: Object.assign({}, this.state.model) });
                this.props.setIsDirty(false);
                this.props.setToastActive(true);
            }
        }
    }

}