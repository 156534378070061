import { customFetch } from './customFetch';

export const fetchApi = (...args) => {
    let cusFetch = () => {}
    
    if(window.authenticatedFetch){
        cusFetch = window.authenticatedFetch;
    }else{
        cusFetch = customFetch();
    }

    return cusFetch(...args);
  };