export const customFetch = () => {
    let token = new URLSearchParams(window.location.search).get("token");
    
    if (!token) {
        return fetch;
    }

    return (url, options) => {
      if(!options){
        options = {};
      }
      if(!options.headers){
        options.headers = {};
      }

      return fetch(url, {
        ...options,
        headers: {
          ...options.headers,
         Authorization: `Bearer ${token}`,
        },
      });
    };
  };